angular.module('bfServices.subbuyer', [])

.service('subbuyer', ['$http', '$resource', '$rootScope', function($http, $resource, $rootScope) {

    this.editedBalance = {};
    this.editedFactors = {
        selected_platform: 0,
        selected_type: 0,
        platforms: {},
        types: {
            0: {}
        }
    };
    this.editedMarkup = {
        platform: 0,
        plantation: 0,
        platforms: {},
        plantations: {}
    };
    this.subbuyerFormErrors = {};
    this.subbuyerFormFieldsErrors = {};
    this.sentForm = false;

    var self = this;

    /**
     * Get Subbuyer Balance for Edit
     */
    this.editBalance = function(id, url){
        // http://ec.term.biflorica:8080/api/subbuyers/balance/id/3824
        /*$resource(API_LINK + '/subbuyers/:action',
            {action:'balance', 'id': id},
            {get: {method:'GET'}}
        ).get(function(result){
            self.editedBalance = result.data.subbuyer;
            self.editedBalance.subbuyerid = id;
            //console.log(self.editedBalance);
        });*/
        location.href = url + '/id/' + id;
    };

    /**
     * Get Subbuyer Factors for edit
     */
    this.editFactors = function(id){
        // http://ec.term.biflorica:8080/api/subbuyers/balance/id/3824
        $resource(API_LINK + '/subbuyers/:action',
            {action:'factors', 'id': id},
            {get: {method:'GET'}}
        ).get(function(result){
            self.editedFactors = result.data.subbuyer;
            self.editedFactors.selected_platform = 0;
            self.editedFactors.types[0] = {};
            console.log(self.editedFactors);
        });
    };



    /**
     * Save Balance
     * @param order_id
     */
    this.saveBalance = function(){
        // Disable Save button
        self.sentForm = true;

        url = API_LINK + '/subbuyers/saveBalance';

        //Формируем параметры на отправку
        sent_params = {};
        sent_params.subbuyerid = self.editedBalance.subbuyerid;
        sent_params.balance = {};
        for(var balance_id in self.editedBalance.balance){
            console.log(self.editedBalance.balance[balance_id]['value']);
            sent_params.balance[balance_id] = self.editedBalance.balance[balance_id]['value'];
        }

        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            if( result.errors.length == 0) {
                self.sentForm = false;
                $rootScope.timerCountSec = 0;
                $('.bf-subbuyer-balance-dialog').modal('hide');
                //location.reload();
            } else {
                // Enable Save button
                self.sentForm = false;
                self.orderFormErrors = result.errors[0];
                self.orderFormFieldsErrors = result.fieldsErrors;//JSON.stringify(result.fieldsErrors);
            }
            console.log(result);
        }, function(err){
            // Enable Save button
            self.sentForm = false;
            console.log(err);
        });
    };

    /**
     * Save Factors
     */
    this.saveFactors = function(){
        // Disable Save button
        self.sentForm = true;

        url = API_LINK + '/subbuyers/saveFactors';

        //Формируем параметры на отправку
        sent_params = {};
        sent_params.subbuyerid = self.editedFactors.subbuyerid;
        sent_params.factors = {};
        for( var platform in self.editedFactors.values ){
            sent_params.factors[platform] = {};
            //console.log(self.editedFactors.values[platform]);
            //sent_params.balance[balance_id] = self.editedBalance.balance[balance_id]['value'];
            for( var type in self.editedFactors.values[platform] ){
                sent_params.factors[platform][type]= {};
                //console.log(self.editedFactors.values[platform][type]);
                for( var size in self.editedFactors.values[platform][type] ){
                    if(
                        typeof self.editedFactors.values[platform][type][size] != 'undefined'
                        && self.editedFactors.values[platform][type][size] != 0
                        && self.editedFactors.values[platform][type][size] != '0.00'
                    ){
                        console.log(self.editedFactors.values[platform][type][size]);
                        sent_params.factors[platform][type][size] = self.editedFactors.values[platform][type][size];
                    }
                }
            }
        }

        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            if( result.errors.length == 0) {
                self.sentForm = false;
                $rootScope.timerCountSec = 0;
                $('.bf-subbuyer-factors-dialog').modal('hide');
                //location.reload();
            } else {
                // Enable Save button
                self.sentForm = false;
                self.orderFormErrors = result.errors[0];
                self.orderFormFieldsErrors = result.fieldsErrors;//JSON.stringify(result.fieldsErrors);
            }
            console.log(result);
        }, function(err){
            // Enable Save button
            self.sentForm = false;
            console.log(err);
        });
    };

    /**
     * Get Subbuyer Markup for edit
     */
    this.editMarkup = function(id){
        // http://ec.term.biflorica:8080/api/subbuyers/balance/id/3824
        $resource(API_LINK + '/subbuyers/:action',
            {action:'markup', 'id': id},
            {get: {method:'GET'}}
        ).get(function(result){
            self.editedBalance = result.data.subbuyer;

            console.log(self.editedBalance);
        });
    };

    /**
     * Get Subbuyer Markup Extend for edit
     */
    this.editMarkupExtend = function(id, user_id){
        // http://ec.term.biflorica:8080/api/subbuyers/markupExtend/id/3824
        $resource(API_LINK + '/subbuyers/:action',
            {action:'markupExtend', 'id': id, 'user_id': user_id, 'platform_id': self.editedMarkup.platform},
            {get: {method:'GET'}}
        ).get(function(result){
            self.editedMarkup = result.data.subbuyer;
        });
    };
    $rootScope.editMarkupExtend = this.editMarkupExtend;

    this.changeMarkupPlatform = function(subbuyer){
        $resource(API_LINK + '/subbuyers/:action',
            {action:'markupPlantations', 'platform_id': subbuyer.editedMarkup.platform},
            {get: {method:'GET'}}
        ).get(function(result){
            self.editedMarkup.plantations = result.data.plantations;
        });
    };

    /**
     * Save Markup
     */
    this.saveMarkup = function(){
        // Disable Save button
        self.sentForm = true;

        //Формируем параметры на отправку
        sent_params = {};
        sent_params.subbuyerid = self.editedBalance.subbuyerid;
        sent_params.markup = self.editedBalance['markup'];

        $http({
            method: 'POST',
            url: API_LINK + '/subbuyers/saveMarkup',
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            if( result.errors.length == 0) {
                self.sentForm = false;
                $rootScope.timerCountSec = 0;
                $('.bf-subbuyer-markup-dialog').modal('hide');
                //location.reload();
            } else {
                // Enable Save button
                self.sentForm = false;
                self.markupFormErrors = result.errors[0];
                self.markupFormFieldsErrors = result.fieldsErrors;
            }
            console.log(result);
        }, function(err){
            // Enable Save button
            self.sentForm = false;
            console.log(err);
        });
    };



    /**
     * Save Markup Extend
     */
    this.saveMarkupExtend = function(){
        // Disable Save button
        self.sentForm = true;

        //Формируем параметры на отправку
        sent_params = {};
        sent_params.subbuyerid = self.editedMarkup.subbuyerid;
        sent_params.platform_id = self.editedMarkup['platform'];
        sent_params.plantation_id = self.editedMarkup['plantation'];
        sent_params.markup = self.editedMarkup['markup'];

        $http({
            method: 'POST',
            url: API_LINK + '/subbuyers/saveMarkupExtend',
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            if( result.errors.length == 0) {
                self.sentForm = false;
                $rootScope.timerCountSec = 0;
                $('.bf-subbuyer-markup-extend-dialog').modal('hide');
                location.reload();
            } else {
                // Enable Save button
                self.sentForm = false;
                self.markupExtendFormErrors = result.errors[0];
                self.markupExtendFormFieldsErrors = result.fieldsErrors;
            }
            console.log(result);
        }, function(err){
            // Enable Save button
            self.sentForm = false;
            console.log(err);
        });
    };

    this.removeMarkupExtend = function(id){
        $resource(API_LINK + '/subbuyers/:action',
            {action:'removeMarkupExtend', 'id': id},
            {get: {method:'GET'}}
        ).get(function(result){
            location.reload();
        });
    };

    this.changeType = function(id){
        $resource(API_LINK + '/subbuyers/:action',
            {action:'changeType', 'id': id},
            {get: {method:'GET'}}
        ).get(function(result){
            location.reload();
        });
    };
    /**
     * Get Subbuyer Transfer for edit
     *
     */
    this.moveBalance = function(id, currency, direct){
        // http://ec.term.biflorica:8080/api/subbuyers/transfer/id/3824
        $resource(API_LINK + '/subbuyers/:action',
            {action:'transfer', 'id': id, 'currency': currency, 'direct': direct},
            {get: {method:'GET'}}
        ).get(function(result){
            self.editedBalance = result.data.subbuyer;
            self.editedBalance.transfer_direct = direct;
            console.log(self.editedBalance);
        });
    };

    /**
     * Save Transfer
     */
    this.saveTransfer = function(){
        // Disable Save button
        self.sentForm = true;

        //Формируем параметры на отправку
        sent_params = {};
        sent_params.subbuyerid = self.editedBalance.subbuyerid;
        sent_params.direct = self.editedBalance.transfer_direct;
        sent_params.currency_id = self.editedBalance.currency.id;
        sent_params.transfer_sum = self.editedBalance.transfer_sum;
        sent_params.comment = self.editedBalance.comment;

        $http({
            method: 'POST',
            url: API_LINK + '/subbuyers/saveTransfer',
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            // Enable Save button
            self.sentForm = false;
            if(result.data.errors.length == 0) {
                $rootScope.timerCountSec = 0;
                $('.bf-subbuyer-transfer-dialog').modal('hide');
            } else {
                self.transferFormErrors = result.data.errors[0];
                self.transferFormFieldsErrors = result.data.fieldsErrors;
            }
            console.log(result);
        }, function(err){
            // Enable Save button
            self.sentForm = false;
            console.log(err);
        });
    };

    /**
     * Очистка заказа
     * @returns {{}|*}
     */
    this.reset = function(){
        self.edited = {};
        self.clearErrors();
        return true;
    };

    this.orderFormTitleCreate = function(){
        return typeof self.editedOrder.guid == 'undefined' || self.editedOrder.guid == '-1';
    };

    /**
     * Очищаем ошибки на форме заказа
     */
    this.clearErrors = function(){
        self.subbuyerFormErrors = {};
        self.subbuyerFormFieldsErrors = {};
    };

    this.incrementValue = function (obj, value, step, fixed) {
        current = parseFloat(obj[value]);
        step = parseFloat(step);
        var rez = current + step;
        obj[value] = rez.toFixed(fixed);
    };
    this.decrementValue = function (obj, value, step, fixed) {
        current = parseFloat(obj[value]);
        step = parseFloat(step);
        var rez = current - step;
        if(rez >= 0){
            obj[value] = rez.toFixed(fixed);
        }
    };

}]);
