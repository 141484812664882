angular.module('bfServices.changeprice', [])

/**
 * Сервис  Прайслиста
 */
.service('changeprice', [ '$http', '$resource', '$rootScope', function($http, $resource, $rootScope) {

    // Выбранные заявки для изменения
    this.requests;

    this.operation;    // true - increment, false - decrement
    this.incPriceValue;
    this.decPriceValue;

    this.boxesTypes = {
            0: {
                'id': 1,
                'name':"HB"
            },
            1: {
                'id': 2,
                'name':"QB"
            }
        };
    this.boxesType;

    this.min_pack;
    this.max_pack;
    this.boxesValue;

    this.price40;
    this.price50;
    this.price60;
    this.price70;
    this.price80;
    this.price90;
    this.price100;
    this.price100p;

    this.submark_selected = 0;
    this.submarks = {};
    this.sentProcess = false;

    this.priceFormFieldsErrors;
    this.requestFormErrors;

    var self = this;

    /**
     * Инициализация
     */
    this.reset = function(){
        self.requests = [];
        self.operation =true;    // true - increment, false - decrement
        self.incPriceValue = 0;
        self.decPriceValue = 0;

        self.boxesType = 0;

        self.boxesValue = 0;

        self.min_pack = 0;
        self.max_pack = 0;

        self.price40 = 0;
        self.price50 = 0;
        self.price60 = 0;
        self.price70 = 0;
        self.price80 = 0;
        self.price90 = 0;
        self.price100 = 0;
        self.price100p = 0;
        
        self.submark_selected = 0;
        self.submarks = {};
        self.sentProcess = false;

        self.priceFormFieldsErrors = {};
        self.requestFormErrors = 0;
    };
    this.reset;

    /**
     * Открываем форму изменения прайса в выбранных заявках
     */
    this.changePrice = function () {
        self.reset();
        self.requests = $rootScope.getIds();
        $('.bf-change-price-dialog').modal('show');
    };

    /**
     * Открываем форму изменения прайса в выбранных заявках
     */
    this.changeMarking = function () {
        self.reset();
        self.requests = $rootScope.getIds();
        $resource(API_LINK + '/submarks/easyList',
            {},
            {get: {method:'GET'}}
        ).get(function(result){
            console.log(result);
            self.submarks = result.data.list;
            console.log(self.submarks);
            $('.bf-change-marking-dialog').modal('show');
        });
    };

    this.setBoxes = function () {
        self.reset();
        self.requests = $rootScope.getIds();
        $('.bf-change-boxes-dialog').modal('show');
    };

    this.setAllPrices = function () {
        self.reset();
        self.requests = $rootScope.getIds();
        $('.bf-change-all-prices-dialog').modal('show');
    };

    /**
     * Применение изменений
     */
    this.save = function() {
        self.sentProcess = true;
        var sent_params = {};

        sent_params.operation = self.operation;

        sent_params['requests'] = $rootScope.getIDsForChangePrice();

        if(self.operation){
            sent_params.value = self.incPriceValue;
            sent_params.operation = 1;
        }else{
            sent_params.value = self.decPriceValue;
            sent_params.operation = 0;
        }

        $http({
            method: 'POST',
            url: API_LINK + '/requests/changePrice',
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            console.log(result);
            result = result.data;
            if( result.errors.length == 0) {
                location.reload();
            } else {
            }
            $('.bf-change-price-dialog').modal('hide');
            self.sentProcess = false;
        }, function(err){
            console.log(err);
            self.sentProcess = false;
        });
    };

    /**
     * Применение изменений
     */
    this.saveMarking = function() {
        self.sentProcess = true;
        var sent_params = {};

        sent_params['requests'] = $rootScope.getIDsForChangePrice();

        sent_params.value = self.submark_selected.submark_id;

        $http({
            method: 'POST',
            url: API_LINK + '/requests/changeMarking',
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            console.log(result);
            result = result.data;
            if( result.errors.length == 0) {
                location.reload();
            } else {
            }
            $('.bf-change-marking-dialog').modal('hide');
            self.sentProcess = false;
        }, function(err){
            console.log(err);
            self.sentProcess = false;
        });
    };

    /**
     * Сохранить ящики
     */
    this.saveBoxes = function() {
        self.sentProcess = true;
        var sent_params = {};

        sent_params['requests'] = $rootScope.getIDsForChangePrice();

        sent_params.value = self.boxesValue;
        if(typeof self.boxesType.value !== 'undefined' && typeof self.boxesType.value.id !== 'undefined'){
            sent_params.boxesType = self.boxesType.value.id;
        }else{
            sent_params.boxesType = 0;
        }
        sent_params.min_pack = self.min_pack;
        sent_params.max_pack = self.max_pack;

        $http({
            method: 'POST',
            url: API_LINK + '/requests/changeBoxes',
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            console.log(result);
            result = result.data;
            if( result.errors.length == 0) {
                location.reload();
            } else {
                self.priceFormFieldsErrors = result.fieldsErrors;
                self.requestFormErrors = result.errors[0];
            }
            self.sentProcess = false;
        }, function(err){
            console.log(err);
            self.sentProcess = false;
        });
    };

    /**
     * Сохранить цены
     */
    this.saveAllPrices = function() {
        self.sentProcess = true;
        var sent_params = {};

        sent_params['requests'] = $rootScope.getIDsForChangePrice();

        sent_params.value40 = self.price40;
        sent_params.value50 = self.price50;
        sent_params.value60 = self.price60;
        sent_params.value70 = self.price70;
        sent_params.value80 = self.price80;
        sent_params.value90 = self.price90;
        sent_params.value100 = self.price100;
        sent_params.value100p = self.price100p;

        $http({
            method: 'POST',
            url: API_LINK + '/requests/changeAllPrices',
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            console.log(result);
            result = result.data;
            if( result.errors.length == 0) {
                location.reload();
            } else {
            }
            $('.bf-change-all-prices-dialog').modal('hide');
            self.sentProcess = false;
        }, function(err){
            console.log(err);
            self.sentProcess = false;
        });
    };

    /**
     * Применение изменений
     */
    this.close = function() {
        self.reset();
        $('.bf-change-price-dialog').modal('hide');
        $('.bf-change-marking-dialog').modal('hide');
        $('.bf-change-boxes-dialog').modal('hide');
        $('.bf-change-all-prices-dialog').modal('hide');
    };

    this.incrementValue = function (obj, value, step, fixed) {
        current = parseFloat(obj[value]);
        step = parseFloat(step);
        var rez = current + step;
        obj[value] = rez.toFixed(fixed);
    };
    this.decrementValue = function (obj, value, step, fixed) {
        current = parseFloat(obj[value]);
        step = parseFloat(step);
        var rez = current - step;

        if(rez >= 0){
            obj[value] = rez.toFixed(fixed);
        }
    };

    /**
     * Очищаем ошибки на форме
     */
    this.clearErrors = function(){
        self.pricetFormErrors = {};
        self.priceFormFieldsErrors = {};
    };
}]);
