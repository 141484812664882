angular.module('bfServices.reclamation', [])

/**
 * Сервис  Прайслиста
 */
.service('reclamation', [ '$http', '$resource', '$rootScope', function($http, $resource, $rootScope) {
    // Активная рекламация с которой производится работа
    this.edited = {
        sum_price: {}
    };

    // Ошибки
    this.errors = {};

    // Новые рекламации, только для селлеров
    this.new = 0;

    var self = this;

    this.init = function(){
        self.new = START_NEWRECLAMATIONS_VALUE;
    };

    // Сразу инициализирум
    this.init();
    
    /**
     * Инициализация
     * @param deal_id - ID сделки к которой предъявляется рекламация
     */
    this.set = function(deal_id){
        //Очищаем ошибки на форме
        self.clearErrors();



        url = API_LINK + '/reclamations/create/deal_id/' + deal_id;

        $resource(
            url,
            {},
            {get: {method:'GET'}}
        ).get(function(result){
            self.edited = result.data.item;
            self.edited.reason.items = self.edited.reason.items;
            self.recalculatePrice(self.edited);

            //self.normallizeVarieties();
            console.log(self.edited);
        });
    };

    /**
     * Получение
     * @returns {{}|*}
     */
    this.get = function(){
        return self.edited;
    };

    /**
     * Сохранение 
     * @param 
     */
    this.save = function(send){
        // http://ec.term.biflorica:8080/api/reclamations/create/id/1
        url = API_LINK + '/reclamations/create/deal_id/' + self.edited.deal_id;
        
        var sent_params = this.getParamsFromEdited(self.edited);
        var files_count = 0;
        // Проверка и перегрузка файлов
        var files_obj = $('#fine-uploader-gallery').fineUploader('getUploads');
        for(var id_file in files_obj){
            var file_obj = files_obj[id_file];
            if(file_obj.status == "upload successful"){
                sent_params['files['+file_obj.id+']'] = file_obj.uuid + '__' + file_obj.originalName;
                files_count++;
            }
        }

        // Файлы фото обязательны к загрузке
        if(files_count == 0 && !self.edited.photos.length && !self.edited.videos.length){
            self.errors = {"files": ["Files not be empty! Please upload file first"]};
            return;
        }

        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            if( result.data.errors.length == 0) {
                self.clearErrors();
                $('.bf-create-reclamation-dialog').modal('hide');
                $('.bf-reclamation-info-dialog').modal('show');
                //$rootScope.timerCountSec = 0;
                //location.reload();
                if(send){
                    self.send();
                }
            } else {
                self.errors = result.data.errors[0];
                self.errors = result.data.fieldsErrors;
            }
        }, function(err){
        });
    };

    /**
     * Запрос суммарной информации по форме рекламации
     */
    this.recalculatePrice = function(edited){
        console.log(edited)
        // http://ec.term.biflorica:8080/api/reclamations/getPrice/
        url = API_LINK + '/reclamations/getPrice';

        $http({
            method: 'POST',
            url: url,
            data: $.param(edited),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            if( result.data.errors.length == 0) {
                console.log(result.data.data);
                self.edited.sum_price = result.data.data;
            } else {
                //$scope.requestFormErrors = result.errors[0];
                //$scope.requestFormFieldsErrors = result.fieldsErrors;
            }
        }, function(err){
        });
    };

    /**
     * Очистка
     * @returns {{}|*}
     */
    this.reset = function(){
        self.clearErrors();
        self.setEmpty();
        return true;
    };

    /**
     * Форму рекламации закрыли по кнопке Close
     * @returns {{}|*}
     */
    this.close = function(){
        // ToDo: Очистить все файлы загруженные ранее
        self.reset();
        return true;
    };

    /**
     * Форму информации после рекламации закрыли по кнопке Close
     * @returns {{}|*}
     */
    this.closeInfo = function(){
        console.log('---');
        location.reload();
        return true;
    };

    /**
     * Формируем параметры на отправку
     * @param edited
     * @returns {{}|*}
     */
    this.getParamsFromEdited = function(edited, send){
        var sent_params = {};

        sent_params.id = edited.id;
        sent_params.deal_id = edited.deal_id;
        sent_params.deal_info = edited.deal_info;
        sent_params.reclamation_reason_id = edited.reclamation_reason_id;
        sent_params.status = (send) ? 1 : 9;
        sent_params.comment = edited.comment;
        sent_params.items = [];

        if (edited.seller_result_return_price !== void 0) {
            sent_params.seller_result_return_price = edited.seller_result_return_price;
            sent_params.comment_from_plantation = edited.comment_from_plantation;
        }

        for (var index in edited.items) {
            if (edited.items[index].checked.length ) {
                sent_params.items[index] = []
                sent_params.items[index] = edited.items[index].stems;
            }
        }

        return sent_params;
    };

    /**
     *  Заголовок формы Прайса
     */
    this.formTitleCreate = function(){
        return self.edited.id == -1;
    };

    /**
     * Отображение сорта в списке только соответствующего типа
     */
    this.checkTypeOfVariety = function(sortid){
        if(
            typeof this.edited.type.selected != 'undefined'
            && this.edited.type.selected != ''
            && sortid.indexOf(this.edited.type.selected) == 0
        ){
            return true;
        }else{
            return false;
        }
    };

    this.incrementValue = function (obj, step, fixed) {
        current = parseFloat(obj.stems);
        step = parseFloat(step);
        var rez = current + step;
        obj.stems = rez.toFixed(fixed);
    };
    this.decrementValue = function (obj, step, fixed) {
        current = parseFloat(obj.stems);
        step = parseFloat(step);
        var rez = current - step;
        if(rez >= 0){
            obj.stems = rez.toFixed(fixed);
        }
    };

    /**
     * пустая структура для инициализации и обнуления
     */
    //
    this.setEmpty = function(){
        self.edited = {
            reason: { items: [] },
        };
        self.editedSum = {};
        self.editedsShow = false;
        self.sentForm = false;
        $('#fine-uploader-gallery').fineUploader('reset');
    };
    this.setEmpty();

    /**
     * Рекламация редактируется - true
     */
    this.edit = function(){
        if ($rootScope.current_user_role == 'seller')
            return true;

        if (self.edited.status_id == '1' || self.edited.status_id == '2' || self.edited.status_id == '4')
            return true

        if (self.edited.status_id == '3') {
            return false
        }

        if(
            typeof self.edited.id != 'undefined'
            && self.edited.id != 0
            && self.edited.reclamation_reason_id != 3
        ){
            return true;
        }else{
            return false;
        }
    };
    
    this.send = function(){
        $http({
            method: 'GET',
            url: API_LINK + '/reclamations/forConsideration/id/' + self.edited.deal_id,
            //data: $.param({id: deal_id}),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            console.log(result);
            //location.reload();
        }, function(err){
            console.log(err);
        });
    };
    
    /**
     * Очищаем ошибки на форме
     */
    this.clearErrors = function(){
        self.reclamationFormErrors = {};
        self.reclamationFormFieldsErrors = {};
    };

    this.sortResaltABC = function(input_date){
        var listNew = [];
        for(var i in input_date){
            listNew.push({ id: input_date[i].id, name: input_date[i].name});
        }
        return listNew.sort(self.compareObjects);
    };

    this.compareObjects = function(a, b) {
        if (self.strip(a.name) > self.strip(b.name)) return 1;
        if (self.strip(a.name) < self.strip(b.name)) return -1;
        return 0;
    };

    this.strip = function(str) {
        return str.replace(/<.*?>/g, '');
    }
}]);
