angular.module('bfControllers.imports', [])
.controller('ImportsController', ['$scope', '$rootScope', '$resource', '$cookies', '$http', 'imports', 'commet', 'utils', 'helpers', 'uiGridConstants', '$q', function($scope, $rootScope, $resource, $cookies, $http, imports, commet, utils, helpers, uiGridConstants, $q){

    $scope.imports = imports;

    var self = this;

    $scope.gridOptions = {
        enableSorting: false,
        enableGridMenu: true,
        //enableImporter: true,
        enableColumnResizing: true,
        columnDefs: [
            { field: 'number', width: 100, enableCellEdit: false },
            { field: 'input', displayName: 'Input string', enableColumnResizing: true,
                /*cellEditableCondition: function($scope){
                    return $scope.rowRenderIndex%2
                },*/
                cellClass: cellClassDirect
            },
            { field: 'boxes', width: 70, enableColumnSorting: false, enableCellEdit: false },
            { field: 'boxes_type', displayName: 'Boxes type', width: 100, enableColumnSorting: false, enableCellEdit: false },
            { field: 'variety', enableColumnResizing: true, enableCellEdit: false },
            { field: 'sizes', width: 50, enableColumnSorting: false, enableCellEdit: false },
            { field: 'prices', width: 100 , enableColumnSorting: false, enableCellEdit: false},
            { field: 'comment', enableCellEdit: false }
            //{ field: 'sort_id', width: 70, enableCellEdit: false },
            //{ field: 'actions', enableCellEdit: false }
            //{ field: 'name', minWidth: 200, width: 250, enableColumnResizing: false },
            //{ field: 'gender', width: '30%', maxWidth: 200, minWidth: 70 },
        ],
        data: 'imports.bfData',
        importerDataAddCallback: function ( grid, newObjects ) {
            console.log(grid);
            console.log(newObjects);
            //$scope.data = $scope.data.concat( newObjects );
        },
        /*importerObjectCallback: function ( grid, newObject ) {
            console.log(grid);
            console.log(newObject);
        },
        importThisFile(grid, fileObject){
            console.log(grid);
            console.log(fileObject);
        },*/
        onRegisterApi: function(gridApi){
            $scope.gridApi = gridApi;
            //$scope.gridApi.grid.registerRowsProcessor( $scope.singleFilter2, 200 );
            gridApi.rowEdit.on.saveRow($scope, $scope.saveRow);
            //gridApi.core.on.rowsRendered( $scope, $scope.rowsRendered );
        }
    };

    /*$scope.saveRow = function( rowEntity ) {
        // create a fake promise - normally you'd use the promise returned by $http or $resource
        var promise = $q.defer();
        $scope.gridApi.rowEdit.setSavePromise( rowEntity, promise.promise );
        console.log(rowEntity);

        $scope.imports.revalidateRow(rowEntity);
        if (rowEntity.sort_id == 0 ){
            promise.reject();
        } else {
            promise.resolve();
        }
    };*/

    function cellClassDirect(grid, row, col, rowRenderIndex, colRenderIndex) {
        console.log(grid);
        console.log(row);
        console.log(col);
        console.log(rowRenderIndex);
        console.log(colRenderIndex);
        if (row.entity.sort_id == 0) {
            return 'wrong_sort';
        }else{
            return 'fine_sort';
        }
    }

    $scope.saveRow = function( rowEntity ) {
        // create a fake promise - normally you'd use the promise returned by $http or $resource
        var promise = $q.defer();
        $scope.gridApi.rowEdit.setSavePromise( rowEntity, promise.promise );
        console.log(rowEntity);

        $scope.imports.revalidateRow(rowEntity);
        console.log(rowEntity);
        promise.resolve();
        /*if (rowEntity.sort_id == 0 ){
            promise.reject();
        } else {
            promise.resolve();
        }*/
        $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
    };

    /*$scope.rowsRendered = function (rowEntity){
        console.log(rowEntity);
    };

    $scope.singleFilter2 = function( renderableRows ){
        console.log(renderableRows);
        renderableRows.forEach( function( row ) {
            console.log(row);
            var promise = $q.defer();
            $scope.gridApi.rowEdit.setSavePromise( row.entity, promise.promise );
            $scope.imports.revalidateRow(row.entity);
            if (row.entity.sort_id == 0 ){
                promise.reject();
            } else {
                promise.resolve();
            }
        });
        return renderableRows;
    };

    $scope.singleFilter = function( renderableRows ){
        var matcher = new RegExp($scope.filterValue);
        renderableRows.forEach( function( row ) {
            var match = false;
            [ 'name' ].forEach(function( field ){
                if ( row.entity[field].match(matcher)){
                    match = true;
                }
            });
            console.log($scope.filterValueAge);
            if (match && $scope.filterValueAge !== undefined)
            {
                console.log($scope.filterValueAge === '');
                console.log(row.entity['age'] === $scope.filterValueAge);
                if (row.entity['age'] === $scope.filterValueAge){
                    console.log('found age match');
                    match = true;
                }
                else
                {
                    match = false
                }
            }

            if (!match)
            {
                row.visible = false;
            }
        });
        return renderableRows;
    };*/

    self.paste = function(e){
        //console.log(e);
        e.preventDefault();
        var input_str = (e.originalEvent || e).clipboardData.getData('text/plain');
        console.log(input_str);
        var input_arr = input_str.split(/\r\n|\r|\n/g);
        //console.log(input_arr);
        $scope.imports.inputData(input_arr);
    };

    self.copy = function(e){
        //console.log(e);
        e.preventDefault();
        var output_str = $scope.imports.outputData();
        console.log(output_str);
        //(e.originalEvent || e).clipboard.writeText(output_str);
        /*
        navigator.clipboard.writeText(output_str)
            .then(() => {
                console.log('Its OK')
            })
            .catch(err => {
                console.log('Something went wrong', err);
            });;
         */
        var tmp   = document.createElement('INPUT'),
            focus = document.activeElement;

        tmp.value = output_str;

        document.body.appendChild(tmp);
        tmp.select();
        document.execCommand('copy');
        document.body.removeChild(tmp);
        focus.focus();
    };

    self.clear = function(e){
        e.preventDefault();
        console.log($scope.imports.bfData);
        $scope.imports.bfData = [];
        $scope.imports.successfull_loaded = 0;
    };

    self.saveAsExcel = function(e){
        e.preventDefault();
        $scope.imports.saveAsExcel();
    }

    self.getTemplate = function(e){
        e.preventDefault();
        $scope.imports.getTemplate();
    }

    self.reCheck = function(e){
        e.preventDefault();
        console.log($scope.grid);
        console.log($scope.grid.rows);
    }

    self.parse = function(e){
        e.preventDefault();
        $scope.imports.parse();
    }

    self.upload = function(e){
        e.preventDefault();
        $scope.imports.upload();
    }

    // Действия после загрузки страницы
    //this.getSenders();
    //this.getMessages();

    self.importFile = function ( grid, fileObject) {
        if (!fileObject) {
            //gridUtil.logError( 'No file object provided to importThisFile, should be impossible, aborting');
            console.log( 'No file object provided to importThisFile, should be impossible, aborting')
            return;
        }

        var reader = new FileReader();

        switch ( fileObject.type ) {
            case 'text/plain':
                reader.onload = function() {
                    var input_str = reader.result;
                    console.log(input_str);
                    var input_arr = input_str.split(/\r\n|\r|\n/g);
                    //console.log(input_arr);
                    $scope.imports.inputData(input_arr);
                };
                reader.onerror = function() {
                    console.log(reader.error);
                };
                reader.readAsText( fileObject );
                break;
            case 'application/json':
                //reader.onload = service.importJsonClosure( grid );
                alert('Format '+fileObject.type+' not implemented')
                break;
            case 'text/csv':
                //reader.onload = service.importJsonClosure( grid );
                alert('Format '+fileObject.type+' not implemented')
                break;
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                reader.onload = function(event) {
                    var excel_file = reader.result;
                    //console.log(excel_file);
                    var workbook = XLSX.read(excel_file, {type: 'binary'});
                    //var headerNames = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]], { header: 1 })[0];
                    var worksheet = workbook.Sheets[workbook.SheetNames[0]];
                    var data = XLSX.utils.sheet_to_json( worksheet, {header: "A"});
                    var input_arr = $scope.imports.inputExcel(data);
                    //console.log(input_arr);
                    $scope.imports.inputData(input_arr);
                };
                reader.onerror = function() {
                    console.log(reader.error);
                };
                reader.readAsBinaryString( fileObject );
                //alert('Format '+fileObject.type+' not implemented')
                break;
            default:
                //reader.onload = service.importCsvClosure( grid );
                alert('Format '+fileObject.type+' unknown !!!')
                console.log('Тип файла неизвестен!')
                break;
        }


    },

    self.handleFileSelect = function( event ){
        var target = event.srcElement || event.target;

        if (target && target.files && target.files.length === 1) {
            var fileObject = target.files[0];
            console.log(fileObject);
            /* var tmp = {
                lastModified: 1717597800678,
                lastModifiedDate: 'Wed Jun 05 2024 17:30:00 GMT+0300 (Восточная Европа, летнее время)',
                name: "import1.txt", //import.json, "import_test.csv", "*.xlsx",
                size: 152,
                type: "text/plain", //"application/json", "text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                webkitRelativePath: ""
            }*/
            //$scope.gridApi.importer.importFile( fileObject );

            self.importFile($scope.imports.bfData, fileObject);

            target.form.reset();
        }
    };

    var fileChooser = document.querySelectorAll('.file-chooser');

    if ( fileChooser.length !== 1 ){
        console.log('Found > 1 or < 1 file choosers within the menu item, error, cannot continue');
    } else {
        fileChooser[0].addEventListener('change', self.handleFileSelect, false);
    }
} ])

.filter('unsafe', function($sce) {
    return function(val) {
        return $sce.trustAsHtml(val);
    };
});