angular.module('bfServices.utils', [])

.service('utils', [function() {

    var self = this;

    /**
     * Возвращает массив IDs из переданного списка
     *
     * @param list
     * @returns {Array}
     */
    this.getGridEntityIds = function (list) {
        var ids = [];

        for (num in list) {
            if (list.hasOwnProperty(num) && list[num]['guid']) {
                ids.push(list[num]['guid'])
            }
        }

        return ids;
    };

    /**
     * Добавляет запись в начало списка
     *
     * @param obj
     * @param list
     * @param comparedKey
     * @returns {boolean}
     */
    this.pushInFront = function (obj, list, comparedKey) {
        for (num in list) {
            if (!list.hasOwnProperty(num)) {
                continue;
            }

            // already loaded
            if (list[num][comparedKey] === obj[comparedKey]) {
                return false;
            }
        }

        list.unshift(obj);
        return true;
    }

    /**
     * Изменяет запись в списке. Поиск по guid
     *
     * @param obj
     * @param list
     * @returns {boolean}
     */
    this.changeEntityInGrid = function (obj, list) {
        for (num in list) {
            if (!list.hasOwnProperty(num)) {
                continue;
            }

            // search by guid
            if (list[num].guid === obj.guid) {

                // copy props
                self.copyProperties(obj, list[num]);

                return true;
            }
        }

        return false;
    }

    /**
     * Копирует свойства из firstObj в secondObj
     *
     * @param firstObj
     * @param secondObj
     */
    this.copyProperties = function (firstObj, secondObj) {
        for (var prop in firstObj) {
            if (firstObj.hasOwnProperty(prop) && secondObj.hasOwnProperty(prop)) {
                secondObj[prop] = firstObj[prop];
            }
        }
    }

}]);
