angular.module('bfServices.balances', [])

.service('balances', ['$resource', function($resource) {

    this.data = {};
    this.main_balance = {};
    this.second_balance = {};

    this.platform_currency = 1; // доллар

    var self = this;

    // Если на платформе расчеты в долларах,
    // то формат вывода в балансе пользователя будет доллары/евро
    // если нет, то евро/доллары
    this.updateData = function (data) {
        self.data = data;

        // доллар
        if (self.platform_currency === 1) {
            self.main_balance = data[1];
            self.second_balance = data[2];
            if(data[1].promised_balance_value != 0){
                self.promised_balance = data[1].promised_balance + data[1].promised_date_balance;
            } else if (typeof self.promised_balance !== 'undefined') {
                delete self.promised_balance;
            }
        } else { // евро
            self.main_balance = data[2];
            self.second_balance = data[1];
            if(data[2].promised_balance_value != 0){
                self.promised_balance = data[2].promised_balance + data[2].promised_date_balance;
            } else if (typeof self.promised_balance !== 'undefined') {
                delete self.promised_balance;
            }
        }
    };


    /**
     * Загрузка балансов
     * http://ec.term.biflorica:8080/api/messages/allBalances
     */
    this.set = function(){

        self.platform_currency = parseInt(START_ALL_BALANCES_VALUE.main_balance);
        self.updateData(START_ALL_BALANCES_VALUE);

        /* BIF-4646 Убрать запросы к API
        $resource(API_LINK + '/messages/:action',
            {action:'allBalances'},
            {get: {method:'GET'}}
        ).get(function(result) {
            if (typeof result.data === 'undefined') {
                return false;
            }
            self.platform_currency = parseInt(result.data.main_balance);
            self.updateData(result.data);
            //console.log(self);
        });
         */
    };

    // Сразу загружаем
    this.set();

}]);
