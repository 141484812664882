angular.module('bfControllers.cargo', [])
        .controller('CargoController', ['$scope', '$rootScope', '$resource', '$cookies', '$http', 'expectedVolume', 'helpers', function ($scope, $rootScope, $resource, $cookies, $http, expectedVolume, helpers) {

                $scope.pagination = new helpers.pagination(ITEMS_SHOW_PER_PAGE);
                $scope.renderFiltersVal = function (filter) {
                    var filtersVal = {};
                    filter.each(function () {
                        filtersVal[this.id] = this.value;
                    });
                    return filtersVal;
                }

                /**
                 * Get list plantation invoices
                 */
                $rootScope.renewCargoItems = function () {
                    $rootScope.querySent = true;
                    // http://ec.term.biflorica:8080/api/cargo
                    var airDateInterval = document.getElementById('datepicker');
                    $resource(API_LINK + '/cargo/ordersList', {
                        airDateInterval: (airDateInterval) ? airDateInterval.value : 0,
                        page: $scope.pagination.currPage,
                        limit: $scope.pagination.itemsPerPage,
                    },
                            {get: {method: 'GET'}}
                    ).get(function (result) {
                        $scope.items = result.data.list;
                        var count = parseInt(result.data.count_items);
                        var total = parseInt(result.data.total_items);
                        $scope.pagination.recountPages(count, total);
                        $rootScope.querySent = false;
                        
                        $rootScope.counts = $scope.pagination.count;
                    });
                };

                $rootScope.renewCargoItemsByDate = function () {
                    filters = $scope.renderFiltersVal($('.filter-select'));
                    filters['airDate'] = document.getElementById('airDate').value;
                    filters['page'] = $scope.pagination.currPage;
                    filters['limit'] = $scope.pagination.itemsPerPage;
                    $resource(API_LINK + '/cargo/ordersDateList', filters,
                            {get: {method: 'GET'}}).get(function (result) {
                        var count = parseInt(result.data.count_items);
                        var total = parseInt(result.data.total_items);
                        $scope.pagination.recountPages(count, total);

                        $scope.items = result.data.list;
                        $rootScope.counts = $scope.pagination.count;
                        $rootScope.querySent = false;
                    });
                };

                $rootScope.renewExpectedVolumeCargoItems = function () {
                    // http://ec.term.biflorica:8080/api/expectedVolumes
                    var needAirDate = document.getElementById('needAirDate');
                    $resource(API_LINK + '/expectedVolumes/cargo',
                            {
                                limit: $scope.items_per_page,
                                needAirDate: (needAirDate) ? needAirDate.value : 0,
                                page: $scope.pagination.currPage
                            },
                            {get: {method: 'GET'}}
                    ).get(function (result) {
                        var count = parseInt(result.data.count_items);
                        var total = parseInt(result.data.total_items);
                        $scope.pagination.recountPages(count, total);

                        $scope.items = result.data.list;
                        $rootScope.counts = $scope.pagination.count;
                        $rootScope.querySent = false;
                    });
                };

                $rootScope.renewItemsFromPage = $scope.pagination.renewPage = function (new_page) {
                    if (document.getElementById('expectedVolumes')) {
                        $rootScope.renewExpectedVolumeCargoItems();
                    } else if (document.getElementById('airDate')) {
                        $rootScope.renewCargoItemsByDate();
                    } else {
                        $rootScope.renewCargoItems();
                    }
                };

                // Запуск действий при загрузке страницы
                if (document.getElementById('expectedVolumes')) {
                    $rootScope.renewExpectedVolumeCargoItems();
                } else if (document.getElementById('airDate')) {
                    $rootScope.renewCargoItemsByDate();
                } else {
                    $rootScope.renewCargoItems();
                }
            }]);