angular.module('bfServices.online', [])

.service('online', ['commet', '$rootScope', function(commet, $rootScope) {

    // список продавцов online
    this.onlineSellers = [];

    // список продавцов away
    this.awaySellers = [];

    this.lastActivity = Date.now();

    this.awaySent = false;

    var self = this;

    this.isUserOnlineClass = function(userId) {
        if (self.onlineSellers.indexOf(parseInt(userId)) === -1 && self.awaySellers.indexOf(parseInt(userId)) === -1) {
            return 'plantation-offline';
        }

        if (self.awaySellers.indexOf(parseInt(userId)) === -1) {
            return 'plantation-online';
        }

        return 'plantation-away';
    };

    commet.socket.on('online', function(userId) {
        var k = self.awaySellers.indexOf(userId);

        if (k !== -1) {
            self.awaySellers.splice(k, 1);
        }

        if (self.onlineSellers.indexOf(userId) === -1) {
            self.onlineSellers.push(userId);
        }

        $rootScope.applySellerOnline(userId);
    });

    commet.socket.on('away', function(userId) {
        if (self.awaySellers.indexOf(userId) === -1) {
            self.awaySellers.push(userId);
        }

        $rootScope.applySellerOnline(userId);
    });

    commet.socket.on('offline', function(userId) {
        var k = self.onlineSellers.indexOf(userId);

        if (k !== -1) {
            self.onlineSellers.splice(k, 1);
        }

        $rootScope.applySellerOnline(userId);
    });

    commet.socket.on('onlineSellers', function(users) {
        self.onlineSellers = users;

        $rootScope.applySellerOnline();
    });

    commet.socket.on('awaySellers', function(users) {
        self.awaySellers = users;

        $rootScope.applySellerOnline();
    });

    $rootScope.mousemoveHandler = function($event) {
        self.lastActivity = Date.now()

        if (self.awaySent) {
            commet.socket.emit('online')
        }

        self.awaySent = false
    }

    this.renewTimer = function() {
        if (Date.now() - self.lastActivity > 60 * 1000) {
            if (!self.awaySent) {
                self.awaySent = true
                commet.socket.emit('away');
            }

        }

        setTimeout(self.renewTimer, 1 * 1000);
    };

    this.renewTimer();

}]);
