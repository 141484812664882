

// Style files
import './node_modules/bootstrap/dist/css/bootstrap.css';
import './node_modules/jquery-ui-dist/jquery-ui.css';
import './node_modules/ng-scrollbar/dist/ng-scrollbar.min.css';
import './node_modules/bootstrap-daterangepicker/daterangepicker.css';
import './node_modules/angular-ui-grid/ui-grid.css';
import './node_modules/owl.carousel/dist/assets/owl.carousel.css';
import './../makeup/css/main.css';
import './../makeup/css/calculator.css';
import './../makeup/css/bootstrap-custom.css';
import './../makeup/css/fontawesome-all.min.css';
import './node_modules/ui-select/dist/select.css';
import './node_modules/fine-uploader/jquery.fine-uploader/fine-uploader-gallery.css';
import './../makeup/css/fine-uploader-biflorica.css';
import './../../../../../js/lightbox/lightbox.css';
import AutoNumeric from './node_modules/autonumeric/src/main';
//import './../../../../../js/playbox-js/playbox.css';

window.AutoNumeric = AutoNumeric

// JS
import './node_modules/jquery/dist/jquery.js';
//jquery = require('jquery');
import './node_modules/jquery-ui-dist/jquery-ui.min.js';
import './node_modules/bootstrap/dist/js/bootstrap.min.js';
import './node_modules/jquery-mousewheel/jquery.mousewheel.js';
window.moment = require('moment');
moment().format();
import './node_modules/bootstrap-daterangepicker/daterangepicker.js';
import './node_modules/angular/angular.js';
import './node_modules/angular-animate/angular-animate.js';
import './node_modules/angular-resource/angular-resource.js';
import './node_modules/angular-cookies/angular-cookies.js';
import './node_modules/angular-ui-bootstrap/dist/ui-bootstrap-tpls.js';
import './node_modules/angular-ui-grid/ui-grid';
import './node_modules/angular-ui-grid/ui-grid.importer';
import './node_modules/angular-sanitize/angular-sanitize.js';
import './node_modules/ui-select/dist/select.js';
import './node_modules/ng-scrollbar/dist/ng-scrollbar.js';
import './node_modules/angularjs-dropdown-multiselect/dist/angularjs-dropdown-multiselect.min.js';
import './node_modules/fine-uploader/jquery.fine-uploader/jquery.fine-uploader';
import './node_modules/jscrollpane/script/jquery.jscrollpane';
import './node_modules/owl.carousel';
import './node_modules/xlsx';
import * as XLSX from 'xlsx';

//$ = jquery;
jQuery = $;
window.jQuery = $;
window.$ = $;

if ($) {
    console.log('JQuery loaded fine');
}else{
    console.log('JQuery not loaded');
}

import './../makeup/js/main.js';

// App JS
import './app.js';

import './fastEdit.js';
import './node_modules/jquery.maskedinput/src/jquery.maskedinput.js';
import './../../../../../js/lightbox/lightbox.js';
import './../../../../../js/jquery.cookie';
import './../../../../../js/jquery.fileupload.js';
import './../../../../../js/jquery.fileupload-ui.js';
import './../../../../../js/playbox-js/playbox.js';


import './../makeup/js/specific.js';
import './../makeup/js/plugins.js';