angular.module('bfControllers.cargoEBF', [])
.controller('CargoEBFController', ['$scope', '$rootScope', '$resource', '$cookies', '$http', function($scope, $rootScope, $resource, $cookies, $http){
    $scope.start = 0;
    $scope.count = 0;
    $scope.total = 0;
    $scope.cur_page = 1;
    $scope.pager = [];
    $scope.items_per_page = ITEMS_SHOW_PER_PAGE;
    
    $scope.plantations = { };
    $scope.selected_plantation = '';
    $scope.selected_plantation_name = '';

    $rootScope.counts = {};

    $rootScope.current_user_role = CURRENT_USER_ROLE;
    $rootScope.role_buyer = ROLE_BUYER;
    $rootScope.role_seller = ROLE_SELLER;

    $rootScope.startDate = start_date;
    $rootScope.endDate = end_date;

    /**
     * Get list of plantations
     * http://ec.term.biflorica:8080/api/cargoEBF/getPlantations
     */
    this.getPlantations = function(){
        $resource(API_LINK + '/cargoEBF/getPlantations',
            {},
            {get: {method:'GET'}}
        ).get(function(result) {
            $scope.plantations = result.data.plantations;
            $scope.selected_plantation = $scope.plantations[0].id;
            $scope.selected_plantation_name = $scope.plantations[0].name;
        });
    };
    $rootScope.getPlantations = this.getPlantations;

    /**
     * Select sender
     */
    this.selectPlantation = function(num_plantation){
        $scope.selected_plantation = $scope.plantations[num_plantation].id;
        $scope.selected_plantation_name = $scope.plantations[num_plantation].name;
        $scope.selected_plantation_type = $scope.plantations[num_plantation].type;
        $rootScope.renewItems();
    };
    $rootScope.selectPlantation = this.selectPlantation;

    /**
     * Get class for selected sender
     */
    this.classForSelectedPlantation = function(id){
        if ($scope.selected_plantation == id)
            return 'active';

        return 'no';
    };
    $rootScope.classForSelectedPlantation = this.classForSelectedPlantation;

    /**
     * Get list plantation invoices
     */
    $rootScope.renewItems = function() {
        $rootScope.querySent = true;
        // http://ec.term.biflorica:8080/api/cargoEbf
        $resource(API_LINK + '/cargoEBF/:action',
            {
                action: 'index',
                sort: $rootScope.sort,
                startDate: $rootScope.startDate,
                endDate: $rootScope.endDate,
                limit: $scope.items_per_page,
                start: 0,
                direct: $rootScope.direct,
                page: $scope.cur_page,
                plantation_id: $scope.selected_plantation
            },
            {get: {method:'GET'}}
        ).get(function(result){
            $scope.start = ($scope.cur_page - 1) * $scope.items_per_page;
            $scope.count = parseInt(result.data.count_items);
            $scope.total = parseInt(result.data.total_items);
            $scope.pager = [];

            var totalPages = Math.ceil($scope.total / $scope.items_per_page);
            for(var i=1; i <= totalPages; i += 1){
                $scope.pager.push(i);
            }

            $scope.items = result.data.list;
            $rootScope.counts = result.data.counts;
            $rootScope.querySent = false;
        });
    };

    $rootScope.renewItemsFromPage = function (new_page) {
        $scope.cur_page = new_page;
        $rootScope.renewItems();
    };

    $scope.renewRange = function(start, end) {
        $rootScope.startDate = start;
        $rootScope.endDate = end;
    };

    // Выбранные значения сортировки
    var sortValueFromCookies = $cookies.get('cargo-ebf-sort');
    if (typeof sortValueFromCookies !== 'undefined') {
        $rootScope.sort = sortValueFromCookies;
    } else {
        $rootScope.sort = 'id';
        $cookies.put('cargo-ebf-sort', 'id');
    }
    var directValueFromCookies = $cookies.get('direct');
    if (typeof directValueFromCookies !== 'undefined') {
        $rootScope.direct = directValueFromCookies;
    } else {
        $rootScope.direct = 'desc';
        $cookies.put('direct', 'desc');
        $rootScope.renewItems();
    }
    this.setSort = function(sort_column) {
        if ($rootScope.sort == sort_column) {
            // Изменяем направление сортировки
            if ($rootScope.direct == 'asc') {
                $rootScope.direct = 'desc';
                $cookies.put('direct', 'desc');
            } else {
                $rootScope.direct = 'asc';
                $cookies.put('direct', 'asc');
            }
        } else {
            // Изменяем сортировку
            $cookies.put('cargo-ebf-sort', sort_column);
            $rootScope.sort = sort_column;
        }
        $rootScope.renewItems();
    };
    $rootScope.setSort = this.setSort;

    $scope.classSort = function(sort_column) {
        if ($rootScope.sort == sort_column) {
            // Изменяем направление сортировки
            if ($rootScope.direct == 'asc') {
                return 'sorted sorted-up';
            } else {
                return 'sorted sorted-down';
            }
        }
    };

    // Запуск действий при загрузке страницы
    $rootScope.getPlantations();
    $rootScope.renewItems();
} ]);