angular.module('bfServices.commet', [])

.service('commet', ['$rootScope', function($rootScope) {

    this.socket = {};

    this.user_id = $.cookie('user');

    var self = this;

    /**
     * Инициализация commet
     */
    this.initCommet = function(){
        console.log(commet_server);

        // соединяемся с нодой
        self.socket = io.connect(commet_server+'/term', {
            query: 'token=' + $.cookie('node_hash') + '&user_id=' + self.user_id
        });

        // эвент на соединение срабатывает лишь один раз
        self.socket.once('connect', function(data) {
            console.log('EVENT connect');
        });

        // при переподключении сообщаем в контроллер об этом, чтобы выполнить необходимые действия
        self.socket.on('reconnect', function(data) {
            console.log('EVENT reconnect');
            $rootScope.$broadcast('afterReconnect');
        });

        // изменение заказа
        self.socket.on('onChangeOrderFromServer',function(data) {
            console.log('EVENT onChangeOrderFromServer');
            $rootScope.changeOrderInGrid(data);
        });

        // изменение заявки на торгах
        self.socket.on('onChangeMarketplaceRequestFromServer',function(data) {
            console.log('EVENT onChangeMarketplaceRequestFromServer', data);

            $rootScope.changeCountsFromNode(data, $rootScope.getBoxesAmountDelta(data));
            $rootScope.changeMarketplaceRequestInGrid(data);
        });

        // изменение заявки
        self.socket.on('onChangeRequestFromServer',function(data) {
            console.log('EVENT onChangeRequestFromServer', data);
            $rootScope.changeRequestInGrid(data);
        });

        // изменение сообщения
        self.socket.on('onChangeMessageFromServer',function(data) {
            console.log('EVENT onChangeMessageFromServer');
            $rootScope.changeMessageInGrid(data);
        });

        // новое сообщение
        self.socket.on('onNewMessageFromServer',function(data) {
            console.log('EVENT onNewMessageFromServer', data);
            $rootScope.addMessageToGrid(data);
        });

        // изменение количества непрочитанных сообщений
        self.socket.on('onChangeUnreadMessagesFromServer', function(count) {
            console.log('EVENT onChangeUnreadMessagesFromServer', count);
            $rootScope.updateMessageCounts(count);
        });

        // изменение баланса
        self.socket.on('onChangeBalanceFromServer', function(data) {
            console.log('EVENT onChangeBalanceFromServer', JSON.stringify(data));

            if (data.length === 3) {
                // заместо CurrencyIds = 1, 2, 3 имеем 0, 1, 2.
                // передвинем на 1 вверх
                if (typeof data[0] !== 'undefined') {
                    var length = data.length;
                    for (var i = length - 1; i >= 0; i--) {
                        data[i+1] = data[i];
                    }
                    delete data[0];
                }

                $rootScope.updateBalances(data);
            }

        });

        // последние поступления
        self.socket.on('onLastOffersFromServer', function(data) {
            console.log('EVENT onLastOffersFromServer');
            $rootScope.addLastOffer(data);
        });

    };
    this.initCommet();

    // подписка на последние офферы от плантаций
    this.joinLastOffersRoom = function () {
        var platform_id = $.cookie('platform_id') || 1;
        self.socket.emit('trackLastOffers', platform_id);
    };

    // подписка на новые сообщения
    this.joinMessagesRoom = function () {
        self.socket.emit('trackNewMessages');
    };

    // подписка на изменение заказов
    this.trackOrders = function (ids) {
        self.emitIds('trackOrders', ids);
    };

    // отписка от изменения заказов
    this.untrackOrders = function (ids) {
        self.emitIds('untrackOrders', ids);
    };

    // подписка на изменение сообщений
    this.trackMessages = function (ids) {
        self.emitIds('trackMessages', ids);
    };

    // отписка от изменения сообщений
    this.untrackMessages = function (ids) {
        self.emitIds('untrackMessages', ids);
    };

    // подписка на изменение заявок
    this.trackRequests = function (ids) {
        self.emitIds('trackRequests', ids);
    };

    // отписка от изменения заявок
    this.untrackRequests = function (ids) {
        self.emitIds('untrackRequests', ids);
    };

    // подписка на изменение заявок, которые в данный момент на торгах
    this.trackMarketplaceRequests = function (ids) {
        self.emitIds('trackMarketplaceRequests', ids);
    };

    // отписка от изменения заявок, которые в данный момент на торгах
    this.untrackMarketplaceRequests = function (ids) {
        self.emitIds('untrackMarketplaceRequests', ids);
    };

    this.emitIds = function (event, ids) {
        if (ids.length > 0) {
            self.socket.emit(event, ids);
        }
    };
}]);
