'use strict';

import './controllers/MarketPlaceController.js';
import './services/BalancesService.js';
import './services/MessagesService.js';
import './services/FilterService.js';
import './services/RequestService.js';
import './services/OrderService.js';
import './services/SubbuyerService.js';
import './services/HandBookService.js';
import './services/OnlineService.js';
import './services/PasswordService.js';
import './services/PriceService.js';
import './services/ReclamationService.js';
import './services/PlantationInvoiceService.js';
import './services/ExpectedVolumeService.js';
import './services/CommetService.js';
import './services/CounterService.js';
import './services/UtilsService.js';
import './services/HelperLibrary.js';
import './services/changePriceInRequestService.js';
import './services/ImportsService.js';
import './models/CheckListModel.js';
import './controllers/TitleController.js';
import './controllers/FiltersController.js';
import './controllers/OrdersController.js';
import './controllers/SubbuyersController.js';
import './controllers/PriceController.js';
import './controllers/RequestsController.js';
import './controllers/RequestsMoverController.js';
import './controllers/NavController.js';
import './controllers/MessagesController.js';
import './controllers/AccountController.js';
import './controllers/ReclamationsController.js';
import './controllers/PlantationInvoicesController.js';
import './controllers/ExpectedVolumesController.js';
import './controllers/ColumnsController.js';
import './controllers/CargoController.js';
import './controllers/PopupController.js';
import './controllers/ManagerController.js';
import './controllers/CargoEBFController.js';
import './controllers/AnalyticsController.js';
import './controllers/ImportsController.js';

// Активный фильтр
var filter = 'test1';

var bifloricaApp = angular.module('bifloricaApp', [
    'ngResource',
    'ngCookies',
    'ui.bootstrap',     // Обёртка для Angular компонентов Bootstrap 3
    'ngScrollbar',
    'angularjs-dropdown-multiselect',
    'ngAnimate',
    //'angular-bootstrap-select',
    //'ui.date',
    'ui.select',
    'ui.grid',
    'ui.grid.importer',
    'ui.grid.edit',
    'ui.grid.rowEdit',
    'checklist-model',
    'bfServices.balances',
    'bfServices.messages',
    'bfServices.request',
    'bfServices.order',
    'bfServices.subbuyer',
    'bfServices.password',
    'bfServices.handbook',
    'bfServices.online',
    'bfServices.price',
    'bfServices.counter',
    'bfServices.reclamation',
    'bfServices.plantationInvoice',
    'bfServices.expectedVolume',
    'bfServices.commet',
    'bfServices.utils',
    'bfServices.changeprice',
    'bfServices.helpers',
    'bfServices.imports',
    'bfControllers.title',
    'bfControllers.marketplace',
    'bfControllers.orders',
    'bfControllers.subbuyers',
    'bfControllers.price',
    'bfControllers.requests',
    'bfControllers.mover',
    'bfControllers.filters',
    'bfControllers.nav',
    'bfControllers.messages',
    'bfControllers.account',
    'bfControllers.reclamations',
    'bfControllers.expectedVolumes',
    'bfControllers.plantationInvoices',
    'bfControllers.columns',
    'bfControllers.cargo',
    'bfControllers.popup',
    'bfControllers.manager',
    'bfControllers.cargoEBF',
    'bfControllers.analytics',
    'bfControllers.imports'
    //'widget.scrollbar',
]);

bifloricaApp.filter('propsFilter', function() {
    return function(items, props) {
        var out = [];

        if (angular.isArray(items)) {
            var keys = Object.keys(props);

            items.forEach(function(item) {
                var itemMatches = false;

                for (var i = 0; i < keys.length; i++) {
                    var prop = keys[i];
                    var text = props[prop].toLowerCase();
                    if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                        itemMatches = true;
                        break;
                    }
                }

                if (itemMatches) {
                    out.push(item);
                }
            });
        } else {
            // Let the output be the input untouched
            out = items;
        }

        return out;
    };
});

/**
 * Анимация при изменении значения
 */
bifloricaApp.directive('animateOnChange', function($animate,$timeout) {
    return function(scope, elem, attr) {
        scope.$watch(attr.animateOnChange, function(newValue, oldValue) {
            if (typeof oldValue !== 'undefined' && newValue != oldValue) {
                var c = 'value-changed';
                $animate.addClass(elem,c).then(function() {
                    $timeout(function() {$animate.removeClass(elem,c)});
                });
            }
        })
    }
})