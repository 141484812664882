angular.module('bfControllers.filters', [])
.controller('FiltersController', ['$scope', '$rootScope', '$http', '$cookies', '$resource', 'handbook', 'commet', 'utils',
    function ($scope, $rootScope, $http, $cookies, $resource, handbook, commet, utils) {

    $scope.handbook = handbook;
    $scope.filters = {};

    // Перечень пунктов в фильтре
    $rootScope.filter_content = {
        trusted_plantations: {},
        plantations: {},
        types: {},
        import_types: {},
        sorts: {},
        sizes: {},
        colors: {}
    };

    // Перечень счётчиков по значениям фильтра
    $rootScope.filter_counters = {
        farm: {},
        type: {},
        variety: {},
        size: {},
        color: {}
    };

    $rootScope.filter_content_show = 'market';
    $rootScope.marketMode_priceList = false;

    $scope.selectFilter = "";
    $scope.all_new_requests = {};
    $rootScope.filter_values_url = "";

    // Список последних предложений
    $scope.lastOffers = {};

    // Выбранная плантация на форме "Последние поступления"
    $scope.onform_all_plantation = "0";

    // Отображение строки поиска в фильтре
    $scope.input_fields = {
        description: false,
        gardener: false,
    };

    var self = this;

    // Выбранные значения фильтра
    var filterValueFromCookies = $cookies.getObject('filter-value');
    if (typeof filterValueFromCookies != 'undefined') {
        $rootScope.filter_value = filterValueFromCookies;

        if (typeof $rootScope.filter_value.show_select === 'undefined') {
            $rootScope.filter_value.show_select = 'all';
        }

    } else {
        $rootScope.filter_value = {
            plantations: [],
            types: [],
            sorts: [],
            sizes: [],
            colors: [],
            show_select: 'all',
            description: '',
            gardener: '',
        };
    }

    $rootScope.saveFiltersValueInCookies = function () {
        $cookies.putObject('filter-value', $rootScope.filter_value);
        //$rootScope.filter_values_url =
    };

    /**
     * Текущий режим отображения на маркете
     * @returns {string|string|string}
     */
    $rootScope.showMarketMode = function () {
        return $rootScope.filter_content_show;
    };

    /**
     * Устаревшая !!!! Для переключений в шаблонах нужно использовать переменную $rootScope.marketMode_priceList !!!
     * Маркет в режиме отображения прайслистов
     * @returns {boolean}
     */
    $rootScope.isMarketInPricelistMode = function () {
        return $rootScope.filter_content_show == 'pricelist';
    };

    // Last Offers
    $rootScope.renewLastOffers = function () {
        $resource(API_LINK + '/requests/:action',
            {action: 'lastOffers', 'start': '0', 'limit': 100},
            {get: {method: 'GET'}}
        ).get(function (result) {
            if (typeof result.data !== 'undefined' && typeof result.data.list !== 'undefined') {
                $scope.lastOffers = result.data.list;
            }
            //console.log(result.data.list);
        });
    };

    // загружаем последние предложения
    $rootScope.renewLastOffers();

    // подписываем на новые офферы
    commet.joinLastOffersRoom();

    /**
     * Действия, которые необходимо выполнить после восстановления соединения с сервером ноды
     */
    $rootScope.$on('afterReconnect', function(event, data) {
        $rootScope.renewLastOffers();
        commet.joinLastOffersRoom();
    });


    /**
     * Добавляем новый оффер в блок
     *
     * @param offer
     */
    $rootScope.addLastOffer = function (offer) {
        if (utils.pushInFront(offer, $scope.lastOffers, 'id')) {
            $scope.$apply();
        }
    };

    /*
     http://ec.term.biflorica:8080/api/filters/
     */
    $scope.getFilters = function(){
        $http({
            method: 'GET',
            url: API_LINK + '/filters/'
        }).then(function(data) {
            $scope.filters = data.data.data.list;
            //console.log($scope.filters);
        });
    };
    $scope.getFilters();

    $scope.showInputField = function(field){
        $scope.input_fields[field] = true;
    };
    $scope.hideInputField = function(field){
        $scope.input_fields[field] = false;
    };

    // Filters Functions
    /**
     * Сохранение фильтра
     */
    $rootScope.saveFilter = function(){
        // http://ec.term.biflorica:8080/api
        url = API_LINK + '/filters/create/';

        var sent_params = $rootScope.getParamsFromFilterValue($scope.filter_value);

        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            if(result.errors.length == 0) {
                //location.reload();
                $scope.filter_value.name = '';
                $scope.getFilters();
            } else {
                $rootScope.requestFormErrors = result.errors[0];
                $rootScope.requestFormFieldsErrors = result.fieldsErrors;
            };
            console.log(result);
        }, function(err){
            console.log(err);
        });
    };

    /**
     * Правильно формирует параметры для создания фильтра
     * @param filterValue
     * @returns {{}|*}
     */
    $rootScope.getParamsFromFilterValue = function(filterValue){
        var sent_params = {};
        sent_params.name = filterValue.name;
        sent_params['farm[]'] = filterValue.plantations;
        sent_params['type[]'] = filterValue.types;
        sent_params['variety[]'] = filterValue.sorts;
        sent_params['size[]'] = filterValue.sizes;
        sent_params['color[]'] = filterValue.colors;
        sent_params['description'] = filterValue.description;
        sent_params['gardener'] = filterValue.gardener;
        return sent_params;
    };

    /**
     * Правильно устанавливает параметры для отображения фильтра
     * @param filterValue
     * @returns {{}|*}
     */
    $rootScope.setParamsToFilterValue = function(inData){
        var filter_value = {};
        filter_value.name = inData.name;
        filter_value.plantations = inData.farms;
        filter_value.types = inData.types;
        filter_value.sorts = inData.varieties;
        filter_value.sizes = inData.sizes;
        filter_value.colors = inData.colors;
        filter_value.description = '';
        filter_value.gardener = '';
        return filter_value;
    };

    /**
     * Выбор фильтра в селекторе фильтров
     */
    $rootScope.changeSelectFilter = function(select_filter){
        if(typeof select_filter == 'undefined'){
            $rootScope.filter_value = {};
            $scope.selectFilter = "";
            $rootScope.setActiveTab('all');
        }else{
            $rootScope.filter_value = $rootScope.setParamsToFilterValue( $scope.filters[select_filter] );
            $scope.selectFilter = select_filter;
        }
        //$rootScope.renewRequests();
        $rootScope.timerCountSec = 0;
        $scope.initAllSelector();
    };

    $rootScope.filterValuesUrl = function () {
        return $.param({filter_values: $scope.filter_value});
    };

    // Display All lastOffers Dialog
    $scope.showAllLastOffers = function(){
        console.log('Function showAllLastOffers get!');
    };
    // Display OnlyMarket lastOffers Dialog
    $scope.showOnlyMarketLastOffers = function(){
        console.log('Function showOnlyMarketLastOffers get!');
    };

    /**
     * При выборе любого селектора сбрасываем селектор "Все"
     * При сбросе последнего селектора устанавливаем селектор "Все"
     * @param idAllSelector
     */
    $scope.resetAllSelector = function (idAllSelector) {
        if(typeof $rootScope.filter_value != 'undefined'){
            var new_str = idAllSelector.replace("filter-all-", "");
            if(typeof $rootScope.filter_value[new_str] == 'undefined' || Object.keys($rootScope.filter_value[new_str]).length === 0 ){
                $rootScope.filter_value['filter_all_'+new_str] = true;
            }else{
                $rootScope.filter_value['filter_all_'+new_str] = false;
            }
        }
    };
    /**
     * Первоначальная инициализация селекторов "Все"
     */
    $scope.initAllSelector = function () {
        $('.filter-all').each(function(){
            $scope.resetAllSelector($(this).attr('id'));
            console.log($(this).attr('id'));
        });
    };
    $scope.initAllSelector();

    /**
     * При клике на селектор "Все" сбрасываем все остальные селекторы и запрещаем сброс выбора селектора "Все"
     * @param filterGroupID
     */
    $scope.showAllSelector = function (filterGroupID) {
        $rootScope.filter_value[filterGroupID] = [];
        $rootScope.renewRequests();
        $scope.initAllSelector();
        console.log(filterGroupID);
        /*if(!$('#filter-all-' + filterGroupID).prop('checked')){
            $('#filter-all-' + filterGroupID).prop('checked', true);
        }*/
        if(!$rootScope.filter_value['filter_all_'+filterGroupID]){
            $rootScope.filter_value['filter_all_'+filterGroupID] = true;
        }
    };

    /**
     * Изменяет в фильтре количество корбок
     *
     * @param data
     * @param boxesDelta - дельта изменения количества коробок
     */
    $rootScope.changeCountsFromNode = function (data, boxesDelta) {
        if (boxesDelta === 0) {
            return;
        }

        // если чего-то нет - добавим
        if (typeof $rootScope.filter_counters.farm[data.plantation_id] === 'undefined') {
            $rootScope.filter_counters.farm[data.plantation_id] = {'bc': 0, 'sc': 0};
        }

        if (typeof $rootScope.filter_counters.type[data.type_id] === 'undefined') {
            $rootScope.filter_counters.type[data.type_id] = {'bc': 0, 'sc': 0};
        }

        if (typeof $rootScope.filter_counters.color[data.color_id] === 'undefined') {
            $rootScope.filter_counters.color[data.color_id] = {'bc': 0, 'sc': 0};
        }

        if (typeof $rootScope.filter_counters.variety[data.sort_id] === 'undefined') {
            $rootScope.filter_counters.variety[data.sort_id] = {'bc': 0, 'sc': 0};
        }

        var counter = data.order_type === 'buyer' ? 'bc' : 'sc';

        // Находим плантацию в фильтре и изменяем счётчик
        for(var i in $rootScope.filter_content.plantations) {
            if (!$rootScope.filter_content.plantations.hasOwnProperty(i)) {
                continue;
            }

            var plantation = $rootScope.filter_content.plantations[i];
            if(plantation.id == data.plantation_id) {
                console.log('CHANGE ' + counter + ' from ' + $rootScope.filter_counters.farm[plantation.id][counter] );

                $rootScope.filter_counters.farm[plantation.id][counter] = self.changeCountsValue($rootScope.filter_counters.farm[plantation.id][counter], boxesDelta);

                console.log('To ' + $rootScope.filter_counters.farm[plantation.id][counter]);

                break;
            }
        }

        // Находим тип в фильтре и изменяем счётчик
        for(var i in $rootScope.filter_content.types) {
            if (!$rootScope.filter_content.types.hasOwnProperty(i)) {
                continue;
            }

            var type = $rootScope.filter_content.types[i];
            if(type.id == data.type_id) {
                $rootScope.filter_counters.type[type.id][counter] = self.changeCountsValue($rootScope.filter_counters.type[type.id][counter], boxesDelta);
                break;
            }
        }

        // Находим сорт в фильтре и изменяем счётчик
        for(var i in $rootScope.filter_content.sorts){
            if (!$rootScope.filter_content.sorts.hasOwnProperty(i)) {
                continue;
            }

            var sort = $rootScope.filter_content.sorts[i];
            if(sort.id == data.sort_id){
                $rootScope.filter_counters.variety[sort.id][counter] = self.changeCountsValue($rootScope.filter_counters.variety[sort.id][counter], boxesDelta);
                break;
            }
        }

        // Находим цвет в фильтре и изменяем счётчик
        for(var i in $rootScope.filter_content.colors){
            if (!$rootScope.filter_content.colors.hasOwnProperty(i)) {
                continue;
            }
        
            var color = $rootScope.filter_content.colors[i];
            if(color.id == data.color_id){
                $rootScope.filter_counters.variety[color.id][counter] = self.changeCountsValue($rootScope.filter_counters.variety[color.id][counter], boxesDelta);
                break;
            }
        }

        // Находим размер в фильтре и изменяем счётчик
        for(var i in $rootScope.filter_content.sizes){
            if (!$rootScope.filter_content.sizes.hasOwnProperty(i)) {
                continue;
            }

            var size = $rootScope.filter_content.sizes[i];
            if (typeof data['price' + size.name] !== 'undefined') {

                // если размера нет -- добавим
                if (typeof $rootScope.filter_counters.size[size.id] === 'undefined') {
                    $rootScope.filter_counters.size[size.id] = {'bc': 0, 'sc': 0};
                }

                $rootScope.filter_counters.size[size.id][counter] = self.changeCountsValue($rootScope.filter_counters.size[size.id][counter], boxesDelta);
            }
        }
        $scope.$apply();
    };

    /**
     * Возвращает сумму текущего значения и дельты
     *
     * @param operand - старое значение
     * @param value - дельта
     * @returns {number}
     */
    this.changeCountsValue = function (operand, value) {
        value = parseFloat(operand) + parseFloat(value);

        if (value < 0) {
            value = 0;
        }

        return value;
    };

    /**
     * Показать форму Новые поступления
     */
    $scope.showAllNew = function (farm) {

        // http://ec.term.biflorica:8080/api
        url = API_LINK + '/requests/lastOffers/';

        var sent_params = {};
        if(typeof farm == 'undefined' || farm == 0){
            sent_params = {showdate: true, start: 0};
        }else{
            sent_params = {showdate: true, start: 0, farm_id: farm};
        }

        $resource( url,
            sent_params,
            {get: {method:'GET'}}
        ).get(function( result ){
            if( result.errors.length == 0) {
                $scope.all_new_requests = result.data.list;
                // Показываем форму
                $('.bf-all-new-dialog').modal('show');
            } else {
                $rootScope.requestFormErrors = result.errors[0];
                $rootScope.requestFormFieldsErrors = result.fieldsErrors;
            }
            console.log(result);
        });
    };

    /**
     * Удалить фильтр
     */
    $scope.deleteFilter = function (filter) {
        // http://ec.term.biflorica:8080/api/filters/delete/
        url = API_LINK + '/filters/delete/';

        var sent_params = {id: filter};
        console.log($.param(sent_params));
        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            if( result.errors.length == 0) {
                $scope.getFilters();
                //location.reload();
            } else {
                self.orderFormErrors = result.errors[0];
                self.orderFormFieldsErrors = result.fieldsErrors;//JSON.stringify(result.fieldsErrors);
            }
            console.log(result);
        }, function(err){
            console.log(err);
        });

    };

    /**
     * Переключатель "Сейчас на торгах - Price List - ВСЕ"
     */
    this.showOnlyMarketLastOffers = function(){
        console.log('Market Mode On');
        $rootScope.filter_content_show = 'market';
        $rootScope.marketMode_priceList = false;
        $rootScope.timerCountSec = 0;
    };
    this.showPriceListOffers = function(){
        console.log('Price List Mode On');
        $rootScope.filter_content_show = 'pricelist';
        $rootScope.marketMode_priceList = true;
        $rootScope.timerCountSec = 0;
    };
    this.showAllLastOffers = function(){
        console.log('Market Mode All');
        $rootScope.filter_content_show = 'all';
        $rootScope.marketMode_priceList = false;
        $rootScope.timerCountSec = 0;
    };
    $rootScope.setMarketMode = function (mode) {
        $rootScope.filter_content_show = mode;
        if($rootScope.filter_content_show == 'pricelist'){
            $rootScope.marketMode_priceList = true;
        }else{
            $rootScope.marketMode_priceList = false;
        }
        $rootScope.timerCountSec = 0;
    };

    /**
     *  Установить фильтр по плантации
     */
    $rootScope.selectPlantation = function(farm_id){
        $rootScope.filter_value.filter_all_plantations = false;
        $rootScope.filter_value.plantations = [farm_id];
        $rootScope.timerCountSec = 0;
    };



    /**
     * Убирает хтмл из строки
     * @param str
     * @returns {*}
     */
    this.strip = function(str) {
        return str.replace(/<.*?>/g, '');
    };

    $scope.getFarmName = function (id) {
        var name = '';
        for(var i in $rootScope.filter_content.plantations){
            if($rootScope.filter_content.plantations[i].id == id){
                name = $rootScope.filter_content.plantations[i].name;
            }
        }
        return name;
    };

    $scope.getTypeName = function (id) {
        var name = '';
        for(var i in $rootScope.filter_content.types){
            if($rootScope.filter_content.types[i].id == id){
                name = $rootScope.filter_content.types[i].name;
            }
        }
        return name;
    };

        $scope.getImportTypeName = function (id) {
            var name = '';
            for(var i in $rootScope.filter_content.import_types){
                if($rootScope.filter_content.import_types[i].id == id){
                    name = $rootScope.filter_content.import_types[i].name;
                }
            }
            return name;
        };

    $scope.getSortName = function (id) {
        var name = '';
        for(var i in $rootScope.filter_content.sorts){
            if($rootScope.filter_content.sorts[i].id == id){
                name = $rootScope.filter_content.sorts[i].name;
            }
        }
        return name;
    };

    $scope.getSizesName = function (id) {
        var name = '';
        for(var i in $rootScope.filter_content.sizes){
            if($rootScope.filter_content.sizes[i].id == id){
                name = $rootScope.filter_content.sizes[i].name;
            }
        }
        return name;
    };

    $scope.getColorName = function (id) {
        var name = '';
        for(var i in $rootScope.filter_content.colors){
            if($rootScope.filter_content.colors[i].id == id){
                name = $rootScope.filter_content.colors[i].name;
            }
        }
        return name;
    };

    $rootScope.$on('filterChanged', function(event, data) {
        if (data === 'availability') {
            $scope.initAllSelector();
        } else {
            $rootScope.changeSelectFilter();
        }
    });

    $rootScope.$watch('filter_value', function(newValue, oldValue) {
        if (typeof plantation_id === 'undefined'
            || $rootScope.current_user_role !== $rootScope.role_seller
            || typeof newValue.show_select === 'undefined'
            || (
                newValue.show_select !== 'plantation_availability'
                && newValue.show_select !== 'plantation_buyers_requests'
                /* && newValue.show_select !== 'plantation_autotransactions' */
            )
        ) {
            return;
        }

        var plantations = [plantation_id];

        if (newValue.filter_all_plantations !== false
            || !angular.equals(plantations, newValue.plantations)) {
            $rootScope.setActiveTab('all');
        }

    }, true);

} ])

.filter('unsafe', function($sce) {
    return function(val) {
        return $sce.trustAsHtml(val);
    };
})

.filter('switch', function () {
    return function (input, map) {
        return map[input] || '';
    };
});