angular.module('bfControllers.messages', [])
.controller('MessagesController', ['$scope', '$rootScope', '$resource', '$cookies', '$http', 'messages', 'commet', 'utils', 'helpers', function($scope, $rootScope, $resource, $cookies, $http, messages, commet, utils, helpers){

    this.messages = messages;
    $rootScope.timerCountSec = TIMER_RENEW_PERIOD;
    this.listMessages = {};

    $scope.pagination = new helpers.pagination(ITEMS_SHOW_PER_PAGE,41);

    $scope.senders = { };
    $scope.selected_sender = 0;
    $scope.selected_sender_type = '';

    $scope.selected_messages = {
        ids: []
    };

    this.path_alias = '/marketPlaceNew/default/messages';

    $scope.message_details = { };

    $scope.daterange = new helpers.daterange();

    $rootScope.current_user_role = CURRENT_USER_ROLE;
    $rootScope.role_buyer = ROLE_BUYER;
    $rootScope.role_seller = ROLE_SELLER;

    var self = this;

    /**
     * Возвращает IDs сообщений на текущей странице грида
     */
    this.getGridMessageIds = function() {
        return utils.getGridEntityIds(self.listMessages);
    };

    /**
     * Get message
     * http://ec.term.biflorica:8080/api/messages/message/id/60
     */
    this.getMessage = function(id){
        $resource(API_LINK + '/messages/message/id/' + id,
            { },
            {get: {method:'GET'}}
        ).get(function(result) {
            $scope.message_details = result.data.message;
            // Отмечаем сообщение прочитанным (на фронте)
            for(msg in self.listMessages){
                if(self.listMessages[msg].guid == id){
                    self.listMessages[msg].isRead = "yes";
                }
            }
            $('#messageDialog').modal('show');
        });
    };
    $rootScope.getMessage = this.getMessage;

    /**
     * Checks whether if message is deleted
     */
    this.isMessageDeleted = function(message){
        return message.isDeleted === 'Y';
    };
    $rootScope.isMessageDeleted = this.isMessageDeleted;

    /**
     * Get list of senders
     * http://ec.term.biflorica:8080/api/messages/getSenders/
     */
    this.getSenders = function(){
        $resource(API_LINK + '/messages/getSenders',
            { },
            {get: {method:'GET'}}
        ).get(function(result) {
            $scope.senders = result.data.senders;
            $scope.selected_sender = $scope.senders[0].id;
            $scope.selected_sender_name = $scope.senders[0].name;
        });
    };
    $rootScope.getSenders = this.getSenders;

    /**
     * Select sender
     */
    this.selectSender = function(num_sender){
        $scope.selected_sender = $scope.senders[num_sender].id;
        $scope.selected_sender_name = $scope.senders[num_sender].name;
        $scope.selected_sender_type = $scope.senders[num_sender].type;
        self.getMessages();
    };
    $rootScope.selectSender = this.selectSender;

    /**
     * Get class for selected sender
     */
    this.classForSelectedSender = function(id){
        if ($scope.selected_sender == id)
            return 'active';

        return 'no';
    };
    $rootScope.classForSelectedSender = this.classForSelectedSender;

    /**
     * Get list Messages
     * http://ec.term.biflorica:8080/api/messages/?direct=desc&start=0&sort=date&show%5B%5D=all&direct=desc&limit=127
     */
    this.getMessages = function() {
        commet.untrackMessages(self.getGridMessageIds());

        $rootScope.querySent = true;
        $resource(API_LINK + '/:action',
            {
                action:'messages',
                'show[]': 'all',
                sort: $rootScope.sort,
                direct: $rootScope.direct,
                startDate: $scope.daterange.startDate,
                endDate: $scope.daterange.endDate,
                sender_id: $scope.selected_sender,
                sender_type: $scope.selected_sender_type,
                limit: $scope.pagination.itemsPerPage,
                page: $scope.pagination.currPage
            },
            {get: {method:'GET'}}
        ).get(function(result) {
            self.listMessages = result.data.list;
            var count = parseInt(result.data.count_items);
            var total = parseInt(result.data.total_items);
            $scope.pagination.recountPages(count, total);
            $rootScope.querySent = false;
            $rootScope.timerCountSec = TIMER_RENEW_PERIOD;
            commet.trackMessages(self.getGridMessageIds());
        });
    };
    $rootScope.renewMessages = $scope.pagination.renewPage = $scope.daterange.renewPage = this.getMessages;

    /**
     * Изменение состояния сообщения в гриде
     *
     * @param data
     */
    $rootScope.changeMessageInGrid = function (data) {
        if (utils.changeEntityInGrid(data, self.listMessages)) {
            $scope.$apply();
        }
    };

    /**
     * Добавление нового сообщение в грид
     *
     * @param data
     * @returns {boolean}
     */
    $rootScope.addMessageToGrid = function (data) {
        // проверяем, что пользователь, от которого пришло сообщение подходит под фильтр
        if ($scope.selected_sender > 0
            && $scope.selected_sender_type === 'submark'
            && parseInt(data.submarkId) !== parseInt($scope.selected_sender)) {
            return false;
        }

        if ($scope.selected_sender > 0
            && parseInt(data.fromUid) !== parseInt($scope.selected_sender)) {
            return false;
        }

        if (utils.pushInFront(data, self.listMessages, 'id')) {
            commet.trackMessages([data['id']]);
            $scope.$apply();
        }
    };

    // подписываем на новые сообщения
    commet.joinMessagesRoom();

    /**
     * Действия, которые необходимо выполнить после восстановления соединения с сервером ноды
     */
    $rootScope.$on('afterReconnect', function(event, data) {
        $rootScope.renewMessages();
        commet.joinMessagesRoom();
    });

    $rootScope.renewMessageFromPage = function (new_page) {
        $scope.cur_page = new_page;
        self.getMessages();
    };

    this.renewTimer = function(){
        $rootScope.timerCountSec--;
        if($rootScope.timerCountSec <= 0){
            $rootScope.timerCountSec = TIMER_RENEW_PERIOD;
            self.getMessages();
            self.getSenders();
        }
        //$('#timer_value').text(self.timerCountSec);
        setTimeout(self.renewTimer, 1000);
    };
    this.renewTimer();

    // Выбранные значения сортировки
    var sortValueFromCookies = $cookies.get('sort');
    if(typeof sortValueFromCookies != 'undefined'){
        $rootScope.sort = sortValueFromCookies;
    }else{
        $rootScope.sort = 'date';
        $cookies.put('sort', 'date', {path: self.path_alias});
    }
    var directValueFromCookies = $cookies.get('direct');
    if(typeof directValueFromCookies != 'undefined'){
        $rootScope.direct = directValueFromCookies;
    }else{
        $rootScope.direct = 'desc';
        $cookies.put('direct', 'desc', {path: self.path_alias});
        $rootScope.timerCountSec = 0;
    }
    this.setSort = function(sort_column){
        if($rootScope.sort == sort_column){
            // Изменяем направление сортировки
            if($rootScope.direct == 'asc'){
                $rootScope.direct = 'desc';
                $cookies.put('direct', 'desc', {path: self.path_alias});
            }else{
                $rootScope.direct = 'asc';
                $cookies.put('direct', 'asc', {path: self.path_alias});
            }
        }else{
            // Изменяем сортировку
            $cookies.put('sort', sort_column, {path: self.path_alias});
            $rootScope.sort = sort_column;
        }

        self.getMessages();
        $rootScope.timerCountSec = 0;
    };
    $rootScope.setSort = this.setSort;

    $scope.classSort = function(sort_column){
        if($rootScope.sort == sort_column){
            // Изменяем направление сортировки
            if($rootScope.direct == 'asc'){
                return 'sorted sorted-up';
            }else{
                return 'sorted sorted-down';
            }
        }
    };


    /**
     * Отметить все чекбоксы в списке
     */
    $scope.checkAll = function () {
        $scope.selected_messages.ids = [];
        if($('#list-messages-all').is(':checked')){
            for (var message in self.listMessages) {
                $scope.selected_messages.ids[message] = self.listMessages[message].guid;
            }
            $('.message-checkbox').attr('checked', true);
        }
    };

    /**
     * Обновить статус кнопок груповых операций
     */
    $scope.renewButtonsStatus = function () {
        if($scope.selected_messages.ids.length == 0){
            // Disable buttons
            $('.group-messages-btn').attr('disabled', 'disabled');
        }else{
            // Enable buttons
            $('.group-messages-btn').attr('disabled', 'disabled');

            // Включаем кнопки которые предназначены для любых статусов
            $('.group-messages-btn-any').removeAttr('disabled');

            // Перебор сообщений и включение кнопок согласно статусов
            for(var req in $scope.selected_messages.ids){
                var message;
                for(var msg in self.listMessages){
                    if(self.listMessages[msg].guid == $scope.selected_messages.ids[req]){
                        message = self.listMessages[msg];
                        break;
                    }
                }

                if(message.isRead == "yes")
                    $('.group-messages-btn-read').removeAttr('disabled');

                if(message.isRead == "no")
                    $('.group-messages-btn-unread').removeAttr('disabled');
            };
        }

    };

    /**
     * Mark messages as unread/read
     */
    $rootScope.markMessages = function (flag) {
        $rootScope.querySent = true;

        url = API_LINK + '/messages/markRead/';

        sent_params = {};
        sent_params['id'] = $scope.selected_messages.ids;
        sent_params['readFlag'] = flag;
        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            if( result.errors.length == 0) {
                //location.reload();

            } else {
                //$rootScope.requestFormErrors = result.errors[0];
                //$rootScope.requestFormFieldsErrors = result.fieldsErrors;
            }
            $rootScope.timerCountSec = 0;
            self.uncheckAll();
            $('.group-messages-btn').attr('disabled', 'disabled');
            console.log(result);
        }, function(err){
            console.log(err);
        });
    };

    /**
     * Delete messages
     */
    $rootScope.deleteMessages = function () {
        $rootScope.querySent = true;

        url = API_LINK + '/messages/delete/';

        sent_params = {};
        sent_params['id'] = $scope.selected_messages.ids;
        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            if( result.errors.length == 0) {
                //location.reload();
            } else {
                //$rootScope.requestFormErrors = result.errors[0];
                //$rootScope.requestFormFieldsErrors = result.fieldsErrors;
            }
            $rootScope.timerCountSec = 0;
            self.uncheckAll();
            $('.group-messages-btn').attr('disabled', 'disabled');
            console.log(result);
        }, function(err){
            console.log(err);
        });
    };

    /**
     * Снять Отметки всех чекбоксов в списке
     */
    this.uncheckAll = function () {
        $scope.selected_messages.ids = [];
        $('#list-messages-all').attr('checked',false);
    };
    $scope.uncheckAll = this.uncheckAll;

    // Действия после загрузки страницы
    this.getSenders();
    this.getMessages();

} ])

.filter('unsafe', function($sce) {
    return function(val) {
        return $sce.trustAsHtml(val);
    };
});