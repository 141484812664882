angular.module('bfControllers.plantationInvoices', [])
.controller('PlantationInvoicesController', ['$scope', '$rootScope', '$resource', '$cookies', '$http', 'plantationInvoice', 'helpers', function($scope, $rootScope, $resource, $cookies, $http,  plantationInvoice, helpers){

    $scope.plantationInvoice = plantationInvoice;
    $scope.selected_invoices = {
        ids: []
    };

    $scope.pagination = new helpers.pagination(ITEMS_SHOW_PER_PAGE, 41);

    $rootScope.counts = {};

    $rootScope.current_user_role = CURRENT_USER_ROLE;
    $rootScope.role_buyer = ROLE_BUYER;
    $rootScope.role_seller = ROLE_SELLER;

    $scope.daterange = new helpers.daterange();

    var self = this;

    $rootScope.create = function () {
        $scope.plantationInvoice.set('');
    };

    /**
     * Delete invoice
     */
    $rootScope.delete = function (id) {
        $rootScope.querySent = true;
        url = API_LINK + '/plantationInvoices/delete/';

        sent_params = {};
        sent_params['ids[]'] = [id];

        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            if (result.status != 200) {
                alert('Something went wrong. Please try again later.');
            }

            $rootScope.timerCountSec = 0;
            self.uncheckAll();
        }, function(err){
            console.log(err);
        });
    };

    $rootScope.sentParams = function(excel = false){
        let sent_params = {};
        sent_params['sort'] = $rootScope.sort;
        sent_params['limit'] = $scope.pagination.itemsPerPage;
        sent_params['startDate'] = $scope.daterange.startDate;
        sent_params['endDate'] = $scope.daterange.endDate;
        sent_params['start'] = 0;
        sent_params['direct'] = $rootScope.direct;
        sent_params['page'] = $scope.pagination.currPage;
        if (excel) {
            sent_params['excel'] = 1;
        }
        return sent_params
    };
    /**
     * Get list plantation invoices
     */
    $rootScope.renewItems = $scope.pagination.renewPage = function() {
        $rootScope.querySent = true;
        // http://ec.term.biflorica:8080/api/plantationInvoices
        self.saveUpFilterToCookies();

        $http({
            method: 'GET',
            url: API_LINK + '/plantationInvoices/index',
            params: $rootScope.sentParams(),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            var count = parseInt(result.data.count_items);
            var total = parseInt(result.data.total_items);
            $scope.pagination.recountPages(count, total);
            $scope.items = result.data.list;
            $rootScope.counts = result.data.counts;
            $rootScope.querySent = false;
        }, function(err){
            console.log(err);
        });
    };

    /**
     * Download plantation invoices list
     */
    $rootScope.downloadItems = function() {
        const searchParams = new URLSearchParams($rootScope.sentParams(true));
        document.location.href= API_LINK + '/plantationInvoices/index?=' + searchParams.toString();
    }

    $scope.renewTimer = function() {
        if (!$rootScope.pause) {
            $rootScope.timerCountSec--;
        }

        if ($rootScope.timerCountSec <= 0) {
            $rootScope.timerCountSec = TIMER_RENEW_PERIOD;
            $scope.renewItems();
        }

        $('#timer_value').text($rootScope.timerCountSec);
        setTimeout($scope.renewTimer, 1000);
    };
    $scope.renewTimer();


    /**
     * Отметить все чекбоксы в списке
     */
    $scope.checkAll = function () {
        $scope.selected_invoices.ids = [];

        if ($('#list-dates-all').is(':checked')) {
            for (var request in $scope.items) {
                $scope.selected_invoices.ids[request] = $scope.items[request].guid;
            }
            $('.request-checkbox').attr('checked', true);
        }
    };

    /**
     * Снять Отметки всех чекбоксов в списке
     */
    this.uncheckAll = function () {
        $scope.selected_invoices.ids = [];
        $('#list-dates-all').attr('checked',false);
        //console.log($scope.selected_orders);
    };
    $scope.uncheckAll = this.uncheckAll;

    $scope.getIds = function () {
        var ret_arr = [];
        for (var req in $scope.selected_invoices.ids) {
            ret_arr.push($scope.selected_invoices.ids[req].guid);
        }
        return ret_arr;
    };

    // Выбранные значения сортировки
    var sortValueFromCookies = $cookies.get('invoices-sort');
    if (typeof sortValueFromCookies != 'undefined') {
        $rootScope.sort = sortValueFromCookies;
    } else {
        $rootScope.sort = 'id';
        $cookies.put('invoices-sort', 'id');
    }
    var directValueFromCookies = $cookies.get('invoices-direct');
    if (typeof directValueFromCookies != 'undefined') {
        $rootScope.direct = directValueFromCookies;
    } else {
        $rootScope.direct = 'desc';
        $cookies.put('invoices-direct', 'desc');
        $rootScope.timerCountSec = 0;
    }
    this.setSort = function(sort_column) {
        if ($rootScope.sort == sort_column) {
            // Изменяем направление сортировки
            if ($rootScope.direct == 'asc') {
                $rootScope.direct = 'desc';
                $cookies.put('invoices-direct', 'desc');
            } else {
                $rootScope.direct = 'asc';
                $cookies.put('invoices-direct', 'asc');
            }
        } else {
            // Изменяем сортировку
            $cookies.put('invoices-sort', sort_column);
            $rootScope.sort = sort_column;
        }
        $rootScope.timerCountSec = 0;
    };
    $rootScope.setSort = this.setSort;

    $scope.classSort = function(sort_column) {
        if ($rootScope.sort == sort_column) {
            // Изменяем направление сортировки
            if ($rootScope.direct == 'asc') {
                return 'sorted sorted-up';
            } else {
                return 'sorted sorted-down';
            }
        }
    };

    $rootScope.setShowFrom = function(plantation_id) {
        $rootScope.renewItems();
    };

    // Выбранные значения фильтра вверху
    var upFilterValueFromCookies = $cookies.get('upfilter_plantation_invoices');
    if (typeof upFilterValueFromCookies != 'undefined') {
        $rootScope.show_value = upFilterValueFromCookies;
    } else {
        $rootScope.show_value = [];
        $cookies.put('upfilter_plantation_invoices', $rootScope.show_value);
    }

    this.saveUpFilterToCookies = function() {
        $cookies.put('upfilter_plantation_invoices', $rootScope.show_value);
    };

    // Запуск действий при загрузке страницы
    $rootScope.renewItems();
} ]);