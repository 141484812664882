angular.module('bfControllers.title', [])
.controller('titleController', ['$scope', '$cookies', '$rootScope', 'balances', function($scope, $cookies, $rootScope, balances){

    $scope.balances = balances;

    this.getPurpose = function(){
        if (typeof $cookies.get('order_purpose') === 'undefined') {
            return 0;
        }

        var purpose = parseInt($cookies.get('order_purpose'));
        return purpose >= 0 && purpose <= 2 ? purpose : 0;
    };

    $rootScope.order_purpose = this.getPurpose();

    this.setOrderPurpose = function(purpose) {
        $rootScope.order_purpose = purpose;
        $cookies.put('order_purpose', purpose, {path: '/'});
    };
    $rootScope.setOrderPurpose = this.setOrderPurpose;

    /**
     * Обновление баланса
     *
     * @param data
     */
    $rootScope.updateBalances = function(data) {
        $scope.balances.updateData(data);
        $scope.$apply();
    };

    /**
     * Проверка наличия лимита для пользователя
     * @returns {boolean} true - Лимит есть
     */
    $scope.checkLimit = function () {
        if(typeof $scope.balances.promised_balance == 'undefined'){
            return false;
        }else{
            return true;
        }
    };

} ])

.filter('unsafe', function($sce) {
    return function(val) {
        return $sce.trustAsHtml(val);
    };
});