angular.module('bfControllers.analytics', [])
.controller( 'AnalyticsController',
    ['$scope', '$rootScope', '$http', '$resource', '$cookies', 'order', '$sce', 'commet', 'utils', 'handbook', 'helpers', '$window',
    function ($scope, $rootScope, $http, $resource, $cookies, order, $sce, commet, utils, handbook, helpers, $window) {

        $scope.handbook = handbook;

        // Period = yesterday, week, month
        $rootScope.analyticsPeriod = '';

        $scope.analyticsData = {
            1: {},
            2: {},
            3: {}
        };

        $scope.colValue = {
            1: 'farm',
            2: 'varieties',
            3: '',
        };
        $scope.colSort = {
            1: {
                sort: 'farm',
                direct: 'asc',
            },
            2: {
                sort: 'varieties',
                direct: 'asc',
            },
            3: {
                sort: '',
                direct: '',
            },
        };
        $scope.colSelectedValue = {
            1: 0,
            2: 0,
            3: 0,
        };
        $scope.colSelectedValueItem = {
            1: {},
            2: {},
            3: {},
        };
        this.colValueAssoc = {
            farm: 'farms',
            variety: 'varieties',
            size: 'sizes'
        };

        $rootScope.trustAsHtml = $sce.trustAsHtml;

        var self = this;

        /**
         * Инит для аналитики
         *
         */
        //
        this.init = function() {
            $rootScope.analyticsPeriod = 'week';
            $scope.colValue[1] = 'farm';
            self.getAnalytics(1, {});
        };


        // Переключатель для смены периода в аналитике
        this.changePeriod = function(period) {
            $rootScope.analyticsPeriod = period;
            self.renewAnalytics();
        };
        $rootScope.changePeriod = this.changePeriod;

        /**
         * https://term.biflorica.com/api/analytics/farms?start=0&period=yesterday&farm=&variety=&size=&contentType=json
         */
        this.getAnalytics = function (col, additionaly_parameters) {
            var farm, variety, size = '';

            if(typeof additionaly_parameters['farm'] != 'undefined'){
                farm = additionaly_parameters['farm'];
            }
            if(typeof additionaly_parameters['variety'] != 'undefined'){
                variety = additionaly_parameters['variety']
            }
            if(typeof additionaly_parameters['size'] != 'undefined'){
                size = additionaly_parameters['size']
            }

            $resource(API_LINK + '/analytics/:action',
                {
                    action: self.colValueAssoc[$scope.colValue[col]],
                    start: 0,
                    'farm': farm,
                    'variety': variety,
                    'size': size,
                    sort: $scope.colSort[col].sort,
                    direct: $scope.colSort[col].direct,
                    period: $rootScope.analyticsPeriod,
                    contentType: 'json',
                },
                {get: {method:'GET'}}
            ).get(function(result){
                $scope.analyticsData[col] = result.data.list;
            });
        };
        $scope.getAnalytics = this.getAnalytics;

        /**
         * Change Select in First column
         */
        this.changeSelectFirst = function() {

            this.setSort(1, $scope.colValue[1])

            // Загрузить данные для первой колонки
            self.getAnalytics(1, {});

            // Настроить селектор второй колонки и сброс третьей
            $scope.colValue[2] = '';
            $scope.colValue[3] = '';

            // Сбросить выбор для значения в первой колонке
            $scope.colSelectedValue[1] = 0;

            // Настроить подписи второй колонки
        };

        /**
         * Change Select in Second column
         */
        this.changeSelectSecond = function() {
            this.setSort(2, $scope.colValue[2])

            var param_for_get = {};
            param_for_get[$scope.colValue[1]] = $scope.colSelectedValue[1];

            self.getAnalytics(2, param_for_get);

            // Определяем отображаемое в третьей колонке
            if(
                ($scope.colValue[1] == 'farm' && $scope.colValue[2] == 'variety')
                || ($scope.colValue[1] == 'variety' && $scope.colValue[2] == 'farm')
            ){
                $scope.colValue[3] = 'size';
            }
            if(
                ($scope.colValue[1] == 'variety' && $scope.colValue[2] == 'size')
                || ($scope.colValue[1] == 'size' && $scope.colValue[2] == 'variety')
            ){
                $scope.colValue[3] = 'farm';
            }
            if(
                ($scope.colValue[1] == 'farm' && $scope.colValue[2] == 'size')
                || ($scope.colValue[1] == 'size' && $scope.colValue[2] == 'farm')
            ){
                $scope.colValue[3] = 'variety';
            }

            this.setSort(3, $scope.colValue[3])
            param_for_get[$scope.colValue[2]] = $scope.colSelectedValue[2];
            self.getAnalytics(3, param_for_get);
        };

        this.setColSelectedValue = function(col, selectedValue, item){

            console.log(col)
            console.log(selectedValue)
            console.log(item)

            if($scope.colSelectedValue[col] == 0 || $scope.colSelectedValue[col] != selectedValue){
                $scope.colSelectedValue[col] = selectedValue;
                $scope.colSelectedValueItem[col] = item;
            }else{
                $scope.colSelectedValue[col] = 0;
                $scope.colSelectedValueItem[col] = {};
            }

            if (col == 1) {
                if ($scope.colValue[1] == 'variety') {
                    $scope.colValue[2] = 'farm'
                } else {
                    $scope.colValue[2] = 'variety'
                }
                this.changeSelectSecond()
            }

            if(col == 2){
                var param_for_get = {};
                param_for_get[$scope.colValue[1]] = $scope.colSelectedValue[1];
                param_for_get[$scope.colValue[2]] = $scope.colSelectedValue[2];
                self.getAnalytics(3, param_for_get);
            }
        };


        /**
         * Renew all columns with analytics
         */
        this.renewAnalytics = function(){
            if($scope.colValue[1] != ''){
                self.getAnalytics(1, {});

                if($scope.colValue[2] != ''){
                    var param_for_get = {};
                    param_for_get[$scope.colValue[1]] = $scope.colSelectedValue[1];
                    self.getAnalytics(2, param_for_get);

                    if($scope.colValue[3] != ''){
                        var param_for_get = {};
                        param_for_get[$scope.colValue[1]] = $scope.colSelectedValue[1];
                        param_for_get[$scope.colValue[2]] = $scope.colSelectedValue[2];
                        self.getAnalytics(3, param_for_get);
                    }
                }
            }
        };
        
        this.setSort = function (col, name) {
            if($scope.colSort[col].sort == name){
                if($scope.colSort[col].direct != 'asc'){
                    $scope.colSort[col].direct = 'asc';
                }else{
                    $scope.colSort[col].direct = 'desc';
                }
            }else{
                $scope.colSort[col].sort = name;
                $scope.colSort[col].direct = 'asc';
            }
            self.renewAnalytics();
        };

/*
    // Выбранные значения сортировки
    var sortValueFromCookies = $cookies.get('order-sort');
    if(typeof sortValueFromCookies != 'undefined'){
        $rootScope.sort = sortValueFromCookies;
    }else{
        $rootScope.sort = 'created_at';
        $cookies.put('order-sort', 'created_at', {path: self.path_alias});
    }
    var directValueFromCookies = $cookies.get('order-direct');
    if(typeof directValueFromCookies != 'undefined'){
        $rootScope.direct = directValueFromCookies;
    }else{
        $rootScope.direct = 'desc';
        $cookies.put('order-direct', 'desc', {path: self.path_alias});
    }
    this.setSort = function(sort_column){
        console.log(self.path_alias);
        if($rootScope.sort == sort_column){
            // Изменяем направление сортировки
            if($rootScope.direct == 'asc'){
                $rootScope.direct = 'desc';
                $cookies.put('order-direct', 'desc', {path: self.path_alias});
            }else{
                $rootScope.direct = 'asc';
                $cookies.put('order-direct', 'asc', {path: self.path_alias});
            }
        }else{
            // Изменяем сортировку
            $cookies.put('order-sort', sort_column, {path: self.path_alias});
            $rootScope.sort = sort_column;
        }
        $rootScope.timerCountSec = 0;
    };
    //$rootScope.setSort = this.setSort;

    //$scope.renewTimer();
    //$rootScope.renewOrders();

    $scope.classSort = function(sort_column){
        if($rootScope.sort == sort_column){
            // Изменяем направление сортировки
            if($rootScope.direct == 'asc'){
                return 'sorted sorted-up';
            }else{
                return 'sorted sorted-down';
            }
        }
    };
*/

    this.init();
} ])

.filter('unsafe', function($sce) {
    return function(val) {
        return $sce.trustAsHtml(val);
    };
});