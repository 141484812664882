angular.module('bfServices.password', [])

.service('password', ['$http', function($http) {

    var self = this;

    this.fieldsErrors =  {};
    this.successMessage = false;

    this.save = function() {

        var params = {};
        params.oldPassword = this.oldPassword;
        params.newPassword = this.newPassword;
        params.confirmPassword = this.confirmPassword;

        $http({
            method: 'POST',
            url: API_LINK + '/profile/changePassword/',
            data: $.param(params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            if (result.data.errors.length == 0) {
                self.successMessage = result.data.infoMessage.message;
                self.oldPassword = '';
                self.newPassword = '';
                self.confirmPassword = '';
            } else {
                self.fieldsErrors = result.data.fieldsErrors;
            }
        }, function(err){
            console.log(err);
        });
    };
}]);
