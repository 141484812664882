function form_values(selector)
{
    var form = $(selector);
    var values = {};

    $(form).find('input, select, textarea').each(function(index, value)
    {
        if (!$(value).is(':checkbox'))
        {
            values[$(value).attr('name')] = $(value).val();
        }
        else if ($(value).is(':checkbox') && $(value).is(':checked'))
        {
            values[$(value).attr('name')] = $(value).val();
        }
    });

    values['lang'] = language;

    return values;
}

function clear_errors()
{
    $('.control__error')
    .removeAttr('title')
    .removeClass('control__error')
    .removeClass('data-text-error');
}

function show_error(obj, msg)
{
    obj.closest('.control')
    .addClass('control__error')
    .attr('data-text-error', msg.join('<br>'));
}

function show_errors(selector, response)
{
    for (var i in response)
    {
        if ($(selector).find('[name=' + i + ']').length > 0)
        {
            show_error($(selector).find('[name=' + i + ']'), response[i]);
        }
    }
}

function EditPhoneSubmit(e)
{
    form_selector = 'form#edit-phone';

    values = form_values(form_selector);
    url = $(form_selector).attr('action');

    var iti = window.intlTelInputGlobals.getInstance(document.querySelector("#phone"));
    var countryData = iti.getSelectedCountryData();
    values.code = countryData.iso2 + "+" + countryData.dialCode;
    values.iso2 = countryData.iso2;
    values.dialcode = countryData.dialCode;

    $(form_selector + ' input:submit').prop('disabled', true);

    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        crossDomain: true,
        data: values,
        success:function(response)
        {
            $(form_selector + ' input:submit').prop('disabled', false);

            clear_errors();

            if (response.data.success)
            {
console.log(values);
                $('#display_phone').html("+" + values.dialcode + values.phone);
                $("#overlay, .popup:visible").fadeOut();
                $(form_selector + ' input[name=phone]').val('');
            }
            else
            {
                show_errors(form_selector, response.fieldsErrors);
            }
        }
    });

    e.preventDefault();
    e.stopPropagation();
}


function SignupBuyerStep1Submit(e)
{
    form_selector = 'form#signupbuyerstep1';

    values = form_values(form_selector);

    var iti = window.intlTelInputGlobals.getInstance(document.querySelector("#phone"));
    //values.phone = iti.getNumber();
    var countryData = iti.getSelectedCountryData();
    values.code = countryData.iso2 + "+" + countryData.dialCode;
    values.iso2 = countryData.iso2;
    values.dialcode = countryData.dialCode;
    url = $(form_selector).attr('action')
    console.log(values);
    $(form_selector + ' input:submit').prop('disabled', true);

    $.ajax({
        url: url,
        //headers: {  'Access-Control-Allow-Origin': url },
        type: 'POST',
        crossDomain: true,
        dataType: 'json',
        data: values,
        success:function(response)
        {
            $(form_selector + ' input:submit').prop('disabled', false);

            clear_errors();

            if (response.data.success)
            {
                window.location.href = window.location.href + "?secret=" + response.data.success + "&phone=" + response.data.phone;
            }
            else
            {
                show_errors(form_selector, response.fieldsErrors);
            }
        }
    });

    e.preventDefault();
    e.stopPropagation();
}

function SignupBuyerStep2Submit(e)
{
    form_selector = 'form#signupbuyerstep2';

    values = form_values(form_selector);
    url = $(form_selector).attr('action');

    $(form_selector + ' input:submit').prop('disabled', true);

    $.ajax({
        url: url,
        type: 'post',
        crossDomain: true,
        dataType: 'json',
        data: values,
        success:function(response)
        {
            $(form_selector + ' input:submit').prop('disabled', false);

            clear_errors();

            if (response.data.success)
            {
                window.location.href = response.data.success;
            }
            else
            {
                show_errors(form_selector, response.fieldsErrors);
            }
        }
    });

    e.preventDefault();
    e.stopPropagation();
}

function SignupSellerStep1Submit(e)
{
    form_selector = 'form#signupsellerstep1';

    values = form_values(form_selector);
    url = $(form_selector).attr('action');

    $(form_selector + ' input:submit').prop('disabled', true);

    $.ajax({
        url: url,
        type: 'post',
        crossDomain: true,
        dataType: 'json',
        data: values,
        success:function(response)
        {
            $(form_selector + ' input:submit').prop('disabled', false);

            clear_errors();

            if (response.data.success)
            {
                window.location.href = window.location.href + "?secret=" + response.data.success + "&phone=" + response.data.phone;
            }
            else
            {
                show_errors(form_selector, response.fieldsErrors);
            }
        }
    });

    e.preventDefault();
    e.stopPropagation();
}

function FeedbackSubmit(e)
{
    form_selector = 'form#feedback';

    values = form_values(form_selector);
    url = $(form_selector).attr('action');

    $(form_selector + ' input:submit').prop('disabled', true);

    $.ajax({
        url: url,
        type: 'post',
        crossDomain: true,
        dataType: 'json',
        data: values,
        success:function(response)
        {
            $(form_selector + ' input:submit').prop('disabled', false);

            clear_errors();

            if (response.data.contact_from)
            {
               // window.location.href = response.data.success

                $("#overlay").fadeIn();
                $('#feedback_sent').fadeIn();

                $("#overlay, .popup__close, .button_popup__close").on("click", function() {
                    $("#overlay, .popup:visible").fadeOut();
                    window.location = window.location.href;
                    //return false;
                });
            }
            else
            {
                show_errors(form_selector, response.fieldsErrors);
            }
        }
    });

    e.preventDefault();
    e.stopPropagation();
}

function AddOffer(data, init)
{
    var pagefn = doT.template(document.getElementById('rowtmpl').text, undefined);

    if (!init)
        $('#rowtmpl').next().remove();

    $('.trading__table').append(pagefn(data));
}

function calculate() {
    fob_price_per_unit = $('#fob_price_per_unit').val()
    fob_price_per_unit = fob_price_per_unit.replace(/^\$+/g, '')

    fob_rate = $('#fob_rate').val()
    fob_rate = parseFloat(fob_rate.replace(/^\$+/g, ''))

    box_price = parseFloat(fob_price_per_unit) * parseInt($('#unit_per_box').val())

    if (isNaN(box_price))
        box_price = 0

    box_weight = parseFloat($('#box_weight').val()) / 2.205

    trucking_company_cube_rate = $('#trucking_company_cube_rate').val()
    trucking_company_cube_rate = trucking_company_cube_rate.replace(/^\$+/g, '')

    track = parseFloat(trucking_company_cube_rate) * parseFloat($('#box_size').val())

    trucking_company_fuel_surcharge = $('#trucking_company_fuel_surcharge').val()
    trucking_company_fuel_surcharge = parseFloat(trucking_company_fuel_surcharge.replace(/(\s|%)+/g, ''))

    biflorica_rate = $('#biflorica_rate').val()
    biflorica_rate = parseFloat(biflorica_rate.replace(/(\s|%)+/g, ''))

    duty = parseInt($('#duty').val())

    total_miami = (box_price * (1 + biflorica_rate / 100) + box_weight * fob_rate) * (1 + duty / 100)
    total = track * (1 + trucking_company_fuel_surcharge / 100) + total_miami

    unit_per_box = parseInt($('#unit_per_box').val())
    total_miami = total_miami / unit_per_box
    total = total / unit_per_box

    if (isNaN(total_miami))
        total_miami = 0

    if (isNaN(total))
        total = 0

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    $('#cost_miami').text(formatter.format(total_miami))
    $('#cost_location').text(formatter.format(total))
}

function calculate_size() {
    volume = parseInt($('#length').val()) * parseInt($('#width').val()) * parseInt($('#height').val())

    if ($('#measurements').val() == 'inch') {
        volume = volume / 1728
    } else {
        volume = volume / 28320
    }

    volume = parseInt(volume * 100) / 100
    $('#cb_feet').val(volume)
}

$(document).ready(function(){

    if ($('#fob_location').length > 0) {

        fobRate = new AutoNumeric('#fob_rate', { digitGroupSeparator: '', currencySymbol : '$' });
        new AutoNumeric('#fob_price_per_unit', { digitGroupSeparator: '', currencySymbol : '$' });
        new AutoNumeric('#trucking_company_cube_rate', { digitGroupSeparator: '', currencySymbol : '$' });
        new AutoNumeric('#kg', { digitGroupSeparator: '', decimalPlaces : 0 });
        new AutoNumeric('#unit_per_box', { digitGroupSeparator: '', decimalPlaces : 0 });
        kgElement = new AutoNumeric('#box_weight', { digitGroupSeparator: '', decimalPlaces : 2, suffixText: ' lbs.' });
        sizeElement = new AutoNumeric('#box_size', { digitGroupSeparator: '', decimalPlaces : 2});
        new AutoNumeric('#trucking_company_fuel_surcharge', { digitGroupSeparator: '', decimalPlaces : 0, suffixText: ' %'});
        new AutoNumeric('#biflorica_rate', { digitGroupSeparator: '', decimalPlaces : 0, suffixText: ' %'});
        new AutoNumeric('#length', { digitGroupSeparator: '', decimalPlaces : 0 });
        new AutoNumeric('#width', { digitGroupSeparator: '', decimalPlaces : 0 });
        new AutoNumeric('#height', { digitGroupSeparator: '', decimalPlaces : 0 });

        $('#box_size_calculator_link').click(function(event) {
            $('#box_size_calculator').modal();
            $('#box_size_calculator').css('display', 'inline-block');
            $('#box_size_calculator').css('z-index', '10000');
            return false;
        });

        $('#box_weight_calculator_link').click(function(event) {
            $('#box_weight_calculator').modal();
            $('#box_weight_calculator').css('display', 'inline-block');
            $('#box_weight_calculator').css('z-index', '10000');
            return false;
        });

        $('#fob_location').change(function () {
            fobRate.set($(this).val())

            if ($(this).val() == '2.50') // quito
            {
                $('#duty').val('1').change()
            } else {
                $('#duty').val('0').change();
            }

        })

        fobRate.set($('#fob_location').val())

        $('.calc_size').change(function () {
            calculate_size()
        })

        $('.calc_size').keyup(function () {
            calculate_size()
        })

        $('#kg').keyup(function () {
            $('#pounds').val(parseInt( $('#kg').val() * 2.205 * 100) / 100 )
        })

        $('#add_size').click(function () {
            sizeElement.set($('#cb_feet').val())
            $('#box_size_calculator').modal('hide');
        })

        $('#add_weight').click(function () {
            kgElement.set($('#pounds').val())
            $('#box_weight_calculator').modal('hide');
        })

        $('input').change(function () {
            calculate()
        })

        $('select').change(function () {
            calculate()
        })
    }

    if ($('form#edit-phone').length > 0)
        $('form#edit-phone').submit(EditPhoneSubmit);

    if ($('form#signupbuyerstep1').length > 0){
        $('form#signupbuyerstep1').submit(SignupBuyerStep1Submit);
    }

    if ($('form#feedback').length > 0)
        $('form#feedback').submit(FeedbackSubmit);

    if ($('form#signupbuyerstep2').length > 0)
    {
        $('form#signupbuyerstep2').submit(SignupBuyerStep2Submit);

        var country = $('[name=country_id]');
        $(country).data("customSelect").reinit();

        $(country).change(function()
        {
            var country_id = country.val();
            var city = $('[name=city_id]');
            $(city).find('option').remove();

            $.ajax({
                url:$('[name=city_id]').attr('url'),
                dataType:'json',
                type: "POST",
                crossDomain: true,
                data:{country_id: country_id, lang: language},
                success:function(response){
                    for(var key in response.data.cities )
                    {
                        var line = response.data.cities[key];
                        var city = $('[name=city_id]');
                        $(city).append('<option value="'+line.id+'">'+line.name+'</option>');
                    }

                    $(city).data("customSelect").reinit();
                    $(city).data("customSelect").setLabelText("&nbsp;");
                    city.prop("value", !1);
                }
            });
        });

        $.ajax({
            url: $(country).attr('url'),
            dataType: 'json',
            type: 'post',
            data: {lang: language},
            crossDomain: true,
            success:function(response)
            {
                var country = $('[name=country_id]');

                for(var key in response.data.countries )
                {
                    var line = response.data.countries[key];

                        $(country).append('<option value="'+line.id+'">'+line.name+'</option>');
                }

                $(country).data("customSelect").reinit();

                if (language == 'ru') {
                    country.prop("value", 182);
                    $(country).change();
                }

                if (language == 'fr') {
                    country.prop("value", 73);
                    $(country).change();
                }
            }
        });
    }

    if ($('#resend_sms').length > 0)
    {
        $('#resend_sms').click(function()
        {
            $.get({
                url: $('#resend_sms').attr('url')
            });
        });
    }

    if ($('#edit_phone').length > 0)
    {
        $('#edit_phone').click(function()
        {
            clear_errors();
        });
    }

    if ($('form#signupsellerstep1').length > 0)
    {
        $('form#signupsellerstep1').submit(SignupSellerStep1Submit);

        var country = $('[name=country_id]');

        $(country).change(function()
        {
            var country_id = country.val();
            var city = $('[name=city_id]');
            $(city).find('option').remove();

            $.ajax({
                url:$('[name=city_id]').attr('url'),
                dataType:'json',
                type: "POST",
                crossDomain: true,
                data:{country_id: country_id, lang: language},
                success:function(response){
                    for(var key in response.data.cities )
                    {
                        var line = response.data.cities[key];
                        var city = $('[name=city_id]');
                        $(city).append('<option value="'+line.id+'">'+line.name+'</option>');
                    }

                    $(city).data("customSelect").reinit();
                    $(city).data("customSelect").setLabelText("&mdash;");
                    city.prop("value", !1);
                }
            });
        });
    }

    if ($('.trading__table').length > 0)
    {
        $.get(marketplace_server + '/api/misc/getLastRequests', function (response) {
            for (var i in response.data.list)
                AddOffer(response.data.list[i], true);
        });

        var socket = io.connect(commet_server + '/biflorica.com');

        socket.on('onLastOffersSiteFromServer', function(data) {
            if ($('.trading__table').length > 0) {
                AddOffer(data, false);
            }
        });
    }
});



jQuery(document).ready(function($) {
	if($(".control-calc select").length) {
		$(".control-calc select").each(function() {
			var $this = $(this);

			$this.customSelect({
				onChange: function(id, value, selectId, dataValue) {
					var $closest = $this.closest(".custom-select");
					
					if(selectId == "select-phone") {
						var img = $this.find("option:selected").attr("data-html-before");
							
						$closest.find(".txt").html(img + " " + (dataValue ? dataValue : value));
					} else {
						$closest.find(".txt").html($("#"+id).find(".selected a").html());
					}
				},
				
				onInit: function(id, value, selectId, dataValue) {
					var $closest = $this.closest(".custom-select");
					
					if(selectId == "select-phone") {
						var img = $this.find("option:selected").attr("data-html-before");
							
						$closest.find(".txt").html(img + " " + (dataValue ? dataValue : value));
					}
				}
			});
			
			if($this.data("placeholder")) {
				$this.data('customSelect').setLabelText($this.data("placeholder"));
			} else {
                $this.closest(".custom-select").addClass("select-changed");
            }

			$this.on("change", function() {
				$this.closest('.control__error').removeClass('control__error');
			});
		});
	}

	$("body").on("input change", ".control__error input", function() {
		$(this).closest('.control__error').removeClass('control__error');
	});

	if(MMM('(min-width: 1024px)')) {
		$(".js-toggle-drop-langs").on({
			click: function(e) {
				$("body").toggleClass("show-drop-langs");
				$("body").removeClass("show-menu");

				return false;
			}
		});

		$("body").on("click", function(e) {
			if(!($(e.target).hasClass("js-toggle-drop-langs") || $(e.target).closest(".js-toggle-drop-langs").length)) {
				$("body").removeClass("show-drop-langs");
			} else {
			}
		});
	}

	$(".js-toggle-menu").on("click", function() {
		$("body").toggleClass("show-menu");
		$("body").removeClass("show-drop-langs");
	});

	$(".js-up").on("click", function(e) {
		e.preventDefault();

		$("html:not(:animated), body:not(:animated)").animate({
			scrollTop: 0
		});
	});

	(function() {
		var $slider = $(".trust-us__slider-inner"),
			timerSlider,
			slider;

		if(!$slider.length) {
			return;
		}

		$(window).on("load", function() {
			slider = $slider.bxSlider(getParams());	

			$(window).on("resize oreintationchange", function() {
				if(timerSlider) {
					clearTimeout(timerSlider);
					timerSlider = null;
				}

				timerSlider = setTimeout(function() {
					slider.reloadSlider(getParams());
				}, 100);
			});
		});

		function getParams() {
			var count = 5;

			if(MMM('(max-width: 1400px)') && MMM('(min-width: 1100px)')) {
				count = 4;
			} else if (MMM('(max-width: 1099px)') && MMM('(min-width: 980px)')) {
				count = 3;
			} else if (MMM('(max-width: 979px)') && MMM('(min-width: 768px)')) {
				count = 3;
			} else if (MMM('(max-width: 767px)') && MMM('(min-width: 630px)')) {
				count = 2;
			} else if (MMM('(max-width: 480px)')) {
				count = 1;
			}

			return {
				pager: !1,

				minSlides : count,
				maxSlides : count,
				slideWidth: $slider.closest(".container").width() / count,
				moveSlides: 1,

				auto: !0,
				pause: 6000,
				autoHover: !0,
				speed: 600,

				nextText: '',
				prevText: '',
				nextSelector: '.trust-us__slider-next',
				prevSelector: '.trust-us__slider-prev'
			}
		}
	}());

	(function() { // filter trust
		var $grid = $(".logos__grid");

		if(!$grid.length) {
			return;
		}

		var $filter = $(".logos__menu a"),
			$ajax   = $(".js-load-logos");

		$grid.isotope({
		  	itemSelector: '.logos__item',
		  	percentPosition: true,
		  	masonry: {
		    	columnWidth: '.logos__item-sizer'
		  	}
		});

		$grid.css("opacity", 1);

		$(".logos__menu a").on("click", function(e) {
			e.preventDefault();

			if($(this).hasClass("active")) {
				return;
			}

			$grid.isotope({ filter:  $(this).data("filter")});

			$(this).addClass("active").siblings(".active").removeClass("active");
		});

		$ajax.on("click", function(e) {
			e.preventDefault();

			var $this = $(this);

			if($this.hasClass("disabled")) {
				return;
			}

			$this.addClass("disabled");

			$.ajax({
				url: $this.attr("href"),
				dataType: "json",
				success: function(data) {
					var $items;

					if(data) {
						if("next" in data && data.next) {
							$this.attr("href", data.next);
							$this.removeClass("disabled");
						} else {
							$this.parent().slideUp();
						}

						if("content" in data && data.content) {
							$items = $(data.content);

							$grid
								.append($items)
								.isotope('appended', $items);

							setTimeout(function() {
								$("html:not(:animated), body:not(:animated)").animate({
									scrollTop: $items.first().offset().top - 4
								});
							}, 400);
						} else {
							$this.parent().slideUp();
						}
					} else {
						console.log('loading was wrong');
					}
				}
			});
		});
	}());

    $("#overlay, .popup__close, .button_popup__close").on("click", function() {
        $("#overlay, .popup:visible").fadeOut();
        return false;
    });
	
	function showPopup($popup) {
		$("#overlay").fadeIn();
		$popup.fadeIn();
	}
	
	$(".js-popup-open").on("click", function(e) {
		e.preventDefault();
		
		var $popup = $($(this).data("target"));
		if($popup.length) {
			showPopup($popup);
		}
	});

	// animation on scroll
	//$(window).load(function() {
		setTimeout(function() {
			$("body").addClass("start");

			(function() {
				var $els = $(".animation"),
					limit = .85,
					timeout = 500;

				$(window).on("scroll", animateElems);

				setTimeout(animateElems, 600);

				function animateElems() {
					var $elems = $els.not(".animation-end"),
						count = 0;

					$elems.each(function(i) {
						if($(this).closest(".section_6__list-2").length) {
							limit = .9;
						}

						var _if = ($(this).offset().top < $(window).scrollTop() + $(window).height() * limit),
							_if2 = ($(this).offset().left < $(window).width()),
							_timeout;

						if(_if && _if2) {
							$(this).addClass("animation-end");
							
							_timeout = timeout * count;
							count += 1;

							(function(index, $this) {
								setTimeout(function() {
									$this.addClass("transition");
								}, _timeout);
							}(i, $(this)));
						} else if (_if && !_if2) {
							$(this).addClass("animation-end transition");
						}
					});
				}
			}());
		}, 500);
    //});
	
	$(".js-reset-error").on("click", function(e) {
		e.preventDefault();
		
		resetError($(this).closest("form"));
	});
});

function MMM(expression) {
	return !!('matchMedia' in window && window.matchMedia(expression).matches);
}

function resetError(form) {
	var $elements = form.find(".control__error");
	
	$elements.each(function() {
		$(this).removeClass("control__error");
		
		var elems = {
			$inputText: $(this).find("input[type=text]"),
			$inputMail: $(this).find("input[type=email]"),
			$inputTel : $(this).find("input[type=tel]"),
			$inputPass: $(this).find("input[type=password]"),
			$checkbox : $(this).find("input[type=checkbox]"),
			$radio    : $(this).find("input[type=radio]"),
			$textarea : $(this).find("textarea"),
			$select   : $(this).find("select")
		};
		
		for(var key in elems) {
			if(elems[key].length) {
				elems[key].prop("value", false).val("").change();
			}
		}
	});
}