angular.module('bfServices.counter', [])

.service('counter', ['$http', '$resource', '$rootScope', function($http, $resource, $rootScope) {

    this.toMe = START_REQUESTS_TOME_VALUE;

    var self = this;

    this.renewRequestsToMe = function() {
        if ($rootScope.current_user_role != "seller")
            return

        url = API_LINK + '/marketPlace/counterToMe';

        $http({
            method: 'GET',
            url: url,
        }).then(function( result ) {
            self.toMe = result.data.data.count
        })
    };

    this.renewTimerToMe = function(){
        self.renewRequestsToMe();
        setTimeout(self.renewTimerToMe, TIMER_RENEW_PERIOD * 1000);
    };

    this.renewTimerToMe();
}]);
