angular.module('bfControllers.nav', [])
.controller('navController', ['$scope', '$rootScope', '$cookies', 'messages', 'handbook', 'reclamation', 'counter', function($scope, $rootScope, $cookies, messages, handbook, reclamation, counter){

    $scope.for_filters = handbook;
    $scope.messages =  messages;
    $scope.reclamation = reclamation;
    $scope.counter = counter;

    $rootScope.manage = {
        plantation: 0
    };

    this.path_alias = '/';

    var self = this;
    // Выбранные значения плантации для управления
    var managePlantation = $cookies.get('manage_plantation');
    if(typeof managePlantation != 'undefined'){
        $rootScope.manage.plantation = managePlantation;
    }else{
        //$rootScope.sort = 'utime';
        //$cookies.put('sort_requests', 'utime', {path: self.path_alias});
    }

    this.changeManagedPlantation = function(){
        console.log($rootScope.manage.plantation);
        $cookies.put('manage_plantation', $rootScope.manage.plantation, {path: self.path_alias});
        //$rootScope.timerCountSec = 0;
        location.reload();
    };

    /**
     * Устанавливает количество непрочитанных сообщений
     *
     * @param count
     */
    $rootScope.updateMessageCounts = function(count) {
        if (!isNaN(parseInt(count))) {
            $scope.messages.unreadCount = count;
            $scope.$apply();
        }
    };
} ])

.filter('unsafe', function($sce) {
    return function(val) {
        return $sce.trustAsHtml(val);
    };
});