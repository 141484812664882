angular.module('bfServices', [])

.service('filter', ['$resource', function($resource) {

    var filter_value = {
        plantations: [],
        types: [],
        sorts: [],
        sizes: [],
        description: '',
        gardener: '',

    };

    this.getFiltersValue = function(){
        return filter_value;
    };

    this.setFiltersValue = function(filter_value_in){
        filter_value = filter_value_in;
    };

}]);
