angular.module('bfControllers.popup', [])
        .controller('PopupController', ['$resource', '$scope', '$cookies', '$rootScope', function ($resource, $scope, $cookies, $rootScope) {

                $scope.setAlertReaction = function (id, popupNum) {
                    $resource(API_LINK + '/popup/:action/id/:id',
                            {action: 'update', id: id},
                            {get: {method: 'GET'}}
                    ).get(function(){(popupNum < 0) || $('.popupItem' + popupNum).modal('show');});
                };

                $scope.setConfirmReaction = function (id, confirm, popupNum) {
                    $resource(API_LINK + '/popup/:action/id/:id',
                            {action: 'update', id: id, confirm: confirm},
                            {get: {method: 'GET'}}
                    ).get(function(){(popupNum < 0) || $('.popupItem' + popupNum).modal('show');});
                };

                $scope.setPromptReaction = function (id, popupNum) {
                    var comment = $('#prompt' + id).val();
                    $resource(API_LINK + '/popup/:action/id/:id',
                            {action: 'update', id: id, comment: comment},
                            {get: {method: 'GET'}}
                    ).get(function(){(popupNum < 0) || $('.popupItem' + popupNum).modal('show');});
                };

            }]);