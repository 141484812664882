angular.module('bfControllers.manager', [])
        .controller('ManagerController', ['$scope', '$rootScope', '$resource', '$cookies', '$http', 'messages', 'helpers', function ($scope, $rootScope, $resource, $cookies, $http, messages, helpers) {

                this.path_alias = '/marketPlaceNew/manager/index';

                $scope.ajaxEditable = "/marketPlaceNew/manager/ajaxUpdate";
                $scope.apiPrefix = $('#apiLink').val();

                $scope.pagination = new helpers.pagination(ITEMS_SHOW_PER_PAGE, 41);

                $scope.comments = new helpers.comments($('#managerComment'), $("#datepicker").val());
                $rootScope.openComment = $scope.comments.openForm;

                $scope.select = {plantation_id: "Select plantation ...", buyer_id: "Select buyer ...", truck_id: "Select truck ..."};

                $scope.filter = {air_date: $("#datepicker").val()};
                $scope.deals = [];
                $scope.renewList = $scope.pagination.renewPage = function () {
                    $rootScope.querySent = true;
                    $resource(API_LINK + '/deals/' + $scope.apiPrefix,
                            {
                                sort: $rootScope.sort,
                                direct: $rootScope.direct,
                                plantation_id: $scope.filter.plantation_id,
                                air_date: $scope.filter.air_date,
                                buyer_id: $scope.filter.buyer_id,
                                truck_id: $scope.filter.truck_id,
                                limit: $scope.pagination.itemsPerPage,
                                page: $scope.pagination.currPage
                            },
                            {get: {method: 'GET'}}
                    ).get(function (result) {
                        $scope.deals = result.data.list;
                        var count = parseInt($scope.deals.length);
                        var total = parseInt(result.data.total);
                        $scope.pagination.recountPages(count, total);
                        $rootScope.querySent = false;
                        setTimeout(function () {
                            $('table tr td.editable').each(function () {
                                var dealId = $(this).siblings(':first-child').text();
                                $(this).fastEdit($scope.ajaxEditable, dealId, $(this).attr('field'));
                            });
                        }, 500);

                    });
                };
                $scope.renewList();

//Коментарии
                $scope.comments.add = function () {
                    $resource(API_LINK + '/comments/:action/date/:date',
                            {action: 'add', date: $scope.comments.date, comment: $('#pr-comment').val()},
                            {get: {method: 'GET'}}
                    ).get(function (result) {
                        if (result.data.result === 'success') {
                            $scope.comments.update();
                        }
                    });
                };
                $scope.comments.update = function (date) {
                    $('#pr-comment').val('');
                    $resource(API_LINK + '/comments/:action/date/:date', {action: 'list', date: $scope.comments.date},
                            {get: {method: 'GET'}}
                    ).get(function (result) {
                        $scope.comments.list = result.data.list;
                        $scope.comments.count = result.data.total;
                    });
                };
                $scope.comments.update($scope.filter.air_date);

                $scope.setSort = function (sort_column) {
                    console.log(self.path_alias);
                    if ($rootScope.sort == sort_column) {
                        // Изменяем направление сортировки
                        if ($rootScope.direct == 'asc') {
                            $rootScope.direct = 'desc';
                            $cookies.put('direct', 'desc', {path: this.path_alias});
                        } else {
                            $rootScope.direct = 'asc';
                            $cookies.put('direct', 'asc', {path: this.path_alias});
                        }
                    } else {
                        // Изменяем сортировку
                        $cookies.put('sort', sort_column, {path: this.path_alias});
                        $rootScope.sort = sort_column;
                    }
                    $scope.renewList();
                };
                $rootScope.setSort = this.setSort;

                $scope.classSort = function (sort_column) {
                    if ($rootScope.sort == sort_column) {
                        // Изменяем направление сортировки
                        if ($rootScope.direct == 'asc') {
                            return 'sorted sorted-up';
                        } else {
                            return 'sorted sorted-down';
                        }
                    }
                };

                $scope.selectFilter = function (field, val, content) {
                    $scope.filter[field] = val;
                    $scope.select[field] = content;
                    $scope.renewList();
                }

                $scope.getExcel = function () {
                    var queryStr = 'show_deals_excel=1';
                    for (var param in $scope.filter) {
                        queryStr += '&' + param + '=' + $scope.filter[param];
                    }
                    document.location.href = '/marketPlaceNew/manager/' + $scope.apiPrefix + '?' + queryStr;
                }
            }]);



