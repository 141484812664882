angular.module('bfServices.plantationInvoice', [])

/**
 * Сервис инвойсов плантации
 */
.service('plantationInvoice', ['$http', '$resource', '$rootScope', function($http, $resource, $rootScope) {
    this.edited = { };

    this.availableMarks = [];

    this.isEdit = false;

    // Ошибки
    this.errors = {};

    var self = this;

    this.eraseFileErrors = function () {
        if (this.errors.files !== 'undefined') {
            $('#fine-uploader-files-error').removeClass('files-uploading-error');
            delete this.errors.files;
        }
    };

    // set files error
    this.setFileError = function (msg) {
        this.errors.files = [];
        this.errors.files.push(msg);
    };

    /**
     * Load submarks
     */
    this.getMarks = function () {
        url = API_LINK + '/plantationInvoices/getMarks/';

        $http({
            method: 'GET',
            url: url
        }).then(function(result) {
            self.availableMarks = result.data.data.marks;
        }, function(err){
            console.log(err);
        });
    };

    /**
     * Инициализация
     * @param id - id инвойса
     */
    this.set = function(id) {
        //Очищаем ошибки на форме
        self.clearErrors();

        if (typeof id === 'undefined' || id === '') {
            self.isEdit = false;
            self.edited = {'id': 0, 'invoices': [], 'mark': ''};
            self.errors = {};
            $('#fine-uploader-gallery').fineUploader('setItemLimit', 0);
            return false;
        }

        $('#fine-uploader-gallery').fineUploader('setItemLimit', 1);

        self.isEdit = true;

        if (self.availableMarks.length === 0) {
            self.getMarks();
        }

        // http://ec.term.biflorica:8080/api/plantationInvoices/get/id
        url = API_LINK + '/plantationInvoices/get/id/' + id;
        $resource(
            url,
            {},
            {get: {method:'GET'}}
        ).get(function(result){
            self.edited = result.data.item;

            self.errors = {};
            console.log(self.edited);
        });
    };

    /**
     * Получение
     * @returns {{}|*}
     */
    this.get = function(){
        return self.edited;
    };

    /**
     * Сохранение 
     */
    this.save = function() {
        var sent_params = this.getParamsFromEdited(self.edited);
        var files_count = 0;
        // Проверка и перегрузка файлов
        var files_obj = $('#fine-uploader-gallery').fineUploader('getUploads');
        for (var id_file in files_obj) {
            var file_obj = files_obj[id_file];
            if (file_obj.status == "upload successful") {
                sent_params['files['+file_obj.id+']'] = file_obj.uuid + '__' + file_obj.originalName;
                files_count++;
            }
        }

        self.sentForm = true;

        // http://ec.term.biflorica:8080/api/plantationInvoices/save
        url = API_LINK + '/plantationInvoices/save';
        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function(result) {
            if (result.data.data.item.errors.length === 0) {
                self.reset();
                $('.bf-create-plantation-invoice-dialog').modal('hide');
                $rootScope.renewItems();
            } else {
                self.errors = result.data.data.item.errors;
                if (typeof self.errors.files !== 'undefined') {
                    $('#fine-uploader-files-error').addClass('files-uploading-error').html(self.errors.files);
                }

                console.log(self.errors);
            }
        }, function(err) {
            self.clearErrors();
            alert('Something went wrong. Please try again later');
            console.log(err);
        });

        self.sentForm = false;
    };

    /**
     * Очистка
     * @returns {{}|*}
     */
    this.reset = function(){
        self.setEmpty();
        self.clearErrors();
        return true;
    };

    /**
     * Формируем параметры на отправку
     * @param edited
     * @returns {{}|*}
     */
    this.getParamsFromEdited = function(edited) {
        var sent_params = {};

        sent_params.id = edited.id;
        sent_params.price = edited.price;
        sent_params.number = edited.number;
        sent_params.boxes_amount = edited.boxes_amount;
        sent_params.boxes_type = edited.boxes_type;
        sent_params.count_all = edited.count_all;
        sent_params.mark = edited.mark;

        return sent_params;
    };

    /**
     * пустая структура для инициализации и обнуления
     */
    //
    this.setEmpty = function() {
        self.edited = {
            invoices: [],
            boxes_types: {},
            mark: ''
        };

        self.sentForm = false;
        $('#fine-uploader-gallery').fineUploader('reset');
        self.errors = {};
        console.log(self.errors);
    };
    this.setEmpty();

    /**
     * Очищаем ошибки на форме
     */
    this.clearErrors = function() {
        self.errors = {};
        $('#fine-uploader-files-error').removeClass('files-uploading-error');
    };

    this.incrementValue = function (obj, value, step, fixed) {
        current = parseFloat(obj[value]);
        step = parseFloat(step);
        var rez = current + step;
        obj[value] = rez.toFixed(fixed);
    };
    this.decrementValue = function (obj, value, step, fixed, min) {
        if(typeof min == 'undefined') min = 0;
        current = parseFloat(obj[value]);
        step = parseFloat(step);
        var rez = current - step;
        if(rez >= min){
            obj[value] = rez.toFixed(fixed);
        }
    };
}]);
