angular.module('bfControllers.account', [])
.controller('AccountController', ['$resource', '$rootScope', '$scope', 'password', function($resource, $rootScope, $scope, password) {

    $scope.password = password;

    if(typeof PAYMENT_RELATIVE_TAX == 'undefined') PAYMENT_RELATIVE_TAX = 0;
    if(typeof PAYMENT_ABSOLUTE_TAX == 'undefined') PAYMENT_ABSOLUTE_TAX = 0;
    if(typeof PAYMENT_RELATIVE_TAX_ACH == 'undefined') PAYMENT_RELATIVE_TAX_ACH = 0;
    if(typeof PAYMENT_ABSOLUTE_TAX_ACH == 'undefined') PAYMENT_ABSOLUTE_TAX_ACH = 0;
    if(typeof USE_PLAID == 'undefined') USE_PLAID = 0;

    this.payment = {
        sum: '100.00',
        tax: PAYMENT_RELATIVE_TAX,
        subTax: PAYMENT_ABSOLUTE_TAX,
        sumWithTax: function(){
            return ( (Number(this.sum) + Number(this.subTax)) / (Number(1-(this.tax/100))) ).toFixed(2);
        },
    };

    this.paymentType = "1";
    this.payment_us = {
        sum: '100.00',
        tax: PAYMENT_RELATIVE_TAX,
        subTax: PAYMENT_ABSOLUTE_TAX,
        sumWithTax: function(){
            return ( (Number(this.sum) + Number(this.subTax)) / (Number(1-(this.tax/100))) ).toFixed(2);
        },
    };
    this.payment_us_ach = {
        sum: '100.00',
        tax: PAYMENT_RELATIVE_TAX_ACH,
        subTax: PAYMENT_ABSOLUTE_TAX_ACH,
        maxTaxValue: 5.00,
        sumWithTax: function(){
            allSum = ( Number(this.sum) / Number(1-(this.tax/100)) ).toFixed(2);
            if(allSum * (this.tax / 100) > Number(this.maxTaxValue)){
                return ( Number(this.sum) + Number(this.maxTaxValue) ).toFixed(2);
            }else{
                return allSum;
            }
        },
    };

    if(USE_PLAID == 1){
        this.paymentTypes = {
            1: "Add funds by a credit card",
            2: "Add funds by ACH"
        };
    }else{
        this.paymentTypes = {
            1: "Add funds by a credit card",
        };
    }


    var self = this;
    /**
     * Get list Messages
     * http://ec.term.biflorica:8080/api/messages/?direct=desc&start=0&sort=date&show%5B%5D=all&direct=desc&limit=127
     */
    this.getMessages = function(){
        $resource(API_LINK + '/:action',
            {action:'messages'},
            {get: {method:'GET'}}
        ).get(function(result) {
            self.listMessages = result.data.list;
            //console.log(self);
        });
    };
    this.getMessages();

    this.renewTimer = function(){
        self.timerCountSec--;
        if(self.timerCountSec <= 0){
            self.timerCountSec = TIMER_RENEW_PERIOD;
            self.getMessages();
        }
        //$('#timer_value').text(self.timerCountSec);
        setTimeout(self.renewTimer, 1000);
    };
    this.renewTimer();
   
    /**
     * Send Payment with Stripe
     */
    this.sendPayment = function () {
        console.log(self.payment);
    };

    this.changePaymentType = function(){
        console.log(self.paymentType);
    };

    $rootScope.showPasswordForm = function () {
        $scope.password.successMessage = false;
        $('.bf-password-dialog').modal('show');
    };

} ])

.filter('unsafe', function($sce) {
    return function(val) {
        return $sce.trustAsHtml(val);
    };
});