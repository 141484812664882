$.fn.fastEdit = function (url, itemId, paramName) {
    var self = $(this);
    (function () {
        self.span = $('<span/>').css({display:'table-cell', verticalAlign: 'middle', height:self.height(), width:self.width()}).html(self.html());
        self.manageDiv = $("<div class='manageDiv'/>");
        self.input = $("<input type='text' />").appendTo(self.manageDiv);
        self.html('').append(self.span).append(self.manageDiv.hide());

        self.span.click(function () {
            $(this).hide();
            self.manageDiv.show();
            self.input.val($(this).text()).focus();
        });

        self.input.keypress(function (key) {
            if(key.keyCode == 13){
                self.span.show().html(self.input.val());
                self.manageDiv.hide();
                var postData = {};
                postData['field'] = paramName;
                postData['value'] = self.input.val();
                postData['itemId'] = itemId;
                $.post(url, postData);
            }
        });

        self.input.blur(function () {
            setTimeout(function () {
                self.span.show();
                self.manageDiv.hide();
            }, 100);
        });
    })();
};
