angular.module('bfServices.price', [])

/**
 * Сервис  Прайслиста
 */
.service('price', [ '$http', '$resource', '$rootScope', function($http, $resource, $rootScope) {
    // Активный прайс с которым производится работа
    this.editedPrice = {
        farm: {
            items: [],
        },
        type: {
            items: [],
        },
        variety: {
            items: [],
        },
        size: {
            items: {},
        },
        new: true,
        selected_varieties: [],
    };

    this.sort = {
        types_id: 0,
        color: 0,
        name: '',
    }

    this.sortErrors = { }

    // Ошибки валидации заявки
    this.priceFormFieldsErrors = {};

    // Вспомогательн
    this.listSubmark = {};
    this.listCargo = {};

    this.availableVarieties = [];

    var self = this;

    /**
     * Инициализация прайса
     * @param price_id
     */
    this.set = function(price_id){
        //Очищаем ошибки на форме
        self.clearErrors();

        var new_price;
        if(typeof price_id == 'undefined' || price_id == 0 || price_id == -1){
            // http://ec.term.biflorica:8080/api/priceList/get/id/-1
            // Создание прайса
            url = API_LINK + '/priceList/get/id/-1';
            new_price = true;
        }else{
            // http://ec.term.biflorica:8080/api/priceList/get/id/1
            url = API_LINK + '/priceList/get/id/' + price_id;
            new_price = false;
        }

        $resource(
            url,
            {managed: $rootScope.manage.plantation},//{action:'new', 'request': request_id},
            {get: {method:'GET'}}
        ).get(function(result){
            self.editedPrice = result.data.item;
            self.editedPrice.id = price_id;
            self.editedPrice.new = new_price;
            self.editedPrice.selected_varieties = [];

            // Для формы создания прайса сбрасываем выбранные значения
            if(self.editedPrice.new){
                self.editedPrice.type.selected = 0;
                self.editedPrice.variety.selected = 0;
                self.editedPrice.variety.disable = true;
                self.editedPrice.prices.disable = true;
            }else{
                self.editedPrice.type.disable = false;
                self.editedPrice.variety.disable = false;
                self.editedPrice.prices.disable = false;
            }

            self.normallizeVarieties();

            if (!self.editedPrice.new) {
                // Обновляем доступные типы
                self.updateAvailableVarieties(true);
            }
            //console.log(self.editedPrice);
        });
    };
    this.setPrice = this.set;

    /**
     * Получение прайса
     * @returns {{}|*}
     */
    this.get = function(){
        return self.editedPrice;
    };
    this.getPrice = this.get;

    this.createSort = function() {
        console.log(self.sort)


        var sent_params = self.sort;
        var files_count = 0;
        // Проверка и перегрузка файлов
        var files_obj = $('#fine-uploader-gallery').fineUploader('getUploads');
        for(var id_file in files_obj){
            var file_obj = files_obj[id_file];
            if(file_obj.status == "upload successful"){
                sent_params['files['+file_obj.id+']'] = file_obj.uuid + '__' + file_obj.originalName;
                files_count++;
            }
        }

        url = API_LINK + '/sorts/create/';

        console.log('xx')


        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            console.log('yy')
            console.log(result)
            if( result.data.errors.length == 0) {
                self.clearErrors();
                location.reload();
            } else {
                self.sortErrors = result.data.fieldsErrors;
                console.log(self.sortErrors)
            }
        }, function(err){
            console.log(err);
        });
    }
    
    /**
     * Сохранение price
     * @param price
     */
    this.save = function() {
        var sent_params = this.getParamsFromEditedPrice(self.editedPrice);
        if(typeof self.editedPrice.id == 'undefined' || self.editedPrice.id == 0 || self.editedPrice.id == -1){
            // http://ec.term.biflorica:8080/api/priceList/create/
            url = API_LINK + '/priceList/create/';
        }else{
            // http://ec.term.biflorica:8080/api/priceList/update
            url = API_LINK + '/priceList/update/';
            sent_params['id'] = self.editedPrice.id;
        }

        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            if( result.errors.length == 0) {
                self.clearErrors();
                location.reload();
            } else {
                self.priceFormErrors = result.errors[0];
                self.priceFormFieldsErrors = result.fieldsErrors;
            }
        }, function(err){
            console.log(err);
        });
    };


    /**
     * Очистка
     * @returns {{}|*}
     */
    this.reset = function(){
        //self.setEmptyRequest();
        self.clearErrors();
        //self.requestFormDeletePreviusVar();
        return true;
    };
    /**
     * 
     * @param editedPrice
     * @returns {{}|*}
     */
    this.getParamsFromEditedPrice = function(editedPrice){
        //Формируем параметры на отправку
        var sent_params = {};

        /*
        if(typeof editedPrice.guid != 'undefined' && editedPrice.guid != '' && editedPrice.guid != 0 && editedPrice.guid != -1) {
            sent_params.guid = editedPrice.guid;
            sent_params.id = editedPrice.guid; // Требуется для изменения заявки из ордера
        }
        */
        //sent_params.farm = editedPrice.farm.selected;
        sent_params.type = editedPrice.type.selected;
        sent_params.variety = editedPrice.variety.selected;
        sent_params.varieties = editedPrice.selected_varieties;

        var size, price;
        var selectedSizes = [];

        if(typeof editedPrice.prices[self.getCurrentChoise()] != 'undefined') {
            for (var num_size in sizes) {
                size = sizes[num_size];
                price = 'price' + size;
                if (
                    typeof editedPrice.prices[self.getCurrentChoise()]['price' + size] != 'undefined'
                    && editedPrice.prices[self.getCurrentChoise()]['price' + size].checked == true
                ) {
                    sent_params['price' + size] = editedPrice.prices[self.getCurrentChoise()]['price' + size].value;
                    for (var num_box in boxes) {
                        box = boxes[num_box];
                        sent_params['count' + size + '_' + box] = editedPrice.countes[self.getCurrentChoise()]['count' + size + '_' + box].value;
                    }
                    selectedSizes.push(size);
                    //price40=0.03&count40=350&count40_qb=100
                }
            }
        }

        sent_params.selectedSizes = selectedSizes;
        sent_params.managed = $rootScope.manage.plantation;

        return sent_params;
    };

    /**
     * 
     * @returns {boolean}
     */
    this.showPricesInRequest = function(){
        return typeof self.editedRequest.variety != 'undefined'
            && typeof self.editedRequest.variety.selected != 'undefined'
            && self.editedRequest.variety.selected != '0';
    };

    /**
     *  Заголовок формы Прайса
     */
    this.formTitleCreate = function(){
        return self.editedPrice.id == -1;
    };

    /**
     * На форме изменена платнация
     * Нет такой возможности
     */
    this.changePlantationInRequest = function(){

    };

    /**
     * На форме изменен тип
     */
    this.changeType = function(){
        // Сбрасываем выбор сорта
        self.editedPrice.variety.selected = 0;
        self.editedPrice.variety.disable = false;
        self.editedPrice.prices.disable = true;
        self.editedPrice.selected_varieties = [];
        self.clearErrors();

        // Обновляем доступные типы
        self.updateAvailableVarieties();
    };

    this.updateAvailableVarieties = function(edit_form) {
        self.availableVarieties = [];
        var edit = edit_form || false;
        var tempItems = self.editedPrice.variety.items;
        for (var key in tempItems) {
            if (!tempItems.hasOwnProperty(key)) {
                continue;
            }

            var o = {'key': key, 'value': tempItems[key]};
            if (edit || self.checkTypeOfVariety(o.key)) {
                self.availableVarieties.push(o);
            }
        }
    };

    /**
     * На форме изменен сорт
     */
    this.changeSort = function(){
        // Отображаем поле цен
        self.editedPrice.prices.disable = false;
        self.clearErrors();

        if(self.editedPrice.selected_varieties.indexOf(self.editedPrice.variety.selected) === -1) {
            self.editedPrice.selected_varieties.push(self.editedPrice.variety.selected);
        }

        parts = self.editedPrice.variety.selected.split('_')

        url = API_LINK + '/priceList/getPrice/typeId/' + parts[0] + '/sortId/' + parts[1]

        $resource(
            url,
            {},
            {get: {method:'GET'}}
        ).get(function(result){
            self.editedPrice.prices = result.data.item.prices
            self.editedPrice.countes = result.data.item.countes
        });

        /*
        prices = self.editedRequest.prices[self.editedRequest.variety.selected];
        console.log(prices);
        for(price in prices){
            self.editedRequest[price] = prices[price].value;
            self.editedRequest[price].checked = prices[price].checked;
            self.editedRequest[price+'Count'] = prices[price].count;
        }
        */
    };

    /**
     * Возвращает ключ для текущего выбора Плантации_Типа_Сорта
     * Например 1_1_1
     */
    this.getCurrentChoise = function(){
        var id_str = null;
        if(typeof self.editedPrice.id == 'undefined' || self.editedPrice.id == 0 || self.editedPrice.id == -1) {
            id_str = self.editedPrice.variety.selected;
        }else{
            id_str = self.editedPrice.farm.selected + '_' + self.editedPrice.type.selected + '_' + self.editedPrice.variety.selected;
        }
        //console.log(id_str);
        return id_str;
    };

    /**
     * Приводит список сортов к нормальному виду (удаляет :ID )
     */
    this.normallizeVarieties = function(){
        for(var id in self.editedPrice.variety.items) {
            self.editedPrice.variety.items[id] = self.editedPrice.variety.items[id].split(':')[0]
        }
    };

    /**
     * Отображение сорта в списке только соответствующего типа
     */
    this.checkTypeOfVariety = function(sortid){
        if(
            typeof self.editedPrice.type.selected != 'undefined'
            && self.editedPrice.type.selected != ''
            && sortid.indexOf(self.editedPrice.type.selected + '_') == 0
        ){
            return true;
        }else{
            return false;
        }
    };

    this.incrementValue = function (obj, value, step, fixed) {
        current = parseFloat(obj[value]);
        step = parseFloat(step);
        var rez = current + step;
        obj[value] = rez.toFixed(fixed);
    };
    this.decrementValue = function (obj, value, step, fixed) {
        current = parseFloat(obj[value]);
        step = parseFloat(step);
        var rez = current - step;

        if(rez >= 0){
            obj[value] = rez.toFixed(fixed);
        }
    };
    this.incrementCount = function (obj, value, step) {
        var current = Number(obj[value]);
        var rez = current + Number(step);
        obj[value] = rez;
    };
    this.decrementCount = function (obj, value, step) {
        var current = Number(obj[value]);
        var rez = current - Number(step);
        if(rez >= 0){
            obj[value] = rez;
        } else {
            obj[value] = current;
        }
    };

    /**
     * Очищаем ошибки на форме
     */
    this.clearErrors = function(){
        self.pricetFormErrors = {};
        self.priceFormFieldsErrors = {};
    };

    /**
     *
     * @param index
     */
    this.removeSelectedVariety = function (index) {
        if (typeof self.editedPrice.selected_varieties[index] === 'undefined') {
            return false;
        }

        var variety_deleted = self.editedPrice.selected_varieties[index];

        delete self.editedPrice.selected_varieties.splice(index, 1);

        if (self.editedPrice.selected_varieties.length !== 0 && variety_deleted === self.editedPrice.variety.selected) {
            self.editedPrice.variety.selected = self.editedPrice.selected_varieties[0];
            self.updateAvailableVarieties();
        }

        // unset selected variety if all selected varieties have been removed
        if (self.editedPrice.selected_varieties.length === 0) {
            self.editedPrice.variety.selected = 0;
            self.editedPrice.variety.disable = false;
            self.editedPrice.prices.disable = true;
        }
    };
}]);
