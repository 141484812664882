$( document ).ready(function() {

    showTimeInTitle();

    $('#message-block').click(function(){
        $('#message-block-body').toggle();
    });

    
    $(".bf-datepicker").datepicker({
        weekHeader: "W",
        firstDay: 1,
        dayNamesMin: [ "S", "M", "T", "W", "T", "F", "S" ],
        numberOfMonths: 2,
        showButtonPanel: true,
        //weekHeader: "W",
        currentText: "Применить",
        buttonText: "Применить",
        closeText: "Отменить"
    });
    /*$(".bf-datepicker-one").datepicker({
        weekHeader: "W",
        firstDay: 1,
        dateFormat: "d M yy",
        dayNamesMin: [ "S", "M", "T", "W", "T", "F", "S" ],
        numberOfMonths: 1,
        showButtonPanel: true,
        "applyClass": "hide",
        //weekHeader: "W",
        currentText: "Применить",
        buttonText: "Применить",
        closeText: "Отменить"
    });*/

    formElements();

    $('#btn-filter-addition').click(function(){
        $('#content-filter-addition').toggle();
        if($('#btn-filter-addition').find('span.caret').hasClass('up')){
            $('#btn-filter-addition').find('span.caret').removeClass('up');
        }else{
            $('#btn-filter-addition').find('span.caret').addClass('up');
        }
    });

    /*$('.filters-content-list').jScrollPane({
        showArrows: true
    });
console.log('ok');
    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        console.log('ok2');
        $('.filters-content-list').jScrollPane({
            showArrows: true
        });
    });*/

    // Инициализация поля поиска по значениям фильтра
    $('.filter-search').keyup(function () {
        filterSearch(this);
    });
});

// Функция поиска по значения фильтра
function filterSearch(searchInput){
    contentPanel = $(searchInput).parent('div.filters-content-search').parent('div.filters-content');
    filterItems = contentPanel.children('.filters-content-list').find('.filters-content-row');
    filterItems.each(function(){
        itemText = $(this).find('.filters-content-list-item').find('label').text().toLowerCase();
        if(itemText.indexOf(searchInput.value.toLowerCase()) == -1){
            $(this).hide();
        }else{
            $(this).show();
        }
    });
}

/**
 * Активация элементов на формах
 */
function formElements(){
    $('.variable-dec').click(function(){
        current = parseFloat($(this).parent().find('input.variable').val());
        step = parseFloat($(this).data('step'));
        $(this).parent().find('input.variable').val(current - step);
    });
    $('.variable-inc').click(function(){
        current = parseFloat($(this).parent().find('input.variable').val());
        step = parseFloat($(this).data('step'));
        $(this).parent().find('input.variable').val(current + step);
    });
}
window.formElements = formElements;

/**
 * Вывод времени в зоне Заголовка
 */
function showTimeInTitle(){
    $('#title-date-time').html( ('0' + title_date_time.getHours()).slice(-2) + ":" + ('0' + title_date_time.getMinutes()).slice(-2) + ":" + ('0' + title_date_time.getSeconds()).slice(-2) );
    $('#title-begin-market-time').html(('0' + begin_market_time.getHours()).slice(-2) + ":" + ('0' + begin_market_time.getMinutes()).slice(-2) + ":" + ('0' + begin_market_time.getSeconds()).slice(-2));
    title_date_time.setTime(title_date_time.getTime() + 1000);
    begin_market_time.setTime(begin_market_time.getTime() - 1000);

    setTimeout(showTimeInTitle, 1000);
}
