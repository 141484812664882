angular.module('bfServices.messages', [])

.service('messages', ['$resource', function($resource) {

    this.data = {};
    this.unreadCount;

    var self = this;
    /**
     * Загрузка счётчика сообщений
     * http://ec.term.biflorica:8080/api/messages/unreadCount
     */

    this.set = function(){
        self.unreadCount = START_UNREADCOUNT_VALUE;
        /* BIF-4646 Убрать запросы к API
        $resource(API_LINK + '/messages/:action',
            {action:'unreadCountAll'},
            {get: {method:'GET'}}
        ).get(function(result) {
            if (typeof result.data !== 'undefined') {
                self.unreadCount = result.data.unreadCount;
            }
        });
        */
    };

    // Сразу загружаем
    this.set();

}]);
