angular.module('bfServices.handbook', [])

.service('handbook', ['$resource', '$rootScope', function($resource, $rootScope) {

    this.data = {
        farm: [],
        type: {},
        variety: {},
        size: {},
        color: {},
        langMP: {},
        trustedFarm: {},
    };

    var self = this;
    /**
     *
     */
    this.set = function(){
        // Загрузка HandBook
        $resource(API_LINK + '/:action',
            {action:'handbook', 'sections[]': ['farm', 'size', 'color', 'variety', 'type', 'importtype', 'sort', 'trademark', 'langMP', 'trustedFarm', 'lang'], 'language': CURRENT_USER_LANGUAGE},
            {get: {method:'GET'}}
        ).get(function(result) {
            // Set Handbook Data
            self.data = result.data;

            if(typeof $rootScope.filter_content != 'undefined' ){
                // Set Filter Contents
                $rootScope.filter_content.trusted_plantations = result.data.trustedFarm;
                $rootScope.filter_content.plantations = self.sortResaltABC(result.data.farm);
                $rootScope.filter_content.types = self.sortResaltABC(result.data.type);
                $rootScope.filter_content.import_types = self.sortResaltABC(result.data.importtype);
                $rootScope.filter_content.sorts = self.sortResaltABC(result.data.variety);
                //$rootScope.filter_content.sizes = self.sortResaltABC(result.data.size);
                $rootScope.filter_content.sizes = self.prepareResultABC(result.data.size);
                $rootScope.filter_content.colors = self.prepareResultABC(result.data.color);
            }
            //console.log(self);
        });
    };

    // Сразу загружаем Хандбук
    this.set();

    this.sortResaltABC = function(input_date){
        var listNew = [];
        for(var i in input_date){
            listNew.push({ id: i, name: input_date[i]});
        }
        return listNew.sort(self.compareObjects);
    };
    this.prepareResultABC = function(input_date){
        var listNew = [];
        for(var i in input_date){
            listNew.push({ id: i, name: input_date[i]});
        }
        return listNew;
    };
    this.compareObjects = function(a, b) {
        if (self.strip(a.name) > self.strip(b.name)) return 1;
        if (self.strip(a.name) < self.strip(b.name)) return -1;
        return 0;
    };
    this.strip = function(str) {
        return str.replace(/<.*?>/g, '');
    };
}]);
