angular.module('bfControllers.mover', [])
    .controller('RequestsMoverController',
    ['$scope', '$rootScope', '$resource', '$cookies', '$http', 'expectedVolume', 'helpers', 'request', 'handbook',
        function ($scope, $rootScope, $resource, $cookies, $http, expectedVolume, helpers, request, handbook) {

            this.request = request;
            this.request.clone_request = true;
            this.inRequestsList = {};    // Список id заявок которые необходимо скопировать/переместить
            this.toOrderId = 0;         // id Ордера в который будем копировать/перемещать

            $rootScope.handbook = handbook;

            var self = this;

            $scope.pagination = new helpers.pagination(ITEMS_SHOW_PER_PAGE);

            $scope.renderFiltersVal = function (filter) {
                var filtersVal = {};
                filter.each(function () {
                    filtersVal[this.id] = this.value;
                });
                return filtersVal;
            };

            this.initListRequests = function () {
                self.inRequestsList = glob_requests;
                self.toOrderId = to_order_id;
                self.request.toOrderId = to_order_id;
                self.request.formType = 'copy';
                $scope.request = self.request;

                self.inRequestsList.forEach(function(request_id, num_request, in_array){
                    console.log(num_request);
                    self.request.set(request_id, self.toOrderId, undefined, undefined, undefined, num_request);
                });
            };
            this.initListRequests();

            /**
             * Редактировать заявку в списке копируемых
             * @param request_row
             */
            this.requestEdit = function(request_num) {

                self.request.editedRequest = self.request.listEditedRequests[request_num];
                self.request.editedRequest.current_request_num = request_num;
                self.request.formType = 'copy';
                $scope.request = self.request;
                console.log(self.request.listEditedRequests[request_num].guid);
                //console.log(self.request.listEditedRequests[request_num].farm.items[self.request.listEditedRequests[request_num].farm.selected].name);
                $('.bf-create-request-dialog').modal('show');
                //self.request.clone_request = true;
                /*
                self.request.setRequest(request_row.guid);
                self.request.clone_request = true;
                */
            };

            /**
             * Копировать заявку
             * @param request_row
             */
            this.requestCopy = function (request_row) {
                // Сейчас вызываем напрямую - MoverCtrl.request.save(request_row.guid, request_row.from, request_num)

            };

            /**
             * Убрать заявку из списка копируемых
             * @param request_row
             */
            this.requestRemove = function (request_num) {
                self.request.listEditedRequests[request_num].copied_finished = true;
            };

            /**
             * Копировать все заявки по очереди в автомате
             */
            this.startCopyAllRequests = function () {
                for (var request_num in self.request.listEditedRequests) {
                    var request_row = self.request.listEditedRequests[request_num];
                    console.log(request_row);
                    self.request.listEditedRequests[request_num].copied = true;
                    if(!request_row.copied_finished) {
                        if(request_row.blocked == 0){
                            self.request.save(request_row.guid, request_row.from, request_num);
                        }else{
                            self.request.listEditedRequests[request_num].copied_finished = true;
                        }
                    }
                };
            };
            $rootScope.startCopyAllRequests = this.startCopyAllRequests;
        }]
    );