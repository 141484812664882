angular.module('bfControllers.columns', [])
.controller('ColumnsController', ['$resource', '$scope', '$cookies', '$rootScope', function($resource, $scope, $cookies, $rootScope) {

    $scope.column_choise_model = [];
    $scope.column_choise_data = [];
    $scope.column_choise_settings = {
        dynamicTitle: false,
        showCheckAll: false,
        showUncheckAll: false,
        //styleActive: true,
        checkBoxes: true,
        //smartButtonTextProvider: function(selectionArray) { return selectionArray.length + 2; }
        /*smartButtonTextProvider(selectionArray) {
            return document.getElementById('columnList').getAttribute('default_text') + ' ...';//$scope.column_choise_model.length + " " + document.getElementById('columnList').getAttribute('button_text');
        },*/
        //smartButtonMaxItems: 1,
        /*
        smartButtonTextConverter: function(itemText, originalItem) {
            //return itemText; //document.getElementById('columnList').getAttribute('button_text');
            return $scope.column_choise_model.length + " " + document.getElementById('columnList').getAttribute('button_text');
        }*/
    };
    $scope.column_choise_translates = [];
    $scope.column_page = document.getElementById('columnList').getAttribute('page');
    $scope.column_choise_customTexts = {
        buttonDefaultText: document.getElementById('columnList').getAttribute('default_text') + ' ...',
        //dynamicButtonTextSuffix: document.getElementById('columnList').getAttribute('button_text'),
    };
    $scope.column_choise_events = {
        onItemSelect: function(){self.setColumns();},
        onItemDeselect: function(){self.setColumns();}
    };
    $rootScope.toggleItm = function(colNum){
        var columnsValueFromCookies = self.getColumnsFromCoockie();
        if (columnsValueFromCookies.length <= 0) {
            return false;
        }
        
        var columns_number = [];
        for(var i in columnsValueFromCookies){
           columns_number.push(columnsValueFromCookies[i].id);
        }
        return (columns_number.indexOf(colNum) >= 0);
    };
    var self = this;

    this.getColumnsFromCoockie = function(){
        //check & update columns setting for the first time
        let defaultOptions = {
            "index" : [{"id":15},{"id":17}],
            "orders" : [{"id":2},{"id":28}],
            "deals" : [{"id":23}]
        };

        var check = $cookies.get('checkcolumns');
        var renew = false;
        
        if(typeof check == 'undefined'){
            renew = true
            check = {}
        } else {
            check = JSON.parse(check);
        };

        renew || typeof check[$scope.column_page] == 'undefined' ?
         renewColumns('columns' + $scope.column_page, defaultOptions[$scope.column_page], check) : 
         null;
        //work normal
        var columnsValueFromCookies = $cookies.get('columns'+$scope.column_page);

        if(typeof columnsValueFromCookies != 'undefined'){
            let result;
            try {
                result = JSON.parse(columnsValueFromCookies);
            } catch (e) {
                return [];
            }
            return result;
        } else {
            return [];
        };
    };

    let renewColumns = function(page, objs, check) {
        var arrayString = $cookies.get(page);
        if(typeof arrayString != 'undefined') {
            try {
                var array = JSON.parse(arrayString);
            } catch (e) {
                console.log(e);
                return; 
            }
            let isObjectInArray;
            for(var objectToAdd of objs) {

                isObjectInArray = array.some((obj) => obj.id === objectToAdd.id);
                if (!isObjectInArray) {
                    array.push(objectToAdd);
                }
            };
            check[$scope.column_page] = 'ok'
            var str = JSON.stringify(check)
            $cookies.put('checkcolumns', str );
            $cookies.put(page, JSON.stringify(array));
            return;
        } else {
            check[$scope.column_page] = 'ok'
            var str = JSON.stringify(check)
            $cookies.put('checkcolumns', str );
            $cookies.put(page, JSON.stringify(objs));
            return;
        }
    }

    /**
     * Get list Columns
     * http://ec.term.biflorica:8080/api/marketPlace
     */
    this.getColumns = function(){
        $resource(API_LINK + '/marketPlace/:action/page/:page',
            {action:'columns',page:$scope.column_page},
            {get: {method:'GET'}}
        ).get(function(result) {
            $scope.column_choise_model = self.getColumnsFromCoockie();
            $scope.column_choise_data = result.data.list;
        });
    };
    this.getColumns();

    /**
     * Hide/Show Columns
     */
    this.setColumns = function(){
        $cookies.put('columns'+$scope.column_page, JSON.stringify($scope.column_choise_model), {path: self.path_alias});
        $scope.column_choise_model = self.getColumnsFromCoockie();
    };
        
} ]).filter('unsafe', function($sce) {
    return function(val) {
        return $sce.trustAsHtml(val);
    };
});