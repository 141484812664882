angular.module('bfServices.helpers', [])
.factory('helpers', function() {

    function pagination (itemPerPage, pageCount) {
        var self = this;

        self.start = 0;
        self.count = 0;
        self.total = 0;
        self.currPage = 1;
        self.pager = [];
        self.itemsPerPage = itemPerPage;
        self.limitPages = pageCount;
        self.renewPage = function () {};

        self.update = function () {

        };

        self.recountPages = function (count, total) {
            self.count = count;
            self.total = total;
            self.pager = [];

            self.firstPage = self.getFirstPage();
            self.lastPage = self.getLastPage();
            for (var i = self.firstPage; i <= self.lastPage; i++) {
                self.pager.push(Math.round(i));
            }
        };

        self.setPage = function (needPage) {
            self.currPage = needPage;
            self.start = (self.currPage - 1) * self.itemsPerPage;
            self.renewPage();
        };

        self.getFirstPage = function () {
            var firstPage = self.currPage - (self.limitPages - 1) / 2;
            return firstPage > 1 ? firstPage : 1;
        };
        self.getLastPage = function () {
            var totalPages = Math.ceil(self.total / self.itemsPerPage);
            (self.currPage < totalPages || self.firstPage <= 1) || self.firstPage--;
            return (0 < self.limitPages && self.limitPages < totalPages) ? self.limitPages + (self.firstPage - 1) : totalPages;
        };
    }
    function daterange (itemPerPage, pageCount) {
        var self = this;

        self.startDate = typeof daterangeStartDate !== 'undefined' ? daterangeStartDate : '';
        self.endDate = typeof daterangeEndDate !== 'undefined' ? daterangeEndDate : '';

        self.renewPage = function () {};

        self.renew = function(start, end){
            self.startDate = start;
            self.endDate = end;

            self.renewPage()
        };
    }
    function comments (comentForm, date) {
        var self = this;
        self.list = [];
        self.count = 0;
        self.date = date;
        self.openForm = function () {
            comentForm.modal('show');
            self.update(self.date);
        };
        self.update = function (date) {};
        self.add = function () {};
    }
    return {
        pagination: pagination,
        daterange: daterange,
        comments: comments
    };
});
