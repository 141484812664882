angular.module('bfControllers.orders', [])
.controller('OrdersController', ['$scope', '$rootScope', '$http', '$resource', '$cookies', 'order', '$sce', 'commet', 'utils', 'handbook', 'helpers',
    function ($scope, $rootScope, $http, $resource, $cookies, order, $sce, commet, utils, handbook, helpers) {

    $scope.order = order;
    $scope.orders = {};
    $scope.handbook = handbook;

    $rootScope.create = order.setOrder;

    $scope.selected_orders = {
        ids: []
    };

    $scope.pagination = new helpers.pagination(ITEMS_SHOW_PER_PAGE,41);

    $rootScope.startDate = '';
    $rootScope.endDate = '';
    $scope.daterange = new helpers.daterange();
    $rootScope.sort = 'created_at';
    $rootScope.direct = 'desc';
    $rootScope.show = 'all';
    this.path_alias = '/marketPlaceNew/default/orders';

    $rootScope.approve_with_deal = false;

    $rootScope.current_user_role = CURRENT_USER_ROLE;
    $rootScope.role_buyer = ROLE_BUYER;
    $rootScope.role_seller = ROLE_SELLER;

    $rootScope.trustAsHtml = $sce.trustAsHtml;

    var self = this;

    // Get list orders
    // http://ec.term.biflorica:8080/api/orders/?startDate=01.08.2015&endDate=19.10.2015&start=NaN&sort=created_at&show%5B%5D=all&direct=desc&limit=127&contentType=json

    /**
     * Возвращает IDs заказов на текущей странице грида
     */
    this.getGridOrderIds = function() {
        return utils.getGridEntityIds($scope.orders);
    };

    this.getOrders = function() {
        commet.untrackOrders(self.getGridOrderIds());

        $rootScope.querySent = true;
        $resource(API_LINK + '/:action',
            {
                action:'orders',
                'show[]': $rootScope.show,
                startDate: $scope.daterange.startDate,
                endDate: $scope.daterange.endDate,
                sort: $rootScope.sort,
                limit: $scope.pagination.itemsPerPage,
                page: $scope.pagination.currPage,
                direct: $rootScope.direct,
                purpose: $rootScope.order_purpose
            },
            {get: {method:'GET'}}
        ).get(function(result){
            $scope.orders = result.data.list;
            var count = parseInt(result.data.count_items);
            var total = parseInt(result.data.total_items);
            $scope.pagination.recountPages(count, total);

            self.buttonsActiveOff();
            $scope.renewButtonsStatus();
            $rootScope.querySent = false;

            commet.trackOrders(self.getGridOrderIds());
        });
    };
    $rootScope.renewOrders = $scope.pagination.renewPage = this.getOrders;

    /**
     * Действия, которые необходимо выполнить после восстановления соединения с сервером ноды
     */
    $rootScope.$on('afterReconnect', function(event, data) {
        $rootScope.renewOrders();
    });

    /**
     * Изменение состояния заказа в гриде
     *
     * @param data
     */
    $rootScope.changeOrderInGrid = function (data) {
        if (utils.changeEntityInGrid(data, $scope.orders)) {
            $scope.$apply();
        }
    };

    $scope.renewTimer = function(){
        if(!$rootScope.pause) $rootScope.timerCountSec--;
        if($rootScope.timerCountSec <= 0){
            $rootScope.timerCountSec = TIMER_RENEW_PERIOD;
            $rootScope.renewOrders();
        }
        $('#timer_value').text($rootScope.timerCountSec);
        setTimeout($scope.renewTimer, 1000);
    };

    $scope.orderFormTitleCreate = function(){
        return typeof $scope.order.editedOrder.guid == 'undefined' || $scope.order.editedOrder.guid == '-1';
    };

    /**
     * Move orders from market
     */
    $rootScope.moveFromMarket = function () {
        $rootScope.querySent = true;

        url = API_LINK + '/orders/moveFromMarketPlace/';

        sent_params = {};
        sent_params['checked[]'] = $scope.selected_orders.ids;
        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            if( result.errors.length == 0) {
                //location.reload();

            } else {
                //$rootScope.requestFormErrors = result.errors[0];
                //$rootScope.requestFormFieldsErrors = result.fieldsErrors;
            }
            $rootScope.timerCountSec = 0;
            self.uncheckAll();
            console.log(result);
        }, function(err){
            console.log(err);
        });
    };

    /**
     * Approve before delete orders
     */
    $rootScope.approveBeforeDeleteOrders = function () {
        var order, selected_order, selected_order_id;
        var approve_with_deal = false;
        for(selected_order in $scope.selected_orders.ids){
            selected_order_id = $scope.selected_orders.ids[selected_order];
            for(order in $scope.orders){
                if($scope.orders[order].guid == selected_order_id && parseFloat($scope.orders[order].deals) > 0){
                    approve_with_deal = true;
                    break;
                }
                //$scope.selected_orders.ids[order] = $scope.orders[order].guid;
            }
        }
        $rootScope.approve_with_deal = approve_with_deal;
        $('.bf-approve-dialog').modal('show');
    };

    /**
     * Open comments for purchase request manager popup
     */
    $rootScope.openPurchaseRequestComments = function (id) {
        $scope.order.setOrder(id);

        $rootScope.loadPurchaseRequestComments(id);

        $('.bf-pr-dialog').modal('show');
    };

    /**
     * Load purchase requests comments
     */
    $rootScope.loadPurchaseRequestComments = function (id) {
        $resource(API_LINK + '/purchaseRequests/:action',
            {action:'getComments', id: id},
            {get: {method:'GET'}}
        ).get(function(result){
            $scope.order.comments = result.data.comments;
        });
    };

    /**
     * Delete orders
     */
    $rootScope.deleteOrders = function () {
        $rootScope.querySent = true;
        url = API_LINK + '/orders/delete/';

        sent_params = {};
        sent_params['ids[]'] = $scope.selected_orders.ids;
        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            if( result.status == 200) {
                //location.reload();
            } else {
                //$rootScope.requestFormErrors = result.errors[0];
                //$rootScope.requestFormFieldsErrors = result.fieldsErrors;
            }
            $rootScope.timerCountSec = 0;
            self.uncheckAll();
        }, function(err){
            console.log(err);
        });
    };

    /**
     * Open orders, show list requests
     */
    $rootScope.openOrders = function () {
        sent_params = {};
        sent_params['item[]'] = $scope.selected_orders.ids;
        window.location.replace('/marketPlaceNew/default/requests/?' + $.param(sent_params));
    };

    /**
     * Show reports for selected orders
     */
    $rootScope.reportOrders = function () {
        sent_params = {};
        sent_params['orders[]'] = $scope.selected_orders.ids;
        window.location.replace('/api/requests/GetCSVList/?'+$.param(sent_params));
    };

    /**
     * Отметить все чекбоксы в списке
     */
    $scope.checkAll = function () {
        $scope.selected_orders.ids = [];
        if($('#list-orders-all').is(':checked')){
            for (var order in $scope.orders) {
                $scope.selected_orders.ids[order] = $scope.orders[order].guid;
                console.log($scope.orders[order].guid);
            }
            $('.order-checkbox').attr('checked', true);
        }
        //console.log($scope.selected_orders);
    };

    /**
     * Снять Отметки всех чекбоксов в списке
     */
    this.uncheckAll = function () {
        $scope.selected_orders.ids = [];
        $('#list-orders-all').attr('checked',false);
    };
    $scope.uncheckAll = this.uncheckAll;

    /**
     * Обновить статус кнопок груповых операций
     */
    $scope.renewButtonsStatus = function () {
        if($scope.selected_orders.ids.length == 0){
            // Disable buttons
            $('.group-orders-btn').attr('disabled', 'disabled');
        }else{
            // Enable buttons
            $('.group-orders-btn').removeAttr('disabled');;

        }
        //console.log($scope.selected_orders);
    };

    this.setPurpose = function (purpose) {
        $rootScope.setOrderPurpose(purpose);
        $rootScope.renewOrders();
    };
    $rootScope.setPurpose = this.setPurpose;

    this.buttonsActiveOff = function(){
        $('.btn-reset-if-renew').blur();
    };

    // Выбранные значения сортировки
    var sortValueFromCookies = $cookies.get('order-sort');
    if(typeof sortValueFromCookies != 'undefined'){
        $rootScope.sort = sortValueFromCookies;
    }else{
        $rootScope.sort = 'created_at';
        $cookies.put('order-sort', 'created_at', {path: self.path_alias});
    }
    var directValueFromCookies = $cookies.get('order-direct');
    if(typeof directValueFromCookies != 'undefined'){
        $rootScope.direct = directValueFromCookies;
    }else{
        $rootScope.direct = 'desc';
        $cookies.put('order-direct', 'desc', {path: self.path_alias});
    }
    this.setSort = function(sort_column){
        console.log(self.path_alias);
        if($rootScope.sort == sort_column){
            // Изменяем направление сортировки
            if($rootScope.direct == 'asc'){
                $rootScope.direct = 'desc';
                $cookies.put('order-direct', 'desc', {path: self.path_alias});
            }else{
                $rootScope.direct = 'asc';
                $cookies.put('order-direct', 'asc', {path: self.path_alias});
            }
        }else{
            // Изменяем сортировку
            $cookies.put('order-sort', sort_column, {path: self.path_alias});
            $rootScope.sort = sort_column;
        }
        $rootScope.timerCountSec = 0;
    };
    $rootScope.setSort = this.setSort;

    $scope.renewTimer();
    $rootScope.renewOrders();

    $scope.classSort = function(sort_column){
        if($rootScope.sort == sort_column){
            // Изменяем направление сортировки
            if($rootScope.direct == 'asc'){
                return 'sorted sorted-up';
            }else{
                return 'sorted sorted-down';
            }
        }
    };

    /**
     * Устанавливает фильт по отображаемым ордерам (активные истекшие)
     */
    this.setSelect = function(show){
        $rootScope.show = show;
        $rootScope.timerCountSec = 0;
    };
    $rootScope.setSelect = this.setSelect;

    $scope.showOrder = function(url){
        window.location.replace(url);
    };
} ])

.filter('unsafe', function($sce) {
    return function(val) {
        return $sce.trustAsHtml(val);
    };
});