import da from "../../../../../../controlpanel_files/bower_components/moment/src/locale/da";
import {forEach} from "../../../../../../controlpanel_files/bower_components/mocha/mocha";

angular.module('bfServices.imports', [])

.service('imports', ['$http', '$resource', 'uiGridConstants', function($http, $resource, uiGridConstants) {

    this.bfData = [

    ];

    this.boxes = [];
    this.varieties = [];
    this.boxes_reg = "(hb|qb|fb|std|zim|jum)";
    this.p = {
        35: '0.00',
        40: '0.00',
        50: '0.00',
        60: '0.00',
        70: '0.00',
        80: '0.00',
        90: '0.00',
        100: '0.00',
        '100p': '0.00'
    };
    this.promo = 0;
    this.querySent = false;

    this.successfull_loaded = 0;

    var self = this;

    /**
     * Загрузка справочник типов ящиков и справочник сортов
     * /api/handbook/varieties
     */
    this.set = function(){
        $resource(API_LINK + '/handbook/:action',
            {action:'varieties'},
            {get: {method:'GET'}}
        ).get(function(result) {
            console.log(result.data.varieties);
            if(typeof result.data !== 'undefined') {
                for (var id in result.data.varieties) {
                    var variety = result.data.varieties[id];

                    self.varieties.push(variety);
                }
            }
        });

        $resource(API_LINK + '/handbook/:action',
            {action:'boxes'},
            {get: {method:'GET'}}
        ).get(function(result) {
            console.log(result.data.boxes);
            if(typeof result.data !== 'undefined') {
                for (var id in result.data.boxes) {
                    var box = result.data.boxes[id];

                    self.boxes.push(box);
                }
            }
        });
    };

    // Сразу загружаем
    this.set();

    /**
     * Массив входных стандартных строк раскладывем в Грид
     * @param input
     */
    this.inputData = function (input){
        self.bfData = [];
        input.forEach(function (input_elem, index){
            console.log(input_elem);
            if(typeof input_elem != 'undefined' && input_elem != '' ){

                var pre_input_elem = self.preProcessing(input_elem);
                var new_element = self.parse(pre_input_elem);

                new_element.input = input_elem.replace(/\t/gi, ' '); // Заменить все табуляции на пробелы
                new_element.num = index;
                new_element.number = index+1;
                self.bfData.push(new_element);

                //$scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
            }
        });
    };

    /**
     * Из грида формируем тектовое представление
     * @param input
     */
    this.outputData = function (){
        var output_str = '';

        self.bfData.forEach(function (element, index){
            output_str = output_str + element.input +  "\r\n";
        });
        console.log(output_str);

        return output_str;
    };

    /**
     * Файл Excel раскладываем в массив входных стандартных строк
     * @param input - файл Excel
     */
    this.inputExcel = function (input){

        console.log(input);

        var result = [];

        input.forEach(function(item) {
            var new_item = '';
            console.log(item);
            var iterator = 0;
            for (var key in item) {
                console.log(key, ':', item[key]);
                if(iterator != 0){
                    new_item = new_item + ' ';
                }
                new_item = new_item + item[key];
                iterator = iterator + 1;
            }
            result.push(new_item);
        });

        /*
        $.ajax({
            type: 'POST',
            url: API_LINK + '/importRequests/inputExcel',
            data: formData,
            cache: false,
            dataType: 'json',
            processData: false,
            contentType: false,
            //headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            success: function( respond, textStatus, jqXHR ){
                console.log(respond);
                console.log(textStatus);
            }
        });
        */
        return result;
    };

    this.addCommentData = function (num, comment){
        self.bfData.forEach(function (element, index){
            if(element.num == num){
                self.bfData[index].comment = comment;
                return;
            }
        });
    };

    this.removeData = function (num){
        self.bfData.forEach(function (element, index){
            console.log(element.num);
            if(element.num == num){
                console.log(num);
                self.bfData.splice(index, 1);
                return;
            }
        });
    };

    this.revalidateRow = function (rowEntity){
        console.log(rowEntity);
        var input_elem = rowEntity.input;
        if(typeof input_elem != 'undefined' && input_elem != '' ){

            var pre_input_elem = self.preProcessing(input_elem);
            var new_element = self.parse(pre_input_elem);

            //new_element.input = input_elem;
            rowEntity.num = rowEntity.num;
            rowEntity.boxes = new_element.boxes;
            rowEntity.boxes_type = new_element.boxes_type;
            rowEntity.variety = new_element.variety;
            rowEntity.sizes = new_element.sizes;
            rowEntity.proices = new_element.prices;
            rowEntity.box_id = new_element.box_id;
            rowEntity.type_id = new_element.type_id;
            rowEntity.sort_id = new_element.sort_id;
            rowEntity.comment = new_element.comment;
            //self.bfData.push(new_element);
        }
    }

    /**
     * Загрузка наличия
     * /api/importRequests/upload
     */
    this.upload = function(){
        var sent_params = this.getParamsFromBfData(self.bfData);
        console.log(self.bfData);
        self.querySent = true;
        $http({
            method: 'POST',
            url: API_LINK + '/importRequests/upload',
            data: $.param({
                inputs: JSON.stringify(sent_params),
                seller_id: $('#seller_id').val(),
                p: JSON.stringify(self.p),
                promo: self.promo ? 1 : 0
            }),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(function(result) {
            self.querySent = false;
            result = result.data;
            console.log(result);

            var success_lines = result.data.response.success_lines;
            self.successfull_loaded = success_lines.length;
            var error_lines = result.data.response.error_lines;

            for (var num in success_lines) {
                console.log(num); console.log(success_lines[num]);
                self.removeData(num);
            }

            for (var num in error_lines) {
                console.log(num); console.log(error_lines[num]);
                self.addCommentData(num, error_lines[num]);
            }

            /*
            self.sentForm = false;
            if (!result.errors || result.errors.length == 0) {
                //location.reload();
                $('.bf-create-request-dialog').modal('hide');
                self.setEmptyRequest(num_request);
                self.listEditedRequests[num_request].hideForm = true;
                self.listEditedRequests[num_request].copied = false;
                self.listEditedRequests[num_request].copied_finished = true;
                self.clearErrors(self.listEditedRequests[num_request]);
                self.requestFormDeletePreviusVar();
                $rootScope.timerCountSec = 0;

            } else {
                self.listEditedRequests[num_request].requestFormErrors = result.errors[0];
                self.listEditedRequests[num_request].requestFormFieldsErrors = result.fieldsErrors;
            }*/
            //self.bfData = [];

        }, function(err) {
            self.querySent = false;
            //self.sentForm = false;
            console.log(err);
        });
    };

    /**
     * Сохранение набора как Excel через сервер
     * /api/importRequests/saveAsExcel
     */
    this.saveAsExcelThroughServer = function(){
        //var sent_params = this.getParamsFromBfData(self.bfData);
        var sent_params = [];

        self.bfData.forEach(function (element, index){
            var new_element = {
                "number": element.number,
                "input": element.input,
                "boxes": element.boxes,
                "boxes_type": element.boxes_type,
                "variety": element.variety,
                "sizes": element.sizes,
                "prices": element.prices,
                "box_id": element.box_id,
                "type_id": element.type_id,
                "sort_id": element.sort_id,
            };
            sent_params.push(new_element);
        });

        console.log(self.bfData);
        self.querySent = true;
        $.ajax({
            method: 'POST',
            url: API_LINK + '/importRequests/saveAsExcel',
            data: $.param({
                inputs: JSON.stringify(sent_params),
                seller_id: $('#seller_id').val(),
            }),
            dataType: 'binary',
            xhrFields: {
                'responseType': 'blob'
            },
            //headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            success: function(data, status, xhr) {
                self.querySent = false;
                //console.log(data);

                var dummy = document.createElement('a');
                dummy.href = URL.createObjectURL(data);
                dummy.download = 'report.xlsx';
                dummy.click();
            }
        });
    }

    /**
     * Сохранение набора как Excel файл
     */
    this.saveAsExcel = function(){

        var rows = [];

        self.bfData.forEach(function (element, index){
            var new_element = [
                element.input
                /*"boxes": element.boxes,
                "boxes_type": element.boxes_type,
                "variety": element.variety,
                "sizes": element.sizes,
                "prices": element.prices,
                "box_id": element.box_id,
                "type_id": element.type_id,
                "sort_id": element.sort_id,*/
            ];
            rows.push(new_element);
        });
        console.log(rows);

        const worksheet = XLSX.utils.aoa_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");
        XLSX.writeFile(workbook, "report.xlsx", { compression: true });
    }

    /**
     * Сохранение шаблона Excel файла через сервер
     * /api/importRequests/getTemplate
     */
    this.getTemplateThroughServer = function(){
        self.querySent = true;
        $.ajax({
            method: 'POST',
            url: API_LINK + '/importRequests/getTemplate',
            data: $.param({}),
            dataType: 'binary',
            xhrFields: {
                'responseType': 'blob'
            },
            success: function(data, status, xhr) {
                self.querySent = false;
                //console.log(data);

                var dummy = document.createElement('a');
                dummy.href = URL.createObjectURL(data);
                dummy.download = 'template.xlsx';
                dummy.click();
            }
        });
    }

    /**
     * Сохранение примерного шаблона Excel файла
     */
    this.getTemplate = function(){
        var rows = [
            ["5hb", "Alba", "50cm"],
            ["5hb", "Alba", "50-70"],
            ["5hb", "Alba", "60", "0.50"]
        ];

        console.log(rows);

        const worksheet = XLSX.utils.aoa_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");
        XLSX.writeFile(workbook, "template.xlsx", { compression: true });
    }

    /**
     * Предварительная обработка входной строки / строки предложения
     *
     * @param line
     * @returns {*}
     */
    this.preProcessing = function(line){

        var new_line;
        new_line = line;

        new_line = new_line.toLocaleLowerCase();

        // Убираем пробелы и комментарии
        new_line.trim();
        var tmp = new_line.split('#');
        new_line = tmp[0].trim();

        // Убрать символы
        new_line = new_line.replaceAll('*', '');
        // Заменить символы
        new_line = new_line.replaceAll(',', '.');
        // Пробелы к одному
        new_line = new_line.replaceAll(/\s+/g, ' ');

        // фиксим пробелы вокруг разделителей / и -
        //fixes = ['\\ ', ' \\', '\\', '/', ' -', '- '];
        new_line = new_line.replaceAll(/(\s*)\/(\s*)/g, '/'); // '/'
        new_line = new_line.replaceAll(/(\s*)\-(\s*)/g, '/'); // '-',' -','- '

        // Если есть знаки $ то убираем все пробелы после них
        new_line = new_line.replaceAll(/(\$)(\s*)([0-9])/g, '$1$3');

        // Убираем cm из размеров
        new_line = new_line.replaceAll(/([0-9])(cm|см)/g, '$1');

        // Если задан пакинг, то для цифр, которые идут в начале строки без маркеров пакинга добавляем пакинг
        // preg_match('/^[\d.]+\s/ism', $line, $matches); TXTImportHelper::_processLine - 124

        // Если определяются ящики то убираем все пробелы между цифрами и типом ящика
        //var reg1 = new RegExp("([0-9])(\s+)"+self.boxes_reg, "g");
        var reg1 = new RegExp(/([0-9\.]*)(\s+)(hb|qb|fb|std|zim|jum)/, "g");
        new_line = new_line.replace(reg1, '$1$3')

        // Переносим ящики в начало строки TXTImportHelper::_processLine - 135
        // preg_match('/[\d.]+(hb|qb|fb|std|zim|jum)/ism', $line, $matches);
        var reg2 = new RegExp("([0-9\.]*)"+self.boxes_reg+"( )", "g");
        var tmp = reg2.exec(new_line);
        // tmp[0] = "22.5fb "
        // tmp[1] = "22.5"
        // tmp[2] = "fb"
        // tmp[3] = " "

        var box_quantity_arr = tmp[1].split('.');
        if(tmp[2] == "fb"){
            if(typeof box_quantity_arr[1] == 'undefined'){
                tmp[0] = (parseInt(box_quantity_arr[0]) * 2)  + 'hb ';
            }else{
                if (parseInt(box_quantity_arr[1]) == 5) {
                    // Если tmp[1] - c остатком 0.5 или 0.0 то tmp[2] установить hb а tmp[1] умножить на 2 + 1
                    tmp[0] = (parseInt(box_quantity_arr[0]) * 2 + 1) + 'hb ';
                } else {
                    if (parseInt(box_quantity_arr[1]) == 25) {
                        // Если tmp[1] - c остатком 0.25 или 0.0 то tmp[2] установить qb а tmp[1] умножить на 4 + 1
                        tmp[0] = (parseInt(box_quantity_arr[0]) * 4 + 1) + 'qb ';
                    } else {
                        if (parseInt(box_quantity_arr[1]) == 75) {
                            // Если tmp[1] - c остатком 0.25 или 0.0 то tmp[2] установить qb а tmp[1] умножить на 4 + 3
                            tmp[0] = (parseInt(box_quantity_arr[0]) * 4 + 3) + 'qb ';
                        } else {
                            tmp[0] = (parseInt(box_quantity_arr[0]) * 2)  + 'hb ';
                        }
                    }
                }
            }
        }else{
            // Если другой тип ящика и дробное количество то оставляем только целое
            tmp[0] = parseInt(box_quantity_arr[0])  + tmp[2] + ' ';
        }


        new_line = tmp[0] + new_line.replace(reg2, '');

        return new_line;
    }

    /**
     * Парсинг входной строки
     *
     * @param line
     */
    this.parse = function(line){
        var result = self.emptyElement(); // Результат парсинга
        console.log("Line: " + line);

        result.inputs = line;
        var src_line_array = line.split(' ');
        var line_array = [];
        line_array[0] = src_line_array[0]; // Поле ящиков

        const isSizes = (str) => /^[0-9/p]+$/.test(str);
        const isPrices = (str) => /^(?=.*?\.)[0-9\/\$\.]+$/.test(str);

        var variety_str = '';
        for(var index = 1; index < src_line_array.length; ++index ){
            if(isSizes(src_line_array[index])){
                line_array[1] = variety_str;
                line_array[2] = src_line_array[index];
                if(typeof src_line_array[index+1] != 'undefined' && isPrices(src_line_array[index+1])){
                    line_array[3] = src_line_array[index+1];
                }
            }else{
                if(index != 1){
                    variety_str = variety_str + ' ';
                }
                variety_str = variety_str + src_line_array[index];
            }
        }

        // 0 - Ящиков
        var reg = new RegExp("([0-9]*)"+self.boxes_reg, "g");
        var boxes = reg.exec(line_array[0]);
        result.boxes = boxes[1];
        result.boxes_type = boxes[2];
        result.box_id = self.parseBoxType(result.boxes_type);

        // 1 - Сорт
        result.variety = line_array[1];
        var tmp = self.parseVariety(result.variety);
        if(typeof tmp != 'undefined' && typeof tmp.sort_id != 'undefined' && tmp.sort_id != 0){
            result.sort_id = tmp.sort_id;
            result.type_id = tmp.type_id;
            result.variety = tmp.variety;
            result.comment = '';
        }else{
            result.comment = 'Variety not recognize';
        }

        // 2 - Размер
        if(typeof line_array[2] !== 'undefined'){
            result.sizes = self.parseSizes(line_array[2]);
        }

        // 3 - Цены
        if(typeof line_array[3] !== 'undefined') {
            result.prices = self.parsePrices(line_array[3]);
        }

        return result;
    };

    /**
     * Парсинг входной строки
     *
     * Line probably format
     * 5hb alba 50cm
     * 5hb alba 50-70
     * 5hb alba 60 0.50
     * 5hb alba 50-70 0.35-0.45
     * @param line
     */
    this.parseSimple = function(line){
        var result = self.emptyElement(); // Результат парсинга
        console.log("Line: " + line);

        result.inputs = line;
        var line_array = line.split(' ');

        // 0 - Ящиков
        var reg = new RegExp("([0-9])"+self.boxes_reg, "g");
        var boxes = reg.exec(line_array[0]);
        result.boxes = boxes[1];
        result.boxes_type = boxes[2];
        result.box_id = self.parseBoxType(result.boxes_type);

        // 1 - Сорт
        result.variety = line_array[1];
        var tmp = self.parseVariety(result.variety);
        console.log(tmp);
        if(typeof tmp != 'undefined' && typeof tmp.sort_id != 'undefined'){
            result.sort_id = tmp.sort_id;
            result.type_id = tmp.type_id;
        }else{

        }

        // 2 - Размер
        if(typeof line_array[2] !== 'undefined'){
            result.sizes = self.parseSizes(line_array[2]);
        }

        // 3 - Цены
        if(typeof line_array[3] !== 'undefined') {
            result.prices = self.parsePrices(line_array[3]);
        }

        return result;
    };

    this.parseBoxType = function(box_type){
        for (var num in self.boxes) {
            if(self.boxes[num].name.toLowerCase() === box_type.toLowerCase()){
                return self.boxes[num].id;
            }
        }
        return 0;
    }

    this.parseVariety = function (variety){
        for (var num in self.varieties) {
            if(
                typeof self.varieties[num].name != 'undefined'
                && self.varieties[num].name !== null
                && typeof variety != 'undefined'
                && self.varieties[num].name.toLowerCase() === variety.toLowerCase()
            ){
                return {
                    'sort_id': self.varieties[num].id,
                    'type_id': self.varieties[num].type_id,
                    'variety': self.varieties[num].name
                };
            }else{
                for (var alias_index in self.varieties[num].aliases) {
                    if(self.varieties[num].aliases[alias_index].toLowerCase() === variety.toLowerCase()){
                        return {
                            'sort_id': self.varieties[num].id,
                            'type_id': self.varieties[num].type_id,
                            'variety': self.varieties[num].name
                        };
                    }
                }
            }
        }
        return {
            'sort_id': 0,
            'type_id': 0,
            'variety': ''
        };
    };

    /**
     * Обработка поля размеров
     * @param sizes
     * @returns {*}
     */
    this.parseSizes = function (sizes){
        var sizes_array = sizes.split('/');
        var present_100p = false;
        for(var index = 0; index < sizes_array.length; ++index ){
            if(sizes_array[index] != '100p' && parseInt(sizes_array[index]) > 100){
                sizes_array[index] = '100p';
            }
            if(sizes_array[index] == '100p'){
                if(present_100p){
                    // Удаляем размер
                    sizes_array.splice(index);
                }
                present_100p = true;
            }
        }
        var rez_sizes = '';
        for(var index = 0; index < sizes_array.length; ++index ){
            if(index !== 0){
                rez_sizes = rez_sizes + '/';
            }
            rez_sizes = rez_sizes + sizes_array[index];
        }
        return rez_sizes;
    };

    /**
     * Обработка поля цен
     * @param prices
     * @returns {*}
     */
    this.parsePrices = function (prices){
        // Убираем $
        return prices.replace(/(\$)/g, '');
    };

    this.getParamsFromBfData = function (inputs){
        var rez = [];

        inputs.forEach(function (element, index){
            if(element.sort_id > 0){
                var new_element = {
                    "num": element.num,
                    "boxes": element.boxes,
                    "boxes_type": element.boxes_type,
                    "variety": element.variety,
                    "sizes": element.sizes,
                    "prices": element.prices,
                    "box_id": element.box_id,
                    "type_id": element.type_id,
                    "sort_id": element.sort_id,
                };
                rez.push(new_element)
            }

        });

        return rez;
    }

    /**
     * {
     *             "input": "5hb alba 50cm",
     *             "boxes": "2",
     *             "boxes_type": "HB",
     *             "variety": "Alba",
     *             "sizes": "50",
     *             "prices": "0.25",
     *             "box_id": 0,
     *             "type_id": 0,
     *             "sort_id": 0,
     *             "comment": '',
     *             "actions": ""
     *         }
     * @returns {{boxes: string, box_id: number, input: string, sizes: string, variety: string, type_id: number, boxes_type: string, comment: string, prices: string, actions: string, sort_id: number}}
     */
    this.emptyElement = function (){
        return {
            "num": 0,
            "input": "",
            "boxes": "",
            "boxes_type": "",
            "variety": "",
            "sizes": "",
            "prices": "",
            "box_id": 0,
            "type_id": 0,
            "sort_id": 0,
            "comment": '',
            "actions": ""
        }
    };

    this.importTxtClosure = function( grid ) {
        return function( importFile ) {
            var newObjects = [];
            var newObject;
            var importArray = service.parseJson( grid, importFile );

            if (importArray === null) {
                return;
            }
            importArray.forEach(  function( value ) {
                newObject = service.newObject( grid );
                angular.extend( newObject, value );
                newObject = grid.options.importerObjectCallback( grid, newObject );
                newObjects.push( newObject );
            });

            //service.addObjects( grid, newObjects );
        };
    };
}]);
