angular.module('bfControllers.subbuyers', [])
.controller('SubbuyersController', ['$scope', '$rootScope', '$http', '$resource', '$cookies', 'subbuyer', function ($scope, $rootScope, $http, $resource, $cookies, subbuyer) {

    $scope.subbuyer = subbuyer;

    $rootScope.create = subbuyer.set;

    $scope.selected_subbuyers = {
        ids: []
    };

    $scope.start = 0;
    $scope.count = 0;
    $scope.pager = [];
    $scope.items_per_page = ITEMS_SHOW_PER_PAGE;

    $rootScope.sort = 'udate';
    $rootScope.direct = 'desc';
    $rootScope.show = 'all';

    $rootScope.current_user_role = CURRENT_USER_ROLE;
    $rootScope.role_buyer = ROLE_BUYER;
    $rootScope.role_seller = ROLE_SELLER;
    
    var self = this;

    // Get list subbuyers
    // http://ec.term.biflorica:8080/api/Relationship/SubBuyers/?start=NaN&sort=bid&show%5B%5D=all&limit=127&direct=desc&contentType=json
    this.get = function() {
        $rootScope.querySent = true;
        $resource(API_LINK + '/Relationship/SubBuyers/',
            {
                'show[]': $rootScope.show,
                sort: $rootScope.sort,
                limit: ITEMS_SHOW_PER_PAGE,
                start: $scope.start,
                direct: $rootScope.direct
            },
            {get: {method:'GET'}}
        ).get(function(result){
            $scope.users = result.data.list;
            //self.buttonsActiveOff();
            //$scope.renewButtonsStatus();
            $rootScope.querySent = false;
            setTimeout(function(){
                    $('table tr td.editable').each(function(){
                    var id = $(this).attr('id');
                    $(this).fastEdit("/api/Relationship/saveShippingPrice", $(this).attr('id'), $(this).attr('field'));
                });
            }, 500);
        });
    };
    this.get();
    $rootScope.renew = this.get;

    $scope.renewTimer = function(){
        if(!$rootScope.pause) $rootScope.timerCountSec--;
        if($rootScope.timerCountSec <= 0){
            $rootScope.timerCountSec = TIMER_RENEW_PERIOD;
            $rootScope.renew();
        }
        //$('#timer_value').text($rootScope.timerCountSec);
        setTimeout($scope.renewTimer, 1000);
    };
    $scope.renewTimer();

    $scope.orderFormTitleCreate = function(){
        //return typeof $scope.subbuyer.edited.guid == 'undefined' || $scope.subbuyer.edited.guid == '-1';
    };

    /**
     * Move orders from market
     */
    $rootScope.moveFromMarket = function () {
        $rootScope.querySent = true;

        url = API_LINK + '/orders/moveFromMarketPlace/';

        sent_params = {};
        sent_params['checked[]'] = $scope.selected_orders.ids;
        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            if( result.errors.length == 0) {
                //location.reload();

            } else {
                //$rootScope.requestFormErrors = result.errors[0];
                //$rootScope.requestFormFieldsErrors = result.fieldsErrors;
            }
            $rootScope.timerCountSec = 0;
            self.uncheckAll();
            console.log(result);
        }, function(err){
            console.log(err);
        });
    };

    /**
     * Approve before delete orders
     */
    $rootScope.approveBeforeDeleteOrders = function () {
        var order, selected_order, selected_order_id;
        var approve_with_deal = false;
        for(selected_order in $scope.selected_orders.ids){
            selected_order_id = $scope.selected_orders.ids[selected_order];
            for(order in $scope.orders){
                if($scope.orders[order].guid == selected_order_id && parseFloat($scope.orders[order].deals) > 0){
                    approve_with_deal = true;
                    break;
                }
                //$scope.selected_orders.ids[order] = $scope.orders[order].guid;
            }
        }
        $rootScope.approve_with_deal = approve_with_deal;
        $('.bf-approve-dialog').modal('show');
    };

    /**
     * Delete orders
     */
    $rootScope.deleteOrders = function () {
        $rootScope.querySent = true;
        url = API_LINK + '/orders/delete/';

        sent_params = {};
        sent_params['ids[]'] = $scope.selected_orders.ids;
        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            if( result.errors.length == 0) {
                //location.reload();
            } else {
                //$rootScope.requestFormErrors = result.errors[0];
                //$rootScope.requestFormFieldsErrors = result.fieldsErrors;
            }
            $rootScope.timerCountSec = 0;
            self.uncheckAll();
            console.log(result);
        }, function(err){
            console.log(err);
        });
    };

    /**
     * Open orders, show list requests
     */
    $rootScope.openOrders = function () {
        sent_params = {};
        sent_params['item[]'] = $scope.selected_orders.ids;
        window.location.replace('/marketPlaceNew/default/requests/?' + $.param(sent_params));
    };

    /**
     * Show reports for selected orders
     */
    $rootScope.reportOrders = function () {
        sent_params = {};
        sent_params['orders[]'] = $scope.selected_orders.ids;
        window.location.replace('/api/requests/GetCSVList/?'+$.param(sent_params));
    };

    /**
     * Отметить все чекбоксы в списке
     */
    $scope.checkAll = function () {
        $scope.selected_orders.ids = [];
        if($('#list-orders-all').is(':checked')){
            for (var order in $scope.orders) {
                $scope.selected_orders.ids[order] = $scope.orders[order].guid;
                console.log($scope.orders[order].guid);
            }
            $('.order-checkbox').attr('checked', true);
        }
        //console.log($scope.selected_orders);
    };

    /**
     * Снять Отметки всех чекбоксов в списке
     */
    this.uncheckAll = function () {
        $scope.selected_orders.ids = [];
        $('#list-orders-all').attr('checked',false);
    };
    $scope.uncheckAll = this.uncheckAll;

    /**
     * Обновить статус кнопок груповых операций
     */
    $scope.renewButtonsStatus = function () {
        if($scope.selected_orders.ids.length == 0){
            // Disable buttons
            $('.group-orders-btn').attr('disabled', 'disabled');
        }else{
            // Enable buttons
            $('.group-orders-btn').removeAttr('disabled');;

        }
        //console.log($scope.selected_orders);
    };

    this.buttonsActiveOff = function(){
        $('.btn-reset-if-renew').blur();
    };

    // Выбранные значения сортировки
    var sortValueFromCookies = $cookies.get('sort');
    if(typeof sortValueFromCookies != 'undefined'){
        $rootScope.sort = sortValueFromCookies;
    }else{
        $rootScope.sort = 'created_at';
        $cookies.put('sort', 'created_at');
    }
    var directValueFromCookies = $cookies.get('direct');
    if(typeof directValueFromCookies != 'undefined'){
        $rootScope.direct = directValueFromCookies;
    }else{
        $rootScope.direct = 'desc';
        $cookies.put('direct', 'desc');
    }
    this.setSort = function(sort_column){
        if($rootScope.sort == sort_column){
            // Изменяем направление сортировки
            if($rootScope.direct == 'asc'){
                $rootScope.direct = 'desc';
                $cookies.put('direct', 'desc');
            }else{
                $rootScope.direct = 'asc';
                $cookies.put('direct', 'asc');
            }
        }else{
            // Изменяем сортировку
            $cookies.put('sort', sort_column);
            $rootScope.sort = sort_column;
        }
        $rootScope.timerCountSec = 0;
    };
    $rootScope.setSort = this.setSort;

    $scope.classSort = function(sort_column){
        if($rootScope.sort == sort_column){
            // Изменяем направление сортировки
            if($rootScope.direct == 'asc'){
                return 'sorted sorted-up';
            }else{
                return 'sorted sorted-down';
            }
        }
    };

    /**
     * Устанавливает фильт по отображаемым ордерам (активные истекшие)
     */
    this.setSelect = function(show){
        $rootScope.show = show;
        $rootScope.timerCountSec = 0;
    };
    $rootScope.setSelect = this.setSelect;

    $scope.renewRange = function(start, end){
        $rootScope.startDate = start;
        $rootScope.endDate = end;
    };

    $scope.showOrder = function(url){
        window.location.replace(url);
    };
} ]);