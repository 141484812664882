angular.module('bfServices.request', [])

/**
 * Сервис заявки
 * 
 */
.service('request', ['$http', '$resource', '$rootScope', 'handbook', function($http, $resource, $rootScope, handbook) {

    /**
     * Активная заявка с которой производится работа, инит структура находится в функции setEmptyRequest
     */
    this.listEditedRequests = {
        0: {},
    };
    this.numRequestInList = 0;

    this.editedRequest = this.listEditedRequests[0];

    this.editedRequestSum = {};
    this.editedRequestPricesShow = false;

    // Дополнительные заявки к основной с которой производится работа
    this.additionalRequests = {};
    this.countAdditionalRequests = 0;
    this.returnToRequestId = 0;

    // Ошибки валидации заявки
    this.requestFormFieldsErrors = {};

    // Вспомогательн // Moved to editedRequest !
    this.listSubmark = {};
    this.listCargo = {};
    this.availableVarieties = [];

    // Список активных заказов пользователя для текущего режима
    this.orders = {};
    this.all_active_orders = {};
    this.toOrderId = 0;

    // Список связей
    this.links = {};

    // Список активных заказов пользователя, все режимы
    this.all_orders = {};
    this.defaultBoxWeight = 25; //вес коробки по умолчанию

    this.clone_request = false;

    this.importRequest = {};

    var self = this;

    this.returnToRequest = function() {
        document.getElementById('order_buyer_id').value = undefined;
        this.formType = 'create';
        this.set(this.returnToRequestId);
        $('.bf-create-request-dialog').modal('show');
        this.returnToRequestId = 0;
    };

    /**
     *
     * @param buyerId
     * @param returnRequestGuid
     */
    this.createCounteroffer = function(buyerId, returnRequestGuid) {

        // BIF-4688 Андрей, функцию Add different item нужно рассматривать как последовательное применение функции “Нет в наличии“ и затем Создать предложение.
        self.editedRequest.no_deal = 1;
        var sent_params = this.getParamsFromEditedRequest(self.editedRequest);
        $http({
            method: 'POST',
            url: API_LINK + '/deals/create',
            data: $.param(sent_params),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(function(result) {
            console.log(result);
        }, function(err) {
            console.log(err);
        });

        // ... и затем Создать предложение
        this.clone_request = false;
        this.returnToRequestId = returnRequestGuid;
        this.formType = 'counteroffer';
        this.set(false, false, false, buyerId);
        this.editedRequestSum.price = 0;
        $('.bf-create-request-dialog').modal('show');
    };

    /**
     * Инициализация заявки
     * @param request_id
     * @param order_id
     * @param price_id
     * @param buyer_id
     * @param approve_change_from_mp
     * @param num_request - номер заявки в списке
     */
    this.set = function(request_id, order_id, price_id, buyer_id, approve_change_from_mp, num_request) {

        if (typeof num_request === 'undefined') num_request = 0;
        console.log(num_request);

        if (typeof self.listEditedRequests[num_request] == 'undefined') {
            console.log('Setting empty');
            self.setEmptyRequest(num_request);
        }

        if (
            typeof self.listEditedRequests[num_request] != 'undefined' &&
            typeof self.listEditedRequests[num_request].approveForm != 'undefined' &&
            self.listEditedRequests[num_request].approveForm
        ) {
            var approve_change = true;
        } else {
            var approve_change = false;
        }

        var market_mode = '';

        //Очищаем ошибки на форме
        self.clearErrors(self.listEditedRequests[num_request]);
        //if(typeof currentEditedRequest != 'undefined' && typeof currentEditedRequest.orders != 'undefined') currentEditedRequest.orders.selected = '';

        // Подгружаем справочники
        self.getSubmarksList(num_request);
        self.getCargoList(num_request);

        //Обновляем список активных ордеров
        self.getActiveOrders();

        if (typeof approve_change_from_mp === 'undefined' && (typeof show_order_id == 'undefined' || show_order_id == 0)) {
            var addNewRequest;

            if (typeof request_id == 'undefined' || request_id == 0) {
                // http://ec.term.biflorica:8080/api/requests/get/order/3/id/-1/plantation/1
                // Создание заявки из маркетплейса
                url = API_LINK + '/requests/get/order/' + order_id + '/id/-1';
                addNewRequest = true;
                //url = API_LINK + '/deals/new/request/-1';
            } else {
                // http://ec.term.biflorica:8080/api/deals/new/request/18
                url = API_LINK + '/deals/new/request/' + request_id;
                if (PRICELIST_MODE) {
                    url = API_LINK + '/deals/new/request/' + request_id + '/market_mode/' + $rootScope.showMarketMode();
                }
                addNewRequest = false;
            }

            if (typeof price_id != 'undefined') {
                url = url + '/priceid/' + price_id;
            }
            // http://ec.term.biflorica:8080/api/deals/new/request/18
            $resource(
                url, {}, //{action:'new', 'request': request_id},
                { get: { method: 'GET' } }
            ).get(function(result) {
                self.listEditedRequests[num_request] = result.data.request; //?
                self.renewLinkForZerroRequest(num_request);
                if (buyer_id) {
                    self.listEditedRequests[num_request].buyer_id = buyer_id;
                }
                if (self.listEditedRequests[num_request].farm.selected == 0 && addNewRequest && !self.listEditedRequests[num_request].farm.disable) self.listEditedRequests[num_request].farm.selected = '';
                if (typeof self.listEditedRequests[num_request].submarks != 'undefined' && self.listEditedRequests[num_request].submarks.selected == '00') self.listEditedRequests[num_request].submarks.selected = '0';

                if (typeof $rootScope.order_purpose === 'undefined') {
                    self.orders = self.all_orders[self.listEditedRequests[num_request].order_purpose];
                }

                self.renewPacking(self.listEditedRequests[num_request]);

                self.listEditedRequests[num_request].from = true;
                self.listEditedRequests[num_request].addNewRequest = addNewRequest;

                if (PRICELIST_MODE) {
                    self.listEditedRequests[num_request].status.disabled = true;
                }

                // Блокируем поля которые зависят от предварительного выбора (только для формы новой заявки)
                if (addNewRequest) self.blockFieldForNew(self.listEditedRequests[num_request]);

                self.moveSelectedValues(self.listEditedRequests[num_request]);
                //$('.bf-select').selectpicker('refresh');
                self.recalculateRequestPrice(self.listEditedRequests[num_request]);
                self.normallizeTypes(self.listEditedRequests[num_request]);
                self.normallizeVarieties(self.listEditedRequests[num_request]);

                // 2. В форме создания заявки селектор MARK (это селектор лучше назвать BUYER как в текущем дизайне) должен быть пустым и неактивным.
                if (addNewRequest && self.listEditedRequests[num_request].farm.disable) {
                    self.listEditedRequests[num_request].submarks.selected = '';
                    self.listEditedRequests[num_request].submarks.disabled = true;
                }

                if (self.clone_request) {
                    self.__send_url = API_LINK + '/requests/clone/id/' + request_id;
                }

                self.changeOrderInRequest(self.listEditedRequests[num_request]);

                if (typeof price_id != 'undefined') {
                    self.changeSortInRequest(self.listEditedRequests[num_request]);
                }

                if (!addNewRequest || typeof price_id != 'undefined') {
                    // Обновляем доступные типы
                    self.updateAvailableVarieties(self.listEditedRequests[num_request], true);
                }

                if ($rootScope.current_user_role == $rootScope.role_seller && self.listEditedRequests[num_request].req_pack == 0) {
                    self.listEditedRequests[num_request].req_pack = '';
                }

                self.listEditedRequests[num_request].last_boxes_type = self.listEditedRequests[num_request].boxes_type;

                //self.listEditedRequests[num_request] = currentEditedRequest;
            });
        } else {
            if (typeof self.listEditedRequests[num_request].get_data_url != 'undefined' && self.listEditedRequests[num_request].get_data_url != '') {
                url = self.listEditedRequests[num_request].get_data_url;
                approve_change = true;
            } else {
                if (typeof request_id == 'undefined' || request_id == 0) {
                    // http://ec.term.biflorica:8080/api/requests/get/order/3/id/-1/plantation/1
                    url = API_LINK + '/requests/get/order/' + show_order_id + '/id/-1';
                    addNewRequest = true;
                } else {
                    // http://ec.term.biflorica:8080/api/deals/new/request/18
                    url = API_LINK + '/deals/new/request/' + request_id;
                    addNewRequest = false;
                }
            }
            console.log(self.listEditedRequests[num_request].get_data_url);
            $resource(
                url, {}, { get: { method: 'GET' } }
            ).get(function(result) {
                self.listEditedRequests[num_request] = result.data.request; //?
                self.renewLinkForZerroRequest(num_request);
                if (self.listEditedRequests[num_request].farm.selected == 0 && addNewRequest && !self.listEditedRequests[num_request].farm.disable) self.listEditedRequests[num_request].farm.selected = '';

                if (typeof $rootScope.order_purpose === 'undefined') {
                    self.orders = self.all_orders[self.listEditedRequests[num_request].order_purpose];
                }

                self.renewPacking(self.listEditedRequests[num_request]);

                self.listEditedRequests[num_request].addNewRequest = addNewRequest;

                // Если форма открыта с предварительным заполнением то переносим прайсы правильно
                if (typeof self.listEditedRequests[num_request].get_data_url != 'undefined' && self.listEditedRequests[num_request].get_data_url != '') {
                    self.movePricesForForm(self.listEditedRequests[num_request]);
                }

                // Блокируем поля которые зависят от предварительного выбора (только для формы новой заявки)
                if (addNewRequest) self.blockFieldForNew(self.listEditedRequests[num_request]);

                self.moveSelectedValues(self.listEditedRequests[num_request]);

                var submark, cargo;

                if (typeof request_id == 'undefined' || request_id == 0) {
                    console.log(self.listSubmark);
                    for (var num_submark in self.listEditedRequests[num_request].listSubmark) {
                        submark = self.listEditedRequests[num_request].listSubmark[num_submark];
                        self.listEditedRequests[num_request].submarks = {};
                        self.listEditedRequests[num_request].submarks.items = [];
                        self.listEditedRequests[num_request].submarks.items[submark.guid] = submark.id; //{guid: "00", id: "B1", name: "-"}
                        self.listEditedRequests[num_request].submarks.selected = parseInt(submark.guid);
                        console.log(submark);
                    }

                    for (var num_cargo in self.listEditedRequests[num_request].listCargo) {
                        cargo = self.listEditedRequests[num_request].listCargo[num_cargo];
                        self.listEditedRequests[num_request].cargo = {};
                        self.listEditedRequests[num_request].cargo.items = [];
                        self.listEditedRequests[num_request].cargo.items[cargo.guid] = cargo.id; // {canBeChosen: true, guid: "1", id: "EBF Cargo", name: "EBF Cargo", comment: ""}
                        console.log(cargo);
                    }
                }

                self.recalculateRequestPrice(self.listEditedRequests[num_request]);
                self.normallizeTypes(self.listEditedRequests[num_request]);
                self.normallizeVarieties(self.listEditedRequests[num_request]);

                // 2. В форме создания заявки селектор MARK (это селектор лучше назвать BUYER как в текущем дизайне) должен быть пустым и неактивным.
                if (addNewRequest && self.listEditedRequests[num_request].farm.disable) {
                    self.listEditedRequests[num_request].submarks.selected = '';
                    self.listEditedRequests[num_request].submarks.disabled = true;
                }

                // Изменения для формы изменения при апруве сделки
                if (approve_change) {
                    self.listEditedRequests[num_request].min_boxes_amount = parseFloat(1.0);
                    self.listEditedRequests[num_request].max_boxes_amount = self.listEditedRequests[num_request].boxes_amount;
                    self.listEditedRequests[num_request].approve_prices_disabled = true;
                    self.listEditedRequests[num_request].approve_boxes_type_disabled = true;
                    self.listEditedRequests[num_request].status.disabled = true;
                    self.listEditedRequests[num_request].orders.disabled = true;
                    self.listEditedRequests[num_request].req_pack = 0;
                    self.listEditedRequests[num_request].approveForm = true;
                }

                self.changeOrderInRequest(self.listEditedRequests[num_request]);

                if (self.clone_request) {
                    self.__send_url = API_LINK + '/requests/clone/id/' + request_id;
                    if (typeof order_id != 'undefined' && order_id != 0) self.listEditedRequests[num_request].orders.selected = order_id;
                }

                if (!addNewRequest) {
                    // Обновляем доступные типы
                    self.updateAvailableVarieties(self.listEditedRequests[num_request], true);
                }

                self.listEditedRequests[num_request].last_boxes_type = self.listEditedRequests[num_request].boxes_type;

                //self.listEditedRequests[num_request] = currentEditedRequest;
            });
        }

    };
    this.setRequest = this.set;

    /**
     * Инициализация Импортной заявки
     * @param import_item_id
     */
    this.setImportRequest = function(import_item_id) {

        self.setEmptyImportRequest();

        // Подгружаем справочники
        self.getSubmarksList(0);
        //self.getCargoList(0);

        //Обновляем список активных ордеров
        self.getActiveOrders(0);

        $resource(
            API_LINK + '/requests/getImport/id/' + import_item_id, {}, { get: { method: 'GET' } }
        ).get(function(result) {
            console.log(result.data.request);
            self.importRequest.import_item = result.data.request;
            self.recalculateImportRequestPrice(self.importRequest);
        });
    };

    /**
     * Получение заявки
     * @returns {{}|*}
     */
    this.get = function(num_request) {
        if (typeof num_request === 'undefined') num_request = 0;
        return self.listEditedRequests[num_request];
    };
    this.getRequest = this.get;

    /**
     * Очистка заявки
     * @returns {{}|*}
     */
    this.reset = function(num_request) {
        if (self.formType != 'copy') {
            if (typeof num_request === 'undefined') num_request = 0;
            self.setEmptyRequest(num_request);
            self.clearErrors(self.listEditedRequests[num_request]);
            self.requestFormDeletePreviusVar(); //?
        }
        return true;
    };

    /**
     * Сохранение заявки
     * @param request
     */
    this.save = function(request_id, from, num_request) {
        if (typeof num_request === 'undefined') num_request = 0;

        // Disable Save button
        self.sentForm = true;

        if (self.saveAdditionalRequests(from)) { // Если есть дополнительные заявки сохраняем их отдельно
            console.log(self.listEditedRequests[num_request]);
            if (typeof self.__send_url != 'undefined' && self.__send_url != '') {
                url = self.__send_url;
            } else {
                if (typeof self.clone_request != 'undefined' && self.clone_request) {
                    url = API_LINK + '/requests/clone/id/' + request_id;
                } else {
                    if (typeof from == 'undefined') {
                        // Работа из ордера
                        if (typeof request_id == 'undefined' || request_id == 0) {
                            // http://ec.term.biflorica:8080/api/requests/create/
                            url = API_LINK + '/requests/create';
                        } else {
                            // http://ec.term.biflorica:8080/api/requests/updateOnMarket/id/14
                            //url = API_LINK + '/requests/updateOnMaket/id/' + request_id;

                            // http://ec.term.biflorica:8080/api/requests/update/id/65
                            url = API_LINK + '/requests/update/id/' + request_id;
                        }
                    } else {
                        // Работа из Маркета
                        if (typeof self.listEditedRequests[num_request].buy != 'undefined' && self.listEditedRequests[num_request].buy == '1') {
                            // http://ec.term.biflorica:8080/api/deals/create/
                            url = API_LINK + '/deals/create';
                        } else {
                            if (typeof request_id == 'undefined' || request_id == 0) {
                                // http://ec.term.biflorica:8080/api/requests/create/
                                url = API_LINK + '/requests/create';
                            } else {
                                // http://ec.term.biflorica:8080/api/deals/update/id/65
                                url = API_LINK + '/deals/update/id/' + request_id;
                            }
                        }
                    }
                }

            }
            var sent_params = this.getParamsFromEditedRequest(self.listEditedRequests[num_request]);
            if (PRICELIST_MODE) {
                sent_params['market_mode'] = $rootScope.showMarketMode();
            }
            if (typeof self.formType != 'undefined') {
                sent_params['form_type'] = self.formType;
            }

            if (self.toOrderId != 0) {
                sent_params['order'] = self.toOrderId;
                sent_params['order_id'] = self.toOrderId;
            }

            (function(num_request) {
                $http({
                    method: 'POST',
                    url: url,
                    data: $.param(sent_params),
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
                }).then(function(result) {
                    result = result.data;
                    self.sentForm = false;
                    if (!result.errors || result.errors.length == 0) {
                        //location.reload();
                        $('.bf-create-request-dialog').modal('hide');
                        self.setEmptyRequest(num_request);
                        self.listEditedRequests[num_request].hideForm = true;
                        self.listEditedRequests[num_request].copied = false;
                        self.listEditedRequests[num_request].copied_finished = true;
                        self.clearErrors(self.listEditedRequests[num_request]);
                        self.requestFormDeletePreviusVar();
                        $rootScope.timerCountSec = 0;

                    } else {
                        self.listEditedRequests[num_request].requestFormErrors = result.errors[0];
                        self.listEditedRequests[num_request].requestFormFieldsErrors = result.fieldsErrors;
                    }
                    console.log(result);
                }, function(err) {
                    self.sentForm = false;
                    console.log(err);
                });
            })(num_request);
        }
    };

    /**
     * Сохранение Ипортной заявки
     * @param request
     */
    this.saveImport = function() {
        self.sentForm = true;

        // http://ec.term.biflorica:8080/api/deals/createImport/
        url = API_LINK + '/deals/createImport';

        var sent_params = {};

        // Delivery Date
        sent_params['delivery_date'] = self.importRequest.import_item.deliveryDate;

        // Order Id
        if (typeof self.importRequest.orders != 'undefined' && typeof self.importRequest.orders.selected != 'undefined' && self.importRequest.orders.selected != '') {
            sent_params['order_id'] = self.importRequest.orders.selected;
        }
        // Submark Id
        if (typeof self.importRequest.submarks != 'undefined') {
            if (typeof self.listSubmark[self.importRequest.submarks.selected] != 'undefined') {
                sent_params['submark_id'] = parseInt(self.listSubmark[self.importRequest.submarks.selected].guid);
            }
        }
        // Machine Features
        if (typeof self.importRequest.machine_features_selected != 'undefined' && typeof self.importRequest.machine_features_selected.id != 'undefined' && self.importRequest.orders.selected.id != '') {
            sent_params['machine_features'] = self.importRequest.machine_features_selected.id;
        }
        sent_params['id'] = self.importRequest.import_item.origin_id;
        sent_params['quantity'] = self.importRequest.buy_quantity;

        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(function(result) {
            console.log(result);
            result = result.data;
            self.sentForm = false;
            if (!result.errors || result.errors.length == 0) {
                //location.reload();
                $('.bf-create-import-request-dialog').modal('hide');
            } else {
                self.importRequest.errors = result.errors;
                self.importRequest.fieldsErrors = result.fieldsErrors;
            }
            //$('.bf-create-import-request-dialog').modal('hide');
        }, function(err) {
            self.sentForm = false;
            console.log(err);
        });
    }


    /**
     * Копирование заявки в ордер
     *
     */
    this.saveCopyToOrder = function(request_id, to_order_id, num_request, from) {
        self.toOrderId = to_order_id;
        self.save(request_id, from, num_request);
        /*
            if(typeof num_request === 'undefined') num_request = 0;

            console.log(self.listEditedRequests[num_request]);

            // Disable Save button
            self.sentForm = true;

            url = API_LINK + '/requests/copyToOrder';
            var sent_params = this.getParamsFromEditedRequest(self.listEditedRequests[num_request]);
            if(PRICELIST_MODE){
                sent_params['market_mode'] = $rootScope.showMarketMode();
            }
            sent_params['order'] = to_order_id;
            sent_params['requests'] = [self.listEditedRequests[num_request].guid];
            $http({
                method: 'POST',
                url: url,
                data: $.param(sent_params),
                headers: {'Content-Type': 'application/x-www-form-urlencoded'}
            }).then(function( result ){
                result = result.data;
                self.sentForm = false;
                if(!result.errors || result.errors.length == 0) {
                    //location.reload();
                    $('.bf-create-request-dialog').modal('hide');
                    self.listEditedRequests[num_request].hideForm = true;
                    self.clearErrors(self.listEditedRequests[num_request]);
                    self.requestFormDeletePreviusVar();
                    $rootScope.timerCountSec = 0;
                } else {
                    self.listEditedRequests[num_request].requestFormErrors = result.errors[0];
                    self.listEditedRequests[num_request].requestFormFieldsErrors = result.fieldsErrors;
                }
                console.log(result);
            }, function(err){
                self.sentForm = false;
                console.log(err);
            });
            */
    };

    /**
     * 
     * @param editedRequest
     * @returns {{}|*}
     */
    this.getParamsFromEditedRequest = function(editedRequest) {
        //Формируем параметры на отправку
        var sent_params = {};
        var size, price;

        if (typeof editedRequest.guid != 'undefined' && editedRequest.guid != '' && editedRequest.guid != 0 && editedRequest.guid != -1) {
            sent_params.guid = editedRequest.guid;
            sent_params.id = editedRequest.guid; // Требуется для изменения заявки из ордера
        }

        sent_params.link_id = editedRequest.link_id;
        sent_params.farm = editedRequest.farm.selected;
        sent_params.type = editedRequest.type.selected;
        sent_params.variety = editedRequest.variety.selected;
        sent_params.boxes_amount = editedRequest.boxes_amount;
        sent_params.boxes_type = editedRequest.boxes_type;
        sent_params.mp_hash = editedRequest.mp_hash;
        sent_params.status = editedRequest.status.selected;
        // Order Id
        if (typeof editedRequest.orders != 'undefined' && typeof editedRequest.orders.selected != 'undefined' && editedRequest.orders.selected != '') {
            sent_params.orders = editedRequest.orders.selected;
            sent_params.order_id = editedRequest.orders.selected;
        }
        // exph
        sent_params.exph = editedRequest.exph;
        // expm
        sent_params.expm = editedRequest.expm;
        // Submarks Id
        if (typeof editedRequest.submarks != 'undefined') {
            if (typeof self.listSubmark[editedRequest.submarks.selected] != 'undefined') {
                sent_params.submarks = parseInt(self.listSubmark[editedRequest.submarks.selected].guid);
                sent_params.submarks_id = parseInt(self.listSubmark[editedRequest.submarks.selected].guid);
            }
        }
        // Cargo Id
        if (typeof editedRequest.cargo != 'undefined' && typeof editedRequest.cargo.selected != 'undefined') {
            sent_params.cargo = editedRequest.cargo.selected;
            sent_params.cargo_id = editedRequest.cargo.selected;
        }

        if (typeof editedRequest.prices != 'undefined') {
            for (var num_size in sizes) {
                size = sizes[num_size];
                price = 'price' + size;
                if (typeof editedRequest['check-price' + size] != 'undefined' && editedRequest['check-price' + size].checked == true) {
                    sent_params[price] = editedRequest[price];
                }
            }
        } else {
            for (var num_size in sizes) {
                size = sizes[num_size];
                if (typeof editedRequest['price' + size] != 'undefined' && editedRequest['price' + size] != '' && editedRequest['check-price' + size].checked == true) {
                    sent_params['price' + size] = editedRequest['price' + size];
                }
            }
        }

        if ($rootScope.current_user_role == $rootScope.role_buyer) {
            // Check Quality of Boxes
            if (typeof editedRequest.check_quality !== 'undefined') {
                sent_params.check_quality = editedRequest.check_quality ? 1 : 0;
            }

            if (editedRequest.need_req_pack) {
                sent_params.req_pack = editedRequest.req_pack;
            } else {
                if (editedRequest.role === 'b' && editedRequest.buy == 1) {
                    sent_params.req_pack = editedRequest.req_pack;
                } else {
                    sent_params.req_pack = 0;
                }
            }
            if (editedRequest.show_pack_block) sent_params.max_pack = editedRequest.max_pack;
            else sent_params.max_pack = 0;
            if (editedRequest.show_pack_block) sent_params.min_pack = editedRequest.min_pack;
            else sent_params.min_pack = 0;
        } else {
            sent_params.req_pack = editedRequest.req_pack;
        }

        if (typeof editedRequest.airport_delivery_date != 'undefined' && editedRequest.airport_delivery_date != '') {
            sent_params.airport_delivery_date = editedRequest.airport_delivery_date;
        }

        if (typeof editedRequest.no_deal != 'undefined' && editedRequest.no_deal == 1) {
            sent_params.no_deal = '1';
        }

        if (typeof $rootScope.order_purpose !== 'undefined') {
            sent_params.order_purpose = $rootScope.order_purpose;
        }
        if (editedRequest.buyer_id) {
            sent_params.buyer_id = editedRequest.buyer_id;
        }

        return sent_params;
    };

    this.isAllowTrade = function(role) {
        if (role == 'seller')
            return true

        if (this.editedRequest.hasOwnProperty('allow_trade')) {
            return this.editedRequest.allow_trade == 1
        }
        if (Symbol.iterator in Object(this.editedRequest.farm.items)) {
            for (const element of this.editedRequest.farm.items) {
                if (element.id == this.editedRequest.farm.selected) {
                    if (element.hasOwnProperty('allow_trade'))
                        return element.allow_trade == 1

                    return true
                }
            }
        }

        return true;
    }

    /**
     * Запрос суммарной информации по форме заявки
     * 
     */
    // http://ec.term.biflorica:8080/api/requests/getPriceWithStems/id/-1?farm=1&type=1_1&variety=1_1_1&hb=1.0&price40=0.25&status=on_market
    this.recalculateRequestPrice = function(editedRequest, need_change_packing) {
        if (typeof editedRequest == 'undefined') { editedRequest = self.editedRequest; }

        if (
            typeof editedRequest.farm.selected != 'undefined' &&
            typeof editedRequest.type.selected != 'undefined' &&
            typeof editedRequest.variety.selected != 'undefined'
        ) {
            if (typeof editedRequest.guid == 'undefined' || editedRequest.guid == 0) {
                // http://ec.term.biflorica:8080/api/requests/get/order/3/id/-1/plantation/1
                url = API_LINK + '/requests/getPriceWithStems/id/-1';
            } else {
                // http://ec.term.biflorica:8080/api/deals/new/request/18
                url = API_LINK + '/requests/getPriceWithStems/id/' + editedRequest.guid;
            }
            //Формирум параметры на отправку для подсчёта
            sent_params = self.getParamsFromEditedRequest(editedRequest);


            // если был изменен формат коробки, то сбрасываем предыдущий пакинг
            if (typeof need_change_packing !== 'undefined' && editedRequest.boxes_type != editedRequest.last_boxes_type) {
                sent_params['req_pack'] = 0;
            }

            if (PRICELIST_MODE) {
                sent_params['market_mode'] = $rootScope.showMarketMode();
            }

            if ($rootScope.current_user_role === $rootScope.role_buyer && editedRequest.show_pack_block) {
                sent_params['req_pack'] = sent_params['max_pack'];
            }

            $http({
                method: 'GET',
                url: url,
                params: sent_params,
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
            }).then(function(result) {
                //console.log(result);
                if (result.data.errors.length == 0) {
                    editedRequest.last_boxes_type = editedRequest.boxes_type;

                    editedRequest.editedRequestSum = result.data.data;
                    if ($rootScope.current_user_role == "seller") {
                        editedRequest.editedRequestSum.sum = parseFloat(editedRequest.editedRequestSum.price) - parseFloat(editedRequest.editedRequestSum.tax);
                    } else {
                        editedRequest.editedRequestSum.sum = parseFloat(editedRequest.editedRequestSum.price);

                        if (typeof editedRequest.editedRequestSum.tax != 'undefined') {
                            editedRequest.editedRequestSum.sum += parseFloat(editedRequest.editedRequestSum.tax);
                        }
                    }

                    editedRequest.editedRequestSum.sum = editedRequest.editedRequestSum.sum.toFixed(2);

                    //Reinit packing field in request
                    if (!editedRequest.show_pack_block && $rootScope.current_user_role != "seller") {
                        var new_req_pack = editedRequest.editedRequestSum.stems_in_box;

                        editedRequest.req_pack = new_req_pack;
                        editedRequest.min_pack = new_req_pack;
                        editedRequest.max_pack = new_req_pack;
                    }

                    if ($rootScope.current_user_role == "seller" &&
                        (
                            (
                                self.packingInQB(editedRequest.boxes_type) &&
                                (editedRequest.req_pack === '' || parseInt(editedRequest.req_pack) === 0)
                            ) ||
                            typeof need_change_packing != 'undefined'
                        )
                    ) {
                        editedRequest.req_pack = editedRequest.editedRequestSum.stems_in_box;
                    }

                    if (editedRequest.editedRequestSum.awb_price) {
                        editedRequest.flightPrice = editedRequest.editedRequestSum.awb_price;
                    } else {
                        editedRequest.flightPrice = (editedRequest.awb_rate) ? editedRequest.boxes_amount * self.defaultBoxWeight * Number(editedRequest.awb_rate) : 0;
                    }
                    editedRequest.editedRequestSum.sum = parseFloat(editedRequest.editedRequestSum.sum) + parseFloat(editedRequest.flightPrice);
                    editedRequest.editedRequestSum.sum = editedRequest.editedRequestSum.sum.toFixed(2);
                    self.recalculateAllRequestsPrice();
                } else {
                    //$scope.requestFormErrors = result.errors[0];
                    //$scope.requestFormFieldsErrors = result.fieldsErrors;
                }
                //console.log(result);
            }, function(err) {
                console.log(err);
            });
        }
    };

    /**
     * Пересчёт общей суммы по заявкам на текущей форме
     */
    this.recalculateAllRequestsPrice = function() {
        self.editedRequestSum = self.clone(self.editedRequest.editedRequestSum);
        // Перебор всех дополнительных заявок
        for (var request in self.additionalRequests) {
            if (self.additionalRequests[request].editedRequestSum.price) {
                self.editedRequestSum.price = parseFloat(self.editedRequestSum.price) + parseFloat(self.additionalRequests[request].editedRequestSum.price);
                self.editedRequestSum.tax = parseFloat(self.editedRequestSum.tax) + parseFloat(self.additionalRequests[request].editedRequestSum.tax);
                self.editedRequestSum.sum = parseFloat(self.editedRequestSum.sum) + parseFloat(self.additionalRequests[request].editedRequestSum.sum);
                self.editedRequestSum.stems = parseInt(self.editedRequestSum.stems) + parseInt(self.additionalRequests[request].editedRequestSum.stems);
                self.editedRequestSum.price = self.editedRequestSum.price.toFixed(2);
                self.editedRequestSum.tax = self.editedRequestSum.tax.toFixed(2);
                self.editedRequestSum.sum = self.editedRequestSum.sum.toFixed(2);
            }
        }
    };

    /**
     * Запрос суммарной информации по форме импортной заявки
     */
    this.recalculateImportRequestPrice = function(editedRequest) {
        var sent_params = {};
        sent_params['quantity'] = editedRequest.buy_quantity;

        $http({
            method: 'POST',
            url: API_LINK + '/importRequests/calculate1/id/' + editedRequest.import_item.origin_id,
            data: $.param(sent_params),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(function(result) {
            result = result.data;
            console.log(result.data.request);
            self.importRequest.calculates = result.data.request;
        }, function(err) {
            console.log(err);
        });
    };

    /**
     * 
     * @returns {boolean}
     */
    this.showPricesInRequest = function() {
        return typeof self.editedRequest.variety != 'undefined' &&
            typeof self.editedRequest.variety.selected != 'undefined' &&
            self.editedRequest.variety.selected != '0';
    };

    /**
     *  Заголовок формы заявки
     */
    this.requestFormTitleCreate = function() {
        return typeof this.editedRequest.guid == 'undefined' || this.editedRequest.buy == 1;
    };

    /**
     * На форме заявки изменена платнация
     * http://ec.term.biflorica:8080/api/requests/get/order/15/id/-1/plantation/1
     */
    this.changePlantationInRequest = function(editedRequest) {
        console.log(editedRequest.farm.selected);
        //Очищаем ошибки на форме
        self.clearErrors(editedRequest);
        // Сбрасываем выбор типа
        editedRequest.type.items = {};
        editedRequest.type.items['-1'] = handbook.data.langMP['loading'];

        editedRequest.type.disable = true;
        editedRequest.type.selected = '-1';

        // Сбрасываем выбор сорта
        editedRequest.variety.disable = true;
        editedRequest.variety.selected = "";
        // Сбрасываем все дополнительные заявки
        editedRequest.additionalRequests = {};
        if (typeof show_order_id != 'undefined' && show_order_id != 0) {
            url = API_LINK + '/requests/get/order/' + show_order_id + '/id/-1/plantation/' + editedRequest.farm.selected;
        } else {
            url = API_LINK + '/requests/get/order/' + editedRequest.orders.selected + '/id/-1/plantation/' + editedRequest.farm.selected;
        }

        $http({
            method: 'POST',
            url: url,
            data: $.param(editedRequest),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(function(result) {
            result = result.data;
            if (!result.errors || result.errors.length == 0) {
                editedRequest.type.disable = false;
                var addNewRequest = editedRequest.addNewRequest;
                editedRequest.type.items = result.data.request.type.items;
                editedRequest.type.selected = 0;
                editedRequest.variety.items = result.data.request.variety.items;
                editedRequest.variety.selected = 0;
                editedRequest.prices = result.data.request.prices;
                editedRequest = result.data.request;
                editedRequest.addNewRequest = addNewRequest;

                self.renewPacking(editedRequest);
            } else {
                editedRequest.requestFormErrors = result.errors[0];
                editedRequest.requestFormFieldsErrors = result.fieldsErrors;
            }

            if (addNewRequest) self.blockFieldForNew(editedRequest);
            self.moveSelectedValues(editedRequest);
            self.recalculateRequestPrice(editedRequest);
            self.normallizeTypes(editedRequest);
            self.normallizeVarieties(editedRequest);
        }, function(err) {
            console.log(err);
        });
    };

    /**
     * На форме заявки изменен тип
     */
    this.changeTypeInRequest = function(editedRequest) {
        //Очищаем ошибки на форме
        self.clearErrors(editedRequest);

        console.log(editedRequest.type.selected);
        // Сбрасываем выбор сорта
        editedRequest.variety.selected = "";
        editedRequest.variety.disable = false;

        // Сбрасываем все дополнительные заявки
        editedRequest.additionalRequests = {};
        editedRequest.additionalRequests.count = 0;

        // Обновляем доступные типы
        self.updateAvailableVarieties(editedRequest);
    };

    this.updateAvailableVarieties = function(editedRequest, edit_form) {
        editedRequest.availableVarieties = [];
        var edit = edit_form || false;
        var tempItems = editedRequest.variety.items;

        for (var key in tempItems) {
            if (!tempItems.hasOwnProperty(key)) {
                continue;
            }

            var o = { 'key': key, 'value': tempItems[key] };

            if (edit || self.checkTypeOfVariety(editedRequest, o.key)) {
                editedRequest.availableVarieties.push(o);
            }
        }

    };

    /**
     * На форме заявки изменен сорт
     */
    this.changeSortInRequest = function(editedRequest) {
        //Очищаем ошибки на форме
        self.clearErrors(editedRequest);

        // Отображаем поле цен
        var prices = editedRequest.prices[editedRequest.variety.selected];
        console.log(prices);
        for (var price in prices) {
            editedRequest[price] = prices[price].value;
            editedRequest[price].checked = prices[price].checked;
            editedRequest[price + 'Count'] = prices[price].count;
            editedRequest['check-' + price] = {
                hidden: prices[price].hidden
            }
        }
    };
    /**
     * На форме дополнительной заявки изменен сорт
     */
    this.changeSortInAdditionalRequest = function(num_req) {
        // Отображаем поле цен
        var prices = self.editedRequest.prices[self.additionalRequests[num_req].variety.selected];
        console.log(prices);
        for (var price in prices) {
            self.additionalRequests[num_req][price] = prices[price].value;
            self.additionalRequests[num_req][price].checked = prices[price].checked;
            self.additionalRequests[num_req][price + 'Count'] = prices[price].count;
            self.additionalRequests[num_req]['check-' + price] = {
                hidden: prices[price].hidden
            };
        }
    };

    this.updateLinks = function(editedRequest) {
        if ($rootScope.current_user_role !== $rootScope.role_buyer) {
            return false;
        }

        if (typeof editedRequest.orders === 'undefined' ||
            typeof editedRequest.orders.selected === 'undefined') {
            return false;
        }

        self.links = self.orders[editedRequest.orders.selected].links;

        if (!self.links.hasOwnProperty(editedRequest.link_id)) {
            editedRequest.link_id = '';
        }
    };

    /**
     * На форме заявки изменен ордер
     */
    this.changeOrderInRequest = function(editedRequest) {
        self.updateLinks(editedRequest);

        if ($rootScope.current_user_role == $rootScope.role_seller) {
            if (typeof editedRequest.opposite_order != 'undefined') {
                var selected_order = editedRequest.opposite_order;
            } else {
                if (typeof editedRequest.order != 'undefined') {
                    var selected_order = editedRequest.order;
                } else {
                    if (typeof editedRequest.orders != 'undefined') {
                        var selected_order = self.orders[editedRequest.orders.selected];
                    } else {
                        var selected_order = 0;
                    }
                }
            }

            cur_date = new Date();

            max_date = new Date(parseInt(selected_order.air_date_for_calculate) * 1000);
            max_date.setDate(max_date.getDate() - selected_order.departure_to);

            if (max_date < cur_date) max_date = cur_date;

            if (selected_order.departure_from == 0) {
                min_date = cur_date;
            } else {
                min_date = new Date();
                min_date.setDate(min_date.getDate() - selected_order.departure_from);
                if (min_date < cur_date) min_date = cur_date;
            }

            var typeof_airport_date = typeof editedRequest.airport_delivery_date;

            if (typeof editedRequest.order_purpose !== 'undefined' && editedRequest.order_purpose > 0) {
                var dateOffset = (24 * 60 * 60 * 1000) * 5; // 5 days
                min_date.setTime(max_date.getTime() - dateOffset);

                cur_date = new Date();
                if (min_date < cur_date) min_date = cur_date;
            }

            // BIF-4361: ограничить диапазон даты доставки в Карго тремя днями
            var dateOffset = (24 * 60 * 60 * 1000) * editedRequest.order.days; // 5 days
            min_date = new Date();
            min_date.setTime(max_date.getTime() - dateOffset);

            cur_date = new Date();
            if (min_date < cur_date) min_date = cur_date;

            $("#airportDeliveryDate").daterangepicker({
                    "autoApply":true,
                    "singleDatePicker": true,
                    //"isInvalidDate" : InvalidDateFunc,
                    "minDate": min_date,
                    "maxDate": max_date,
                    "beforeShowDay": self.allowedDate,
                    "applyClass": "btn-default bf-apply-datepicker-btn",
                    "cancelClass": "btn-link pull-right",
                    "locale": {
                        "format": "D MMM YYYY",
                        "separator": " - ",
                        "applyLabel": "Применить",
                        "cancelLabel": "Отменить",
                        "fromLabel": "From",
                        "toLabel": "To",
                        "customRangeLabel": "Custom",
                        "daysOfWeek": ["S", "M", "T", "W", "T", "F", "S"],
                        "firstDay": 1
                    },
                },
                function(start, end, label) {
                    //var years = moment().diff(start, 'years');
                    //console.log("You are " + years + " years old.");
                });

            if (typeof editedRequest.airport_delivery_date !== typeof_airport_date) {
                editedRequest.airport_delivery_date = "";
            }
        }
    };

    /**
     * Доступные даты в календаре
     */
    this.allowedDate = function(date) {
        console.log(date.getDay());
        if (date.getDay() == 1 || date.getDay() == 4) {
            return false;
        }

        return false;
    };
    /**
     * Добавляем дополнительную заявку
     */
    this.addAdditionalRequest = function() {
        self.countAdditionalRequests++;
        // Прямое копирование приводит к передаче ссылки, реально копии не создаётся
        //self.additionalRequests[self.countAdditionalRequests] = self.editedRequest;
        self.additionalRequests[self.countAdditionalRequests] = self.clone(self.editedRequest);
        for (var price in self.additionalRequests[self.countAdditionalRequests].prices[self.additionalRequests[self.countAdditionalRequests].variety.selected]) {
            self.additionalRequests[self.countAdditionalRequests]['check-' + price].checked = false;
        }
        self.additionalRequests[self.countAdditionalRequests].editedRequestSum = {};
        self.recalculateAllRequestsPrice();
        $('.panel-collapse').removeClass('in');
        $('#collapseOne' + self.countAdditionalRequests).addClass('in');
    };

    /**
     * Активность кнопки добавления дополнительной заявки
     */
    this.addAdditionalRequestButtonDisabled = function() {
        if (self.countAdditionalRequests > 0) {
            var requestForAnalise = self.additionalRequests[self.countAdditionalRequests];
        } else {
            var requestForAnalise = self.editedRequest;
        }
        if (typeof requestForAnalise.variety != 'undefined' && typeof requestForAnalise.variety.selected != 'undefined' && requestForAnalise.variety.selected != 0) {
            var prices = {};
            if (typeof requestForAnalise.prices != 'undefined' && typeof requestForAnalise.prices[requestForAnalise.variety.selected] != 'undefined') {
                prices = requestForAnalise.prices[requestForAnalise.variety.selected];
            }
            for (var price in prices) {
                if (requestForAnalise['check-' + price].checked) return false;
            }
            return true;
        } else {
            return true;
        }
    };

    /**
     * Удаляем дополнительную заявку
     */
    this.removeAdditionalRequest = function(number) {
        delete(self.additionalRequests[number]);
        self.countAdditionalRequests--;
        if (self.countAdditionalRequests == 0) {
            $('#collapseOne').addClass("in");
        }
    };

    /**
     * Сохраняем дополнительные заявки
     * result: true - все заявки сохранились нормально
     *         false - ошибка сохранения заявки
     */
    this.saveAdditionalRequests = function(from) {
        for (var request in self.additionalRequests) {
            //console.log(self.additionalRequests[request]);
            if (self.saveAdditionalRequest(self.additionalRequests[request], from)) {
                return false;
            }
        }
        return true;
    };

    /**
     * Сохраняем дополнительную заявку
     */
    this.saveAdditionalRequest = function(editedRequest, from) {
        console.log(editedRequest);
        url = API_LINK + '/requests/create';
        var sent_params = this.getParamsFromEditedRequest(editedRequest);
        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(function(result) {
            result = result.data;
            if (!result.errors || result.errors.length == 0) {
                return true;
                //location.reload();
            } else {
                editedRequest.requestFormErrors = result.errors[0];
                editedRequest.requestFormFieldsErrors = result.fieldsErrors;
                return false;
            }
            console.log(result);
        }, function(err) {
            console.log(err);
        });
    };

    /**
     * Изменяет значения selected для правильного отображения выбранного значения
     */
    this.moveSelectedValues = function(editedRequest) {
        //console.log(editedRequest.farm.selected);
        if (typeof editedRequest.farm.selected == 'undefined') {
            editedRequest.farm.selected = "";
        } else {
            if (typeof editedRequest.farm.items != 'undefined' && typeof editedRequest.farm.items[editedRequest.farm.selected] != 'undefined' && editedRequest.farm.selected == 0) {
                // Так неправильно! в некоторых запросах selected приходит правильно - по id
                editedRequest.farm.selected = editedRequest.farm.items[editedRequest.farm.selected].id;
            }
        }

        if (typeof editedRequest.order_id != 'undefined') {
            if (typeof editedRequest.orders == 'undefined') {
                editedRequest.orders = {
                    selected: editedRequest.order_id
                };
            } else {
                editedRequest.orders.selected = editedRequest.order_id;
            }
        }

        if (editedRequest.check_quality !== 'undefined') {
            editedRequest.check_quality = editedRequest.check_quality == 1 ? true : false;
        }

        if (typeof editedRequest.submark_id != 'undefined') {
            if (typeof editedRequest.submarks == 'undefined') {
                editedRequest.submarks = {
                    //disabled: true,
                    selected: editedRequest.submark_id
                };
            } else {
                editedRequest.submarks.selected = editedRequest.submark_id;
            }
        }

        if (typeof editedRequest.submarks != 'undefined' && typeof editedRequest.submarks.selected != 'undefined') {
            for (var num_submark in self.listSubmark) {
                if (editedRequest.submarks.selected == self.listSubmark[num_submark].guid) {
                    editedRequest.submarks.selected = num_submark;
                }
            }
        }

        // Когда сабмаркировка не выбрана, пытаемся выбрать первый из списка доступных
        if (typeof editedRequest.submarks == 'undefined' || typeof editedRequest.submarks.selected == 'undefined') {
            editedRequest.submarks = {
                //disabled: true,
                selected: '0'
            };
        } else if (editedRequest.submarks.selected == '-1') {
            editedRequest.submarks.selected = '0';
        }

        // Когда карго не выбрана, пытаемся выбрать первый из списка доступных
        /*
        if(typeof editedRequest.cargos == 'undefined' || typeof editedRequest.cargos.selected == 'undefined'){
            editedRequest.cargos = {selected: self.listCargo[0].guid};
        }else{
            if(editedRequest.cargos.selected == '-1') {
                editedRequest.cargos.selected = self.listCargo[0].guid;
            }
        }
        */
        //console.log(editedRequest.farm.selected);
    };

    /**
     * Get Submark List
     * http://ec.term.biflorica:8080/api/submarks/list
     */
    this.getSubmarksList = function(num_request) {
        $resource(API_LINK + '/submarks/list', {}, { get: { method: 'GET' } }).get(function(result) {
            if (num_request == 0) self.listSubmark = result.data.list;
            self.listEditedRequests[num_request].listSubmark = result.data.list;
            //console.log($scope.listSubmark);
        });
    };
    // BIF-4621 - Загружаем при открытии формы заявки
    // this.getSubmarksList();

    /**
     * Get Cargo List
     * http://ec.term.biflorica:8080/api/cargo/list
     */
    this.getCargoList = function(num_request) {
        $resource(API_LINK + '/cargo/list', {}, { get: { method: 'GET' } }).get(function(result) {
            if (num_request == 0) self.listCargo = result.data.list;
            self.listEditedRequests[num_request].listCargo = result.data.list;
            //console.log($scope.listCargo);
        });
    };
    // BIF-4621 - Загружаем при открытии формы заявки
    // this.getCargoList();

    /**
     * Get Active Orders List
     * http://ec.term.biflorica:8080/api/orders/getActive
     */
    this.getActiveOrders = function() {
        $resource(API_LINK + '/orders/getActive', {}, { get: { method: 'GET' } }).get(function(result) {
            if (typeof $rootScope.order_purpose !== 'undefined') {
                // if opened multiple orders page
                if (parseInt($rootScope.order_purpose) === -1) {
                    var orders = {};

                    for (var purpose_id in result.data.orders) {
                        if (!result.data.orders.hasOwnProperty(purpose_id)) {
                            continue;
                        }

                        for (var order_id in result.data.orders[purpose_id]) {
                            if (!result.data.orders[purpose_id].hasOwnProperty(order_id)) {
                                continue;
                            }

                            orders[order_id] = result.data.orders[purpose_id][order_id];
                        }
                    }

                    self.orders = orders;
                } else {
                    self.orders = result.data.orders[$rootScope.order_purpose];
                }

                self.updateLinks(self.listEditedRequests[0]);
            }

            self.all_orders = result.data.orders;
            console.log(result.data.orders);
            for(var order_num in result.data.orders) {
                console.log(order_num);
                console.log(result.data.orders[order_num]);
                for(var order_id in result.data.orders[order_num]) {
                    self.all_active_orders[order_id] = result.data.orders[order_num][order_id];
                }
            }
            console.log(self.all_active_orders);
        });
    };
    // BIF-4621 - Загружаем при открытии формы заявки
    // this.getActiveOrders();

    /**
     * For Clone Request To AdditionalRequest
     */
    this.clone = function(obj) {
        if (obj == null || typeof(obj) != 'object')
            return obj;
        var temp = new obj.constructor();
        for (var key in obj)
            temp[key] = self.clone(obj[key]);
        return temp;
    };

    this.incrementValue = function(obj, value, step, fixed) {
        if (obj[value] == '') obj[value] = 0;
        current = parseFloat(obj[value]);
        step = parseFloat(step);
        var rez = current + step;
        obj[value] = rez.toFixed(fixed);
    };
    this.decrementValue = function(obj, value, step, fixed, min) {
        if (obj[value] == '') obj[value] = 0;
        if (typeof min == 'undefined') min = 0;
        current = parseFloat(obj[value]);
        step = parseFloat(step);
        var rez = current - step;
        if (rez >= min) {
            obj[value] = rez.toFixed(fixed);
        }
    };

    /**
     *
     */
    this.sizesStr = function(request) {
        if (request === 'undefined')
            return ''

        var rez_size = '';
        var rez_price = '';
        var size, price;
        for (var num_size in sizes) {
            size = sizes[num_size];
            price = 'price' + size;
            if (typeof request['check-price' + size] != 'undefined' && request['check-price' + size].checked == true) {
                //sent_params[price] = editedRequest[price];
                rez_size += size + 'cm/';
                rez_price += '$' + request[price] + '/';
            }
        }
        // Убираем лишний '/' из строк
        return rez_size.slice(0, -1) + ' - ' + rez_price.slice(0, -1);
    };

    /**
     * Возвращает ключ для текущего выбора Плантации_Типа_Сорта
     * Например 1_1_1
     */
    this.getCurrentChoise = function(editedRequest) {
        if (
            typeof editedRequest.farm.selected != 'undefined' &&
            typeof editedRequest.type.selected != 'undefined' &&
            typeof editedRequest.variety.selected != 'undefined'
        ) {
            var id_str = '';
            if (typeof editedRequest.approve_prices_disabled != 'undefined' && editedRequest.approve_prices_disabled) {
                id_str = self.editedRequest.farm.selected + '_' + self.editedRequest.type.selected + '_' + self.editedRequest.variety.selected;
            } else {
                id_str = editedRequest.variety.selected;
            }

            //console.log(id_str);
            return id_str;
        } else {
            return '_';
        }
    };

    /**
     * Блокирует поля при создании новой заявки
     *
     */
    this.blockFieldForNew = function(editedRequest) {
        //Блокируем выбор сорта до выбора типа
        editedRequest.variety.disable = true;
        var size;
        //Блокируем выбор размера
        for (var num_size in sizes) {
            size = sizes[num_size];
            if (typeof editedRequest['check-price' + size] != 'undefined') {
                editedRequest['check-price' + size].disabled = true;
            } else {
                editedRequest['check-price' + size] = {
                    disabled: true
                }
            }
        }
    };

    /**
     * Отображение сорта в списке только соответствующего типа
     */
    this.checkTypeOfVariety = function(editedRequest, sortid) {
        if (
            typeof editedRequest.type.selected != 'undefined' &&
            editedRequest.type.selected != '' &&
            sortid.indexOf(editedRequest.type.selected) == 0
        ) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * Приводит список типов к нормальному виду (удаляет :ID )
     */
    this.normallizeTypes = function(editedRequest) {
        for (var id in editedRequest.type.items) {
            editedRequest.type.items[id] = editedRequest.type.items[id].split(':')[0]
        }
    };

    /**
     * Приводит список сортов к нормальному виду (удаляет :ID )
     */
    this.normallizeVarieties = function(editedRequest) {
        for (var id in editedRequest.variety.items) {
            editedRequest.variety.items[id] = editedRequest.variety.items[id].split(':')[0]
        }
    };

    /**
     * Подтверждает сделку
     */
    this.approveDeal = function(deal_id) {
        $resource(API_LINK + '/deals/approve/id/' + deal_id, {}, { get: { method: 'GET' } }).get(function(result) {
            $rootScope.timerCountSec = 0;
        });
    };

    /**
     * Подтверждает сделку после бронирования
     */
    this.approveBooked = function(deal_id) {
        $resource(API_LINK + '/deals/approveBooked/id/' + deal_id, {}, { get: { method: 'GET' } }).get(function(result) {
            $rootScope.timerCountSec = 0;
        });
    };

    /**
     * ОТменяет сделку при подтверждении
     */
    this.approveCancel = function(deal_id) {
        $resource(API_LINK + '/deals/approveCancel/id/' + deal_id, {}, { get: { method: 'GET' } }).get(function(result) {
            $rootScope.timerCountSec = 0;
        });
    };

    /**
     * Изменяет сделку при подтверждении
     */
    this.approveChange = function(request_id, order_id) {
        self.editedRequest.get_data_url = API_LINK + '/requests/get/order/' + order_id + '/id/' + request_id;
        self.__send_url = API_LINK + '/requests/approveUpdate/id/' + request_id;
        self.approveForm = true;
        self.listEditedRequests[0].approveForm = true;
        self.setRequest(request_id, order_id, undefined, false, true);
    };

    /**
     * Изменяет сделку при подтверждении
     */
    this.requestFormDeletePreviusVar = function() {
        delete self.editedRequest.get_data_url;
        delete self.__send_url;

        self.editedRequestSum = {};
        self.editedRequestPricesShow = false;

        // Удаляем дополнительные заявки на форме
        self.additionalRequests = {};
        self.countAdditionalRequests = 0;
        $('#collapseOne').addClass("in");
    };

    /**
     *
     */
    this.movePricesForForm = function(editedRequest) {
        if (
            typeof editedRequest.farm.selected != 'undefined' &&
            typeof editedRequest.type.selected != 'undefined' &&
            typeof editedRequest.variety.selected != 'undefined'
        ) {
            id_str = editedRequest.farm.selected + '_' + editedRequest.type.selected + '_' + editedRequest.variety.selected;
            for (var num_size in sizes) {
                size = sizes[num_size];
                if (typeof editedRequest.prices[id_str]['price' + size] != 'undefined' && editedRequest.prices[id_str]['price' + size].checked) {
                    editedRequest['check-price' + size] = { checked: true };
                    if (editedRequest.prices[id_str]['price' + size].hidden) {
                        editedRequest['check-price' + size].hidden = true;
                    }
                    editedRequest['price' + size] = editedRequest.prices[id_str]['price' + size].value;
                } else {

                }
            }
        }
    };

    /**
     * Скрыть/Оторазить блок полей Пакинга
     * @param editedRequest
     */
    this.toogleShowPackBlock = function(editedRequest) {
        if (editedRequest.show_pack_block) {
            editedRequest.show_pack_block = false;
        } else {
            editedRequest.show_pack_block = true;
        }
    };

    /**
     * Cостояние поля пакинг на форме
     * @param editedRequest
     */
    this.renewPacking = function(editedRequest, isChanged) {
        console.log(!!isChanged)

        if (!!isChanged) {
            editedRequest.req_pack = 0
            editedRequest.max_pack = 0
            editedRequest.min_pack = 0
        }


        editedRequest.show_pack_block = false;

        if (
            typeof editedRequest.req_pack == 'undefined' ||
            editedRequest.req_pack == 0
        ) {
            editedRequest.need_req_pack = false;
            editedRequest.req_pack = 0;
        } else {
            if ((editedRequest.role == "b" && editedRequest.buy == 1)) {
                editedRequest.need_req_pack = false;
                editedRequest.show_pack_block = false;
            } else {
                editedRequest.need_req_pack = true;
                editedRequest.show_pack_block = true;
            }
        }

        console.log(editedRequest.show_pack_block);

        if (typeof editedRequest.max_pack == 'undefined' || editedRequest.max_pack == 0) {
            editedRequest.need_max_pack = false;
            editedRequest.max_pack = 0;
            editedRequest.show_pack_block = false;
        } else {
            editedRequest.need_max_pack = true;
            editedRequest.show_pack_block = true;
        }
        if (typeof editedRequest.min_pack == 'undefined' || editedRequest.min_pack == 0) {
            editedRequest.need_min_pack = false;
            editedRequest.min_pack = 0;
            editedRequest.show_pack_block = false;
        } else {
            editedRequest.need_min_pack = true;
            editedRequest.show_pack_block = true;
        }

        console.log(editedRequest.show_pack_block);
    };

    this.renewLinkForZerroRequest = function(num_request) {
        console.log(self.editedRequest);
        self.editedRequest = self.listEditedRequests[num_request];
        console.log(self.editedRequest);
    };

    /**
     *
     * пустая структура для инициализации и обнуления заявки
     */
    //
    this.setEmptyRequest = function(num_request) {
        if (typeof num_request === 'undefined') num_request = 0;
        self.listEditedRequests[num_request] = {
            farm: { items: [] },
            type: { items: [] },
            variety: { items: [] },
            size: { items: {} },
            status: { items: {} },
            boxes_type: default_box_type,
            boxes_types: boxes_list,
            show_pack_block: true,
            need_min_pack: false,
            need_max_pack: false,
            need_req_pack: false,
            link_id: '',
            mp_hash: '',
            min_pack: 0,
            max_pack: 0,
            req_pack: 0,
            approve_prices_disabled: false,
            approve_boxes_type_disabled: false,
            min_boxes_amount: 0,
            max_boxes_amount: 30000,
            airport_delivery_date: '',
            editedRequestSum: { stems_in_box: 0 },
            sentForm: false,
            buy: false,
            approveForm: false,
            availableVarieties: [],
            listSubmark: {},
            listCargo: {},
            hideForm: false,
            current_request_num: 0,
        };

        //?
        self.renewLinkForZerroRequest(0);
        self.editedRequestSum = {};
        self.editedRequestPricesShow = false;
        self.sentForm = false;
        self.buy = false;
        self.approveForm = false;
        //self.clone_request = false;
    };
    this.setEmptyRequest();

    this.setEmptyImportRequest = function() {
        self.importRequest = {
            buy_quantity: 0,
            import_item: {},
            calculates: {},
            errors: {},
        };

        self.sentForm = false;
    };
    this.setEmptyImportRequest();

    /**
     * Очищаем ошибки на форме заявки
     */
    this.clearErrors = function(editedRequest) {
        editedRequest.requestFormErrors = {};
        editedRequest.requestFormFieldsErrors = {};
    };

    /**
     * Move Edited request to market
     * http://ec.term.biflorica:8080/api/requests/moveToMarketPlace/
     */
    this.moveToMarket = function(request_id) {
        /*
                if (PRICELIST_MODE)
                    return;
        */
        $http({
            method: 'POST',
            url: API_LINK + '/requests/moveToMarketPlace',
            data: $.param({ 'checked[]': request_id }),
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(function(result) {
            result = result.data;
            //console.log(result);
        }, function(err) {
            console.log(err);
        });
    };

    this.not_available = function() {
        self.editedRequest.no_deal = 1;
        self.save(self.editedRequest.guid, self.editedRequest.from)
    };

    /**
     * Какой пакинг используется для заявки
     *
     * @param type
     *
     * @returns {boolean}
     */
    this.packingInQB = function(type) {
        return parseInt(type) === 2;
    };

    this.packingInHB = function(type) {
        return parseInt(type) === 1;
    };

    this.BoxesAmountMoreThanZero = function(boxes_amount) {
        return parseFloat(boxes_amount) > 0;
    };


}]);