angular.module('bfServices.order', ['ngSanitize'])

.service('order', ['$http', '$resource', '$rootScope', function($http, $resource, $rootScope) {

    this.editedOrder = {};
    this.orderFormErrors = {};
    this.orderFormFieldsErrors = {};
    this.ordersFromDate = '01.08.2015';
    this.ordersEndDate = '30.10.2015';
    this.sentForm = false;
    this.comments = {};

    var self = this;

    // Get order for fill form
    this.set = function(order_id, clone, order_purpose) {
        var purpose = order_purpose || $rootScope.order_purpose; // general purpose
        // http://ec.term.biflorica:8080/api/deals/new/request/18
        editedOrderResource = $resource(API_LINK + '/orders/:action',
            {action:'get', 'order': order_id, 'clone': clone, 'purpose': purpose},
            {get: {method:'GET'}}
        );
        editedOrderResource.get(function(result) {
            self.editedOrder = {};
            self.editedOrder = result.data.order;
            self.editedOrder.clone = clone;
            self.editedOrder.edit = false;
            //self.editedOrder.adate = "14/06/2016";
            if(typeof self.editedOrder.exptime != 'undefined' && self.editedOrder.exptime != ''){
                self.editedOrder.hours = self.editedOrder.exptime.substring(0, 2);
                self.editedOrder.minutes = self.editedOrder.exptime.substring(3, 5);
            }

            self.editedOrder.purpose = purpose;

            if (self.editedOrder.purpose > 0 && typeof self.editedOrder.date_range !== 'undefined') {
                initOrderCalendar(self.editedOrder.date_range.min_date, self.editedOrder.date_range.max_date);
            } else {
                initOrderCalendar();
            }

            if(order_id == -1 || clone){
                self.editedOrder.adate = '';
                self.editedOrder.pickup_date = '';
            }

            if(self.editedOrder.is_pr_enabled !== 'undefined') {
                self.editedOrder.is_pr_enabled = self.editedOrder.is_pr_enabled == 1 ? true : false;
            }

            if(order_id != -1 && !self.editedOrder.clone){
                self.editedOrder.edit = true;
            }
            //console.log(self.editedOrder);
        });
    };
    this.setOrder = this.set;

    // set files error
    this.setFileError = function (msg) {
        self.orderFormFieldsErrors.files = [];
        self.orderFormFieldsErrors.files.push(msg);
    };

    // remove error if comment for manager was changed or file was uploaded
    this.eraseFileErrors = function () {
        if (self.orderFormFieldsErrors.files !== 'undefined') {
            delete self.orderFormFieldsErrors.files;
        }
    };

    // remove error if expected hb was changed
    this.eraseExpectedHBError = function () {
        if (self.orderFormFieldsErrors.expected_hb !== 'undefined') {
            delete self.orderFormFieldsErrors.expected_hb;
        }
    };
    // remove error if expected qb was changed
    this.eraseExpectedQBError = function () {
        if (self.orderFormFieldsErrors.expected_qb !== 'undefined') {
            delete self.orderFormFieldsErrors.expected_qb;
        }
    };

    /**
     * Получение заказа
     * @returns {{}|*}
     */
    this.get = function(){
        return self.editedOrder;
    };
    this.getOrder = this.get;

    // Save order from form
    this.save = function(order_id){
        // Disable Save button
        self.sentForm = true;

        if(typeof order_id == 'undefined' || order_id == '-1'){
            // http://ec.term.biflorica:8080/api/orders/create/
            url = API_LINK + '/orders/create';
        }else if(self.editedOrder.clone) {
            // http://ec.term.biflorica:8080/api/orders/copy/
            url = API_LINK + '/orders/copy';
        }else{
            // http://ec.term.biflorica:8080/api/orders/update/order/3
            url = API_LINK + '/orders/update/order/' + order_id;
        }
        sent_params = self.getParamsFromEditedOrder(self.editedOrder);

        var files_count = 0;
        // Проверка и перегрузка файлов
        var files_obj = $('#fine-uploader-gallery').fineUploader('getUploads');
        for (var id_file in files_obj) {
            var file_obj = files_obj[id_file];
            if (file_obj.status == "upload successful") {
                sent_params['files['+file_obj.id+']'] = file_obj.uuid + '__' + file_obj.originalName;
                files_count++;
            }
        }

        $http({
            method: 'POST',
            url: url,
            data: $.param(sent_params),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).then(function( result ){
            result = result.data;
            if (result.errors.length == 0) {
                $rootScope.timerCountSec = 0;
                $('.bf-create-order-dialog').modal('hide');
                self.reset();
                //location.reload();
            } else {
                self.orderFormErrors = result.errors[0];
                self.orderFormFieldsErrors = result.fieldsErrors;//JSON.stringify(result.fieldsErrors);
            }
            // Enable Save button
            self.sentForm = false;
            console.log(result);
        }, function(err){
            // Enable Save button
            self.sentForm = false;
            console.log(err);
        });
    };
    this.saveOrder = this.save;

    /**
     * Сохранение
     */
    this.addPurchaseRequestComment = function() {
        var sent_params = {};

        sent_params.text_for_manager = self.editedOrder.text_for_manager;
        sent_params.id = self.editedOrder.guid;

        self.sentForm = true;

        // http://ec.term.biflorica:8080/api/purchaseRequests/addComment
        $resource(
            API_LINK + '/purchaseRequests/addComment',
            [],
            {save: {method:'POST'}}
        ).save(sent_params, function(result) {
            if (result.data.item.errors.length === 0) {
                $rootScope.loadPurchaseRequestComments(self.editedOrder.guid);
                self.editedOrder.text_for_manager = '';
            } else {
                self.orderFormFieldsErrors = result.data.item.errors;
            }
        });

        self.sentForm = false;
    };

    /**
     * Очистка заказа
     * @returns {{}|*}
     */
    this.reset = function() {
        self.editedOrder = {};
        self.clearErrors();
        $('#fine-uploader-gallery').fineUploader('reset');
        return true;
    };

    this.setDateInterval = function($from, $to){
        self.ordersFromDate = $from;
        self.ordersEndDate = $to;
    };

    this.orderFormTitleCreate = function(){
        return typeof self.editedOrder.guid == 'undefined' || self.editedOrder.guid == '-1';
    };

    /**
     *
     * @param editedOrder
     * @returns {{}|*}
     */
    this.getParamsFromEditedOrder = function(editedOrder){
        //Формируем параметры на отправку
        sent_params = {};

        sent_params.orderName = editedOrder.orderName;
        sent_params.abbr = editedOrder.abbr;
        sent_params.adate = editedOrder.adate;
        sent_params.pickup_date = editedOrder.pickup_date;
        sent_params.purpose = editedOrder.purpose;
        sent_params.is_pr_enabled = editedOrder.is_pr_enabled ? 1 : 0;
        sent_params.text_for_manager = editedOrder.text_for_manager;
        sent_params.cargo = editedOrder.cargo.selected;
        sent_params.expected_hb = editedOrder.expected_hb;
        sent_params.expected_qb = editedOrder.expected_qb;
        for (var num_box in boxes) {
            box = boxes[num_box];
            sent_params['expected_' + box] = editedOrder['expected_' + box];
        }
        if(typeof editedOrder.hours != 'undefined' && editedOrder.hours != ''){
            sent_params.exp_time = editedOrder.hours + "_" + editedOrder.minutes;
        }
        if(editedOrder.clone){
            sent_params.copyId = editedOrder.guid;
        }

        return sent_params;
    };

    /**
     * Очищаем ошибки на форме заказа
     */
    this.clearErrors = function(){
        self.orderFormErrors = {};
        self.orderFormFieldsErrors = {};
    };

    /**
     * Загрузить заявки в ордер
     * @param id - Ордер
     */
    this.uploadToOrder = function(id){
        sent_params = {};
        sent_params['id'] = id;
        window.location.replace('/marketPlaceNew/default/upload/?' + $.param(sent_params));
    };

    this.isPurchaseRequestFieldsShown = function () {
        return self.editedOrder.can_be_enabled_purchase_request == 1 && (!self.editedOrder.edit || (self.editedOrder.is_pr_enabled == 1 && self.editedOrder.edit));
    };

}]);
