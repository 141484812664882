angular.module('bfServices.expectedVolume', ['ngSanitize'])

/**
 * Сервис expected volume
 */
.service('expectedVolume', ['$http', '$resource', '$rootScope', function($http, $resource, $rootScope) {
    this.edited = {};
    this.platforms = {};

    this.submarks = {};
    this.submarksOptions = {};

    this.cargos = {};
    this.cargosOptions = {};

    this.isEdit = false;

    // Ошибки
    this.errors = {};

    var self = this;

    /**
     * Инициализация
     * @param id - id expected volume
     */
    this.set = function(id) {
        //Очищаем ошибки на форме
        self.setEmpty();
        self.loadPlatforms();

        if (typeof id === 'undefined' || id === '') {
            return false;
        }

        self.isEdit = true;

        // http://ec.term.biflorica:8080/api/expectedVolumes/get/id
        url = API_LINK + '/expectedVolumes/get/id/' + id;
        $resource(
            url,
            {},
            {get: {method:'GET'}}
        ).get(function(result){
            self.edited = result.data.item;
            self.submarksOptions = self.submarks[result.data.item.platform_id];
            self.cargosOptions = self.cargos[result.data.item.platform_id];
            self.errors = {};
            console.log(self.edited);
        });
    };

    this.loadPlatforms = function () {
        // http://ec.term.biflorica:8080/api/expectedVolumes/platforms
        url = API_LINK + '/expectedVolumes/platforms';
        $resource(
            url,
            {},
            {get: {method:'GET'}}
        ).get(function(result){
            self.platforms = result.data.platforms;
            self.submarks = result.data.submarks;
            self.cargos = result.data.cargos;

            console.log(self.platforms);
            console.log(self.submarks);
        });
    };

    /**
     * Получение
     * @returns {{}|*}
     */
    this.get = function(){
        return self.edited;
    };

    /**
     * Сохранение 
     */
    this.save = function() {
        var sent_params = this.getParamsFromEdited(self.edited);
        self.sentForm = true;

        // http://ec.term.biflorica:8080/api/expectedVolumes/save
        $resource(
            API_LINK + '/expectedVolumes/save',
            [],
            {save: {method:'POST'}}
        ).save(sent_params, function(result) {
            if (result.data.item.errors.length === 0) {
                self.reset();
                $('.bf-create-expected-volume-dialog').modal('hide');
                $rootScope.renewItems();
            } else {
                self.errors = result.data.item.errors;
                console.log(self.errors);
            }
        });

        self.sentForm = false;
    };

    /**
     * Очистка
     * @returns {{}|*}
     */
    this.reset = function(){
        self.setEmpty();
        self.clearErrors();
        return true;
    };

    /**
     * Формируем параметры на отправку
     * @param edited
     * @returns {{}|*}
     */
    this.getParamsFromEdited = function(edited) {
        var sent_params = {};

        sent_params.id = edited.id;
        sent_params.air_date = edited.air_date;
        sent_params.submark_id = edited.submark_id;
        sent_params.cargo_relation_id = edited.cargo_relation_id;
        sent_params.platform_id = edited.platform_id;
        sent_params.boxes_amount = edited.boxes_amount;

        return sent_params;
    };

    this.platformChanged = function() {
        self.edited.submark_id = '';
        self.edited.cargo_relation_id = '';
        self.submarksOptions = self.submarks[self.edited.platform_id];
        self.cargosOptions = self.cargos[self.edited.platform_id];
    };

    /**
     * пустая структура для инициализации и обнуления
     */
    //
    this.setEmpty = function() {
        self.edited = {'id': 0, 'platform_id': '', 'submark_id': '', 'cargo_relation_id': '', 'boxes_amount': '0.0', 'air_date': ''};
        self.isEdit = false;
        self.sentForm = false;
        self.submarksOptions = {};
        self.cargosOptions = {};
        self.errors = {};
    };
    this.setEmpty();

    /**
     * Очищаем ошибки на форме
     */
    this.clearErrors = function() {
        self.errors = {};
    };

    this.incrementValue = function (obj, value, step, fixed) {
        current = parseFloat(obj[value]);
        step = parseFloat(step);
        var rez = current + step;
        obj[value] = rez.toFixed(fixed);
    };
    this.decrementValue = function (obj, value, step, fixed, min) {
        if(typeof min == 'undefined') min = 0;
        current = parseFloat(obj[value]);
        step = parseFloat(step);
        var rez = current - step;
        if(rez >= min){
            obj[value] = rez.toFixed(fixed);
        }
    };
}]);
