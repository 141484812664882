angular.module('bfControllers.expectedVolumes', [])
.controller('ExpectedVolumesController', ['$scope', '$rootScope', '$resource', '$cookies', '$http', 'expectedVolume', function($scope, $rootScope, $resource, $cookies, $http, expectedVolume){
    $scope.expectedVolume = expectedVolume;
    $scope.selected_items = {
        ids: []
    };

    $scope.start = 0;
    $scope.count = 0;
    $scope.total = 0;
    $scope.cur_page = 1;
    $scope.pager = [];
    $scope.items_per_page = ITEMS_SHOW_PER_PAGE;

    $rootScope.counts = {};

    $rootScope.current_user_role = CURRENT_USER_ROLE;
    $rootScope.role_buyer = ROLE_BUYER;
    $rootScope.role_seller = ROLE_SELLER;

    var self = this;

    $rootScope.create = function () {
        $scope.expectedVolume.set('');
    };

    /**
     * Delete invoice
     */
    $rootScope.delete = function (id) {
        $rootScope.querySent = true;

        sent_params = {};
        sent_params['ids[]'] = [id];

        // http://ec.term.biflorica:8080/api/expectedVolumes/delete
        $resource(
            API_LINK + '/expectedVolumes/delete',
            sent_params,
            {delete: {method:'GET'}}
        ).delete(function(result) {
            $rootScope.timerCountSec = 0;
            self.uncheckAll();
        });

    };

    /**
     * Get list plantation invoices
     */
    $rootScope.renewItems = function() {
        $rootScope.querySent = true;
        // http://ec.term.biflorica:8080/api/expectedVolumes
        $resource(API_LINK + '/expectedVolumes/:action',
            {
                action: 'index',
                sort: $rootScope.sort,
                limit: $scope.items_per_page,
                start: 0,
                direct: $rootScope.direct,
                page: $scope.cur_page
            },
            {get: {method:'GET'}}
        ).get(function(result){
            $scope.start = ($scope.cur_page - 1) * $scope.items_per_page;
            $scope.count = parseInt(result.data.count_items);
            $scope.total = parseInt(result.data.total_items);
            $scope.pager = [];

            var totalPages = Math.ceil($scope.total / $scope.items_per_page);
            for(var i=1; i <= totalPages; i += 1){
                $scope.pager.push(i);
            }

            $scope.items = result.data.list;
            $rootScope.counts = result.data.counts;
            $rootScope.querySent = false;
        });
    };

    $rootScope.renewItemsFromPage = function (new_page) {
        $scope.cur_page = new_page;
        $rootScope.renewItems();
    };

    $scope.renewTimer = function() {
        if (!$rootScope.pause) {
            $rootScope.timerCountSec--;
        }

        if ($rootScope.timerCountSec <= 0) {
            $rootScope.timerCountSec = TIMER_RENEW_PERIOD;
            $scope.renewItems();
        }

        $('#timer_value').text($rootScope.timerCountSec);
        setTimeout($scope.renewTimer, 1000);
    };
    $scope.renewTimer();


    /**
     * Отметить все чекбоксы в списке
     */
    $scope.checkAll = function () {
        $scope.selected_items.ids = [];

        if ($('#list-dates-all').is(':checked')) {
            for (var request in $scope.items) {
                $scope.selected_items.ids[request] = $scope.items[request].guid;
            }
            $('.request-checkbox').attr('checked', true);
        }
    };

    /**
     * Снять Отметки всех чекбоксов в списке
     */
    this.uncheckAll = function () {
        $scope.selected_items.ids = [];
        $('#list-dates-all').attr('checked',false);
        //console.log($scope.selected_orders);
    };
    $scope.uncheckAll = this.uncheckAll;

    $scope.getIds = function () {
        var ret_arr = [];
        for (var req in $scope.selected_items.ids) {
            ret_arr.push($scope.selected_items.ids[req].guid);
        }
        return ret_arr;
    };

    // Выбранные значения сортировки
    var sortValueFromCookies = $cookies.get('expected-volumes-sort');
    if (typeof sortValueFromCookies != 'undefined') {
        $rootScope.sort = sortValueFromCookies;
    } else {
        $rootScope.sort = 'id';
        $cookies.put('expected-volumes-sort', 'id');
    }
    var directValueFromCookies = $cookies.get('direct');
    if (typeof directValueFromCookies != 'undefined') {
        $rootScope.direct = directValueFromCookies;
    } else {
        $rootScope.direct = 'desc';
        $cookies.put('direct', 'desc');
        $rootScope.timerCountSec = 0;
    }
    this.setSort = function(sort_column) {
        if ($rootScope.sort == sort_column) {
            // Изменяем направление сортировки
            if ($rootScope.direct == 'asc') {
                $rootScope.direct = 'desc';
                $cookies.put('direct', 'desc');
            } else {
                $rootScope.direct = 'asc';
                $cookies.put('direct', 'asc');
            }
        } else {
            // Изменяем сортировку
            $cookies.put('expected-volumes-sort', sort_column);
            $rootScope.sort = sort_column;
        }
        $rootScope.timerCountSec = 0;
    };
    $rootScope.setSort = this.setSort;

    $scope.classSort = function(sort_column) {
        if ($rootScope.sort == sort_column) {
            // Изменяем направление сортировки
            if ($rootScope.direct == 'asc') {
                return 'sorted sorted-up';
            } else {
                return 'sorted sorted-down';
            }
        }
    };

    // Запуск действий при загрузке страницы
    $rootScope.renewItems();
} ]);